import { Injectable, inject } from '@angular/core';
import { ContextProvider } from '@unifii/library/common';
import { Company, PermissionAction } from '@unifii/sdk';
import { CompanyFormContextActionType, CompanyFormProvider, ProvisioningFormProviderPermissionInfo } from '@unifii/user-provisioning';

import { Authentication, PermissionGrantedResult } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { COMPANY_REQUIRED_FIELDS } from 'shell/table/companies/company-constants';

@Injectable()
export class CompanyFormPermissionsProvider implements CompanyFormProvider {

    private auth = inject(Authentication);
    private contextProvider = inject(ContextProvider);

    getReadPermissionInfo(company: Company): ProvisioningFormProviderPermissionInfo {
        const result = this.readCompany(company, undefined);

        return {
            granted: result.granted,
            fields: result.fieldsPermissions.readFields,
        };
    }

    canReadField(field: string, company: Company): boolean {
        return this.readCompany(company, field).granted;
    }

    getEditPermissionInfo(company: Company | undefined, action: CompanyFormContextActionType): ProvisioningFormProviderPermissionInfo {
        const result = this.editCompany(company, action, undefined);

        return {
            granted: result.granted,
            fields: result.fieldsPermissions.fields,
        };
    }

    canEditField(field: string, company: Company | undefined, action: CompanyFormContextActionType): boolean {
        return this.editCompany(company, action, field).granted;
    }

    isEditFieldRequired(field: string, action: CompanyFormContextActionType): boolean {
        return COMPANY_REQUIRED_FIELDS[action].includes(field);
    }

    private readCompany(company: Company, field: string | undefined): PermissionGrantedResult {
        return this.auth.getGrantedInfo(PermissionsFunctions.getCompanyPath(company.id), PermissionAction.Read, company, this.contextProvider.get(), field);
    }

    private editCompany(company: Company | undefined, action: CompanyFormContextActionType, field: string | undefined): PermissionGrantedResult {

        switch (action) {
            case PermissionAction.Add:
                return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getCompaniesPath(), action, field);
            case PermissionAction.Update:
                return company ?
                    this.auth.getGrantedInfo(PermissionsFunctions.getCompanyPath(company.id), action, company, this.contextProvider.get(), field) :
                    { granted: false, condition: undefined, fieldsPermissions: {} };
        }
    }

}
