import { FileUploader } from '@unifii/library/common';
import { Progress, generateUUID } from '@unifii/sdk';

import { OfflineQueue } from './offline-queue';

export class OfflineFileUploader implements FileUploader {

    constructor(private offlineQ: OfflineQueue, private dataId: string) { }

    upload(input: File): Promise<Progress> {
        return this.offlineQ.saveAttachment(this.dataId, generateUUID(), input);
    }

    getUrl(): Promise<string> {
        return Promise.resolve(null as any as string);
    }

}
