<form (ngSubmit)="authorize()" class="uf-grid uf-container-sm">

    <div class="col-12 row center-all justify-center">
        <h5 class="no-margin padded--horizontal">{{ tenantSettings?.name }}</h5>

        <div *ngIf="canChangeCompany">
            <button [title]="discoverTK.ActionChangeCompany | translate" [disabled]="inProgress"
                (click)="changeTenant()" type="button" class="uf-action small tertiary right">
                <uf-icon name="change" />
            </button>
        </div>

    </div>

    <uf-text *ngIf="formGroup.controls.username" [label]="commonTK.UsernameLabel | translate"
        [control]="formGroup.controls.username | asUfControl" name="username" autocomplete="username" class="col-12" />

    <uf-password *ngIf="formGroup.controls.password" [label]="commonTK.PasswordLabel | translate" [showReveal]="true"
        [control]="formGroup.controls.password | asUfControl" name="password" autocomplete="password" class="col-12" />

    <ng-template [ngIf]="rememberMeEnabled">

        <uf-checkbox *ngIf="formGroup.controls.rememberMe" [label]="discoverTK.LoginRemeberMeInputLabel | translate"
            [control]="formGroup.controls.rememberMe | asUfControl" name="rememberMe" class="col-12" />

    </ng-template>

    <div class="col-12 col center-all space-children">
        <button [disabled]="inProgress" type="submit" class="uf-button primary">
            {{ sharedTermsTK.ActionLogin | translate }}
        </button>
        <a [routerLink]="['/login', 'request-password-change']" class="uf-button tertiary">
            {{discoverTK.ActionRequestPasswordChange | translate}}
        </a>
    </div>

</form>