import { ErrorType, UfRequestError } from '@unifii/sdk';

export type AppError = UfRequestError;

export class NotFoundError extends UfRequestError {

    constructor(message: string, code?: string) {
        super(message, ErrorType.NotFound, code);
    }

}

export class LoadError extends UfRequestError {

    constructor(message: string, code?: string) {
        super(message, undefined, code);
    }

}

export class SaveError extends UfRequestError {

    constructor(message: string, code?: string) {
        super(message, undefined, code);
    }

}
