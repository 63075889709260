<uf-panel *ngIf="form" class="container bg-gray">

    <div [class.pad-top]="!breadcrumbs.length" class="uf-container-lg bg-solid">
        <div *ngIf="breadcrumbs.length" class="uf-app-bar transparent">
            <uf-breadcrumbs [breadcrumbs]="breadcrumbs" [minimize]="true" class="small left">
                <span *ngIf="edited" class="suffix">*</span>
            </uf-breadcrumbs>
        </div>

        <div *ngIf="!loading" [formGroup]="form" class="uf-grid row-gap-lg medium">

            <div class="uf-box col-12">

                <div class="uf-app-bar accent">
                    <div class="title">
                        {{shellTK.DetailsTitle | translate}}
                    </div>
                </div>

                <div class="uf-grid pad-sides pad-top pad-bottom">

                    <ng-template [ngIf]="originalUserStatus === userStatus.Pending">
                        <uf-message *ngIf="emailControl?.dirty"
                            [content]="discoverTK.UserDetailsPendingStatusUnsavedMessage | translate"
                            icon="warningSolid" class="col-12 warning" />
                        <uf-message [content]="discoverTK.UserDetailsPendingStatusMessage | translate" icon="infoSolid"
                            class="col-12 info">
                            <button *ngIf="canInvite && emailControl?.value" [disabled]="emailControl?.dirty"
                                (click)="reInvite()" type="button" class="uf-button small right">
                                {{discoverTK.UserActionResendInvite | translate}}
                            </button>
                        </uf-message>
                    </ng-template>

                    <uf-user-description [userInfo]="userInfo" [showOnly]="userDescriptionIdentifiers" class="col-12" />

                    <uf-text *ngIf="usernameControl && usernameControl?.enabled"
                        [label]="labelDictionary[userInfoKeys.Username]" [control]="usernameControl"
                        class="col-12 col-md-6">
                        <span *ngIf="usernameRequired" class="suffix">*</span>
                    </uf-text>
                    <uf-text *ngIf="firstNameControl && firstNameControl?.enabled"
                        [label]="labelDictionary[userInfoKeys.FirstName]" [control]="firstNameControl"
                        class="col-12 col-md-6">
                        <span *ngIf="firstNameRequired" class="suffix">*</span>
                    </uf-text>
                    <uf-text *ngIf="lastNameControl && lastNameControl?.enabled"
                        [label]="labelDictionary[userInfoKeys.LastName]" [control]="lastNameControl"
                        class="col-12 col-md-6">
                        <span *ngIf="lastNameRequired" class="suffix">*</span>
                    </uf-text>
                    <uf-text *ngIf="emailControl && emailControl?.enabled" [label]="labelDictionary[userInfoKeys.Email]"
                        [control]="emailControl" class="col-12 col-md-6">
                        <span *ngIf="emailRequired" class="suffix">*</span>
                    </uf-text>
                    <uf-text *ngIf="phoneControl && phoneControl?.enabled" [label]="labelDictionary[userInfoKeys.Phone]"
                        [control]="phoneControl" class="col-12 col-md-6">
                        <span *ngIf="phoneRequired" class="suffix">*</span>
                    </uf-text>
                    <uf-company-input *ngIf="companyControl && companyControl?.enabled"
                        [label]="labelDictionary[userInfoKeys.Company]" [editableCompanies]="allowedCompanies"
                        [control]="companyControl" class="col-12 col-md-6">
                        <span *ngIf="companyRequired" class="suffix">*</span>
                    </uf-company-input>
                </div>

                <div *ngIf="passwordControl" class="uf-grid pad-bottom">
                    <uf-password-change [user]="userInfo" [control]="form" class="col-12" />
                </div>
                <div *ngIf="lastActivationReasonControl && isActiveControl && isActiveControl.enabled"
                    class="uf-grid pad-bottom">
                    <uf-status-change [lastActivationReasonControl]="lastActivationReasonControl"
                        [isActiveControl]="isActiveControl" [user]="userInfo" class="col-12" />
                </div>
            </div>

            <div *ngIf="managerInfo || managerControl?.enabled" class="uf-box col-12">
                <div class="uf-app-bar accent">
                    <div class="title">
                        {{ commonTK.ManagerLabel | translate }}
                    </div>
                    <button *ngIf="managerControl?.enabled && managerInfo"
                        [title]="sharedTermsTK.ActionDelete | translate" (click)="selectManager(null)" type="button"
                        class="uf-action">
                        <uf-icon name="delete" />
                    </button>
                </div>
                <div class="uf-grid pad">
                    <uf-search-result *ngIf="managerControl?.enabled && !managerInfo" [options]="managerOptions"
                        (searchChange)="searchUsers($event)" (valueChange)="selectManager($event)"
                        nameProperty="_display" class="col-12 default" />
                    <uf-data-display-list *ngIf="managerInfo" [items]="managerInfo" class="col-12" />
                </div>
            </div>

            <div *ngIf="virtualMfaCodeControl" class="uf-box col-12">
                <div class="uf-app-bar accent">
                    <div class="title">{{ commonTK.MfaLabel | translate }}</div>
                </div>
                <div class="uf-grid pad">
                    <ul *ngIf="virtualMfaCodeControl" class="uf-list uf-box flat col-12">
                        <li class="pad-left pad">
                            <uf-virtual-mfa-code-input [control]="virtualMfaCodeControl" />
                        </li>
                    </ul>
                </div>
            </div>

            <div *ngIf="displayAuthProviderInfos.length" class="uf-box col-12">
                <div class="uf-app-bar accent">
                    <div class="title">
                        {{ discoverTK.UserAuthProviderDetailTitle | translate }}
                    </div>
                </div>
                <div *ngFor="let info of displayAuthProviderInfos"
                    [class.border-bottom]="displayAuthProviderInfos.length" class="pad">
                    <uf-data-display-list>
                        <dt>{{ discoverTK.UserAuthProviderTypeLabel | translate }}</dt>
                        <dd>{{ info.type }}</dd>
                        <dt>{{ discoverTK.UserAuthProviderTenantLabel | translate }}</dt>
                        <dd>{{ info.tenant }}</dd>
                        <ng-container *ngIf="info.roles.length">
                            <dt>{{ commonTK.RolesLabel | translate }}</dt>
                            <dd>{{ getRolesDisplays(info.roles).join(', ') }}</dd>
                        </ng-container>
                        <ng-container *ngIf="info.claims.length">
                            <dt>{{ commonTK.ClaimsLabel | translate }}</dt>
                            <dd>
                                <span *ngFor="let claim of info.claims">
                                    {{ claim }}<br>
                                </span>
                            </dd>
                        </ng-container>
                        <ng-container *ngIf="info.units.length">
                            <dt>Units</dt>
                            <dd>
                                <span *ngFor="let unit of info.units">
                                    {{ unit }}<br>
                                </span>
                            </dd>
                        </ng-container>
                    </uf-data-display-list>
                </div>
            </div>

            <div *ngIf="unitsControl && unitsControl.enabled" class="uf-box col-12 gap-top-sm">
                <div class="uf-app-bar flat accent">
                    <div class="title">{{ labelDictionary[userInfoKeys.Units] }}</div>
                </div>
                <div class="uf-grid pad-bottom">
                    <uf-hierarchy-input [control]="unitsControl" [lockedUnits]="lockedConfig.units" class="col-12" />
                </div>
            </div>

            <div *ngIf="rolesControl" class="uf-box col-12">
                <div class="uf-app-bar accent">
                    <div class="title">{{ commonTK.RolesLabel | translate }}</div>
                </div>
                <div class="pad">
                    <uf-role-input [control]="rolesControl" [lockedRoles]="lockedConfig.roles"
                        [editableRoles]="allowedRoles" />
                </div>
            </div>

            <div *ngIf="claimsControl && showClaimsSection" class="uf-box col-12">
                <div class="uf-app-bar accent">
                    <div class="title">{{ commonTK.ClaimsLabel | translate }}</div>
                </div>
                <div class="uf-grid">
                    <uf-user-description *ngIf="claimDescriptionIdentifiers.length" [userInfo]="userInfo"
                        [showOnly]="claimDescriptionIdentifiers" class="col-12 pad border-bottom" />
                    <uf-claim-editor *ngIf="claimsControl.enabled" [control]="claimsControl"
                        [editableClaimsValues]="allowedClaimsValues" [width]="fieldWidth.Half" class="col-12" />
                </div>
            </div>

            <div *ngIf="isMe && pinService.isEnabled()" class="uf-box col-12 gap-lg-top">
                <div class="uf-app-bar accent">
                    <div class="title">
                        {{discoverTK.PINSettingsLabel | translate}}
                    </div>
                </div>
                <ud-pin-input [pinTimeout]="pinTimeout" class="uf-grid pad" />
            </div>
        </div>

        <div class="uf-form-actions gap-top">

            <button *ngIf="isMe && canUpdate" (click)="save()" type="button" class="uf-button primary right">
                {{sharedTermsTK.ActionSave | translate}}
            </button>

            <ng-container *ngIf="!isMe">
                <button (click)="back()" type="button" class="uf-button tertiary left">
                    {{sharedTermsTK.ActionCancel | translate}}
                </button>
                <button *ngIf="canDelete" (click)="deleteUser()" type="button" class="uf-button">
                    {{sharedTermsTK.ActionDelete | translate}}
                </button>
                <button *ngIf="canUpdate" (click)="save()" type="button" class="uf-button primary">
                    {{sharedTermsTK.ActionSaveAndClose | translate}}
                </button>
            </ng-container>
        </div>
    </div>

</uf-panel>