import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Capacitor } from '@capacitor/core';
import { encrypt } from '@unifii/sdk';

import { Config, Environment } from 'config';
import { AuthorizePath, ExternalPath } from 'discover/discover-constants';
import { AppModule } from 'shell/app.module';
import { createIdentityRedirectUri, createIdentityUrl } from 'shell/services/identity-functions';
import { TokenService } from 'shell/services/token.service';

import 'hammerjs';

(async() => {
    let config: Config;
    const configElement = window.document.getElementById('env') as HTMLElement;

    try {
        config = JSON.parse(configElement.innerHTML);
    } catch (e) {
        const response = await fetch('environment.json');

        config = await response.json();
        // fix goLang variable for localhost
        window.document.title = config.env;
    }

    if (config.env !== 'localhost' && config.env !== 'dev') {
        enableProdMode();
    }

    // 8031 server connect to localhost unifii-api server
    if (config.env === 'localhost' && window.location.port === '8031') {
        config.unifii.tenant = 'default';
        config.unifii.baseUrl = 'http://localhost:5171';
    }

    // if configured authProvider, not capacitor & not authenticated, perform identity authorization.
    if (config.authProvider && !Capacitor.isNativePlatform() && !window.location.pathname.includes(`/${AuthorizePath}`)) {

        const tokenService = new TokenService(localStorage, config);

        if (!tokenService.token) {
            const redirectUri = new URL(createIdentityRedirectUri(window));

            if (window.location.pathname.startsWith(`/${ExternalPath}/`)) {
                redirectUri.searchParams.append('next', window.location.pathname);
            }

            const encryptedState = await encrypt(config.unifii.appId, new URLSearchParams({ redirectUri: redirectUri.toString() }).toString());
            const identityUrl = createIdentityUrl(config.authProvider.authorizationEndpoint, config.unifii.appId as string, redirectUri.toString(), encryptedState.byteString);

            window.location.href = identityUrl;

            return;
        }

    }

    const environment = JSON.parse(JSON.stringify(config)) as Config;

    platformBrowserDynamic([{ provide: Environment, useValue: environment }, { provide: Config, useValue: config }])
        .bootstrapModule(AppModule)
        .catch((err) => { console.error(err); });

})();
