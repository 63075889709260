<ng-template [ngIf]="show">
    <div class="row item">
        <ng-container *ngIf="node.type === 'Empty'; else normalNode">
            <div [style.margin-left.px]="level*12" class="grow row heading">
                <!-- <uf-icon *ngIf="node.icon" [name]="node.icon"></uf-icon> -->
                {{node.name}}
            </div>
            <div *ngIf="accessInfo.hasAccessibleChildren" (click)="expanded = !expanded"
                class="clickable expander-button">
                <uf-icon [name]="expanded ? 'menuItemCollapse' : 'menuItemExpand'" />
            </div>
        </ng-container>
        <ng-template #normalNode>

            <!-- href and routerLink don't play well -->
            <a *ngIf="href" [attr.href]="href" [class.active]="isActive" [style.margin-left.px]="level*12"
                class="grow clickable selectable">
                <!-- <uf-icon *ngIf="node.icon" [name]="node.icon"></uf-icon> -->
                <div>{{node.name}}</div>
            </a>

            <!-- Hence the 2 variations -->
            <a *ngIf="link" [routerLink]="link" [class.active]="isActive" [style.margin-left.px]="level*12"
                class="grow clickable selectable">
                <!-- <uf-icon *ngIf="node.icon" [name]="node.icon"></uf-icon> -->
                <div>{{node.name}}</div>
            </a>

            <!-- Not accessible node, just show its label -->
            <div *ngIf="!href && !link" [style.margin-left.px]="level*12" class="grow row heading">
                <!-- <uf-icon *ngIf="node.icon" [name]="node.icon"></uf-icon> -->
                {{node.name}}
            </div>

            <!-- Expand/Collapse toggle -->
            <a *ngIf="accessInfo.hasAccessibleChildren" (click)="expanded = !expanded"
                class="clickable expander-button">
                <uf-icon [name]="expanded ? 'menuItemCollapse' : 'menuItemExpand'" />
            </a>
        </ng-template>
    </div>
    <ng-container *ngIf="expanded">
        <us-nav-item *ngFor="let n of node.children" [node]="n" [level]="level+1" />
    </ng-container>
</ng-template>