import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedTermsTranslationKey, WindowWrapper } from '@unifii/library/common';

import { ShellTranslationKey } from 'shell/shell.tk';

@Injectable({ providedIn: 'root' })
export class BrowserGuard {

    // eslint-disable-next-line @typescript-eslint/naming-convention
    readonly MSIE = 'MSIE';

    allowed = new Promise<void>((resolve, reject) => {

        if (!this.isInternetExplorer) {
            resolve();
        } else {
            this.appendMessage();
            reject();
        }
    });

    constructor(
        @Inject(WindowWrapper) private window: Window,
        @Inject(DOCUMENT) private document: Document,
        private translate: TranslateService,
    ) { }

    private appendMessage() {

        const paragraph = this.document.createElement('p');

        paragraph.setAttribute('style', 'position: absolute; margin: auto; left: 0; right: 0; top: 30px; text-align: center;padding: 16px;');
        paragraph.innerHTML =
            this.translate.instant(SharedTermsTranslationKey.BrowserNotSupported) +
            '<br>' +
            this.translate.instant(ShellTranslationKey.DownloadCompatibleBrowserMessage);

        this.document.getElementsByTagName('body')[0]?.appendChild(paragraph);
    }

    private get isInternetExplorer(): boolean {

        const { userAgent } = this.window.navigator;

        return userAgent.includes(this.MSIE) || !!userAgent.match(/Trident\/7\./);
    }

}

