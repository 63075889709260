import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { CompaniesClient, PermissionAction } from '@unifii/sdk';

import { DiscoverContext } from 'discover/discover-context';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';

export const companyGuard: CanActivateFn = async() => {

    const context = inject(DiscoverContext);
    const companiesClient = inject(CompaniesClient);
    const auth = inject(Authentication);
    const companyId = auth.userInfo?.company?.id;

    try {
        if (
            companyId &&
            auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getCompanyPath(companyId), PermissionAction.Read).granted
        ) {
            const company = await companiesClient.get(companyId);

            context.company = company;
        }
    } catch (err) {
        console.warn('companyGuard - failed to load user company', err);
    }

    return true;
};
