import { InjectionToken, Type } from '@angular/core';
import { Modal } from '@unifii/library/common';
import { Observable } from 'rxjs';

import { ProjectPinDetails } from 'discover/discover-model';

export interface PinModalData {
    message?: string;
    closeAllowed?: boolean;
}

export enum PinState {
    Verified = 'Verified',
    NotVerified = 'NotVerified',
    Logout = 'Logout',
    Closed = 'Closed'
}

export interface PinFingerPrintConfig {
    clientId: string;
    clientSecret?: string;
    disableBackup?: boolean;
    localizedFallbackTitle?: string;
    localizedReason?: string;
}

export interface PinTimeoutOption {
    name: string;
    id: number;
    default?: boolean;
}

export interface PinService {
    timeoutOptions: PinTimeoutOption[];
    fingerprintConfig: PinFingerPrintConfig;
    pinSetupComponent: Type<Modal<PinModalData, PinState>>;
    pinCheckComponent: Type<Modal<PinModalData, PinState>>;
    passwordCheckComponent: Type<Modal<PinModalData, PinState>>;

    isEnabled(): boolean;
    init(): Promise<void>;
    clearPin(tenant: string, userId: string): void;
    checkPinTrigger(): Observable<any>;
    savePin(pin: string, fingerprint: boolean): Promise<void>;
    verifyPin(pin: string): Promise<boolean>;
    changeTimeout(ms: number): void;
    getPinDetails(): ProjectPinDetails;
    showPinReset(): Promise<boolean>;
}

export const PinService = new InjectionToken<PinService>('PinService');
