<ud-user-access-box [backButtonRoute]="backButtonRoute">
    <ng-container content>

        <uf-message [style.visibility]="error ? 'visible' : 'hidden'" class="error gap-sm-bottom gap-sm-top"
            icon="errorSolid">
            <p class="small">{{ error?.message }}</p>
        </uf-message>

        <p *ngIf="message" [innerHTML]="message"></p>

        <router-outlet />

    </ng-container>
</ud-user-access-box>