import { Injectable, NgZone, inject } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { PushNotificationSchema, PushNotifications } from '@capacitor/push-notifications';
import { RouteInfo } from '@unifii/sdk';
import { Observable, Subject } from 'rxjs';

import { DeviceService } from './device.service';

export interface PushNotificationData {
	title: string;
	message: string;
	route?: RouteInfo;
	action: boolean;
}

@Injectable({ providedIn: 'root' })
export class PushNotificationService {

	private zone = inject(NgZone);
	private deviceService = inject(DeviceService);
	private _notifications = new Subject<PushNotificationData>();

	constructor() {

		if (!this.deviceService.isNative() || !Capacitor.isPluginAvailable('PushNotifications')) {
			return;
		}

		void PushNotifications.addListener('pushNotificationReceived', (notification) => {
			this.zone.run(() => {
				this.createNotification(notification);
			});
		});

		void PushNotifications.addListener('pushNotificationActionPerformed', (actionPerformed) => {
			this.zone.run(() => {
				this.createNotification(actionPerformed.notification, true);
			});
		});
	}

	/**
	 * Initializes push notifications and returns a promise with registrationId
	 */
	init(): Promise<string | null> {

		if (!(this.deviceService.isNative() && Capacitor.isPluginAvailable('PushNotifications'))) {
			return Promise.resolve(null);
		}

		return new Promise((resolve) => {

			void PushNotifications.requestPermissions().then((result) => {

				if (result.receive !== 'granted') {
					resolve(null);

					return;
				}

				void PushNotifications.register();
			});

			void PushNotifications.addListener('registration', (token) => {
				this.zone.run(() => {
					resolve(token.value);
				});
			});

			void PushNotifications.addListener('registrationError', (error) => {
				console.error('Push notification error', error);
			});

		});
	}

	/**
	 * Emits received notifications
	 */
	get notifications(): Observable<PushNotificationData> {
		return this._notifications;
	}

	/**
	 * For testing purposes
	 */
	emit(notification: PushNotificationData) {
		this._notifications.next(notification);
	}

	private createNotification(notification: PushNotificationSchema, action = false) {
		this._notifications.next({
			title: notification.title ?? '',
			message: notification.body ?? '',
			route: {
				tenant: notification.data.tenant ?? '',
				projectId: notification.data.projectId ?? '',
				bucket: notification.data.bucket ?? '',
				id: notification.data.id ?? '',
				nodeId: notification.data.nodeId ?? '',
			},
			action,
		});
	}

}
