<ng-container *ngIf="!reloading && !printConfig && !triggerError && formData">
    <uf-form [definition]="definition" [config]="formConfig" [disabled]="isDisabled" [(formData)]="formData"
        (submit)="save($event)" (cancelClick)="back()" (edited)="onEdited($event)">
        <div header>
            <div class="uf-app-bar transparent">
                <uf-data-display-list *ngIf="formData?.id" class="grow stacked print-info">
                    <dt>{{commonTK.FormMetadataFieldIdLabel | translate}}</dt>
                    <dd>{{formData.id}}</dd>
                </uf-data-display-list>

                <uf-breadcrumbs *ngIf="breadcrumbs.length" [breadcrumbs]="breadcrumbs" [minimize]="true"
                    class="small grow left gap-sm-left" />

                <button *ngIf="definition" (click)="print()" type="button" class="uf-action default print-btn">
                    <uf-icon name="print" />
                </button>
            </div>

            <p *ngIf="parentInfo" class="body-copy gap-none-top gap-none-bottom pad-sides small">
                {{ shellTK.FormOriginatedBy | translate }} <a
                    [routerLink]="parentInfo.routerLink">{{parentInfo.label}}</a>
            </p>

        </div>

        <footer footer>
            <uf-data-display-list *ngIf="definition.version != null" class="pad small small-label">
                <dt>{{commonTK.FormMetadataFieldDefinitionVersionLabel | translate }}</dt>
                <dd>{{definition.version}}</dd>
            </uf-data-display-list>
        </footer>
    </uf-form>
</ng-container>

<uf-panel *ngIf="triggerError" class="container bg-solid">
    <div class="uf-container-md">
        <div class="uf-grid pad">
            <uf-message [content]="triggerError.message" icon="errorSolid" class="error gap">
                <h3>{{triggerError.code}}</h3>
            </uf-message>
        </div>
    </div>
</uf-panel>

<uf-print-form *ngIf="printConfig" [config]="printConfig" (done)="printConfig = undefined" />