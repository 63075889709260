import { Injectable, inject } from '@angular/core';
import { WindowWrapper } from '@unifii/library/common';

// cordova-plugin-fingerprint-aio
@Injectable({ providedIn: 'root' })
export class FingerPrintService {

    private window = inject<Window>(WindowWrapper);

    isAvailable(): Promise<string> {

        if (!this.window.Fingerprint) {
            return Promise.reject();
        }

        return new Promise((resolve, reject) => {
            this.window.Fingerprint.isAvailable(resolve, reject);
        });

    }

    show(opt: {
        clientId: string;
        clientSecret?: string;
        disableBackup?: boolean;
        localizedFallbackTitle?: string;
        localizedReason?: string;
    }): Promise<string> {
        return new Promise((resolve, reject) => {
            this.window.Fingerprint.show(opt, resolve, reject);
        });
    }

}
