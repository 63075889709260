import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewContainerRef, forwardRef, inject } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OptionsModalComponent } from '@unifii/components';
import { MessageLevel, ModalService } from '@unifii/library/common';
import { ContentType, ErrorType, Option, StructureNode, StructureNodeType, TableSourceType, UfError, isNumber, isString } from '@unifii/sdk';

import { ContentComponentFactory } from 'shell/content/content-component-factory';
import { AppContent, DiscoverContentType } from 'shell/content/content-types';
import { ShellService } from 'shell/core/shell.service';
import { DashboardPageComponent } from 'shell/dashboard/dashboard-page.component';
import { AppError } from 'shell/errors/errors';
import { ErrorMessageComponent } from 'shell/nav/error-message.component';
import { NavigationService } from 'shell/nav/navigation.service';
import { ContentDetails } from 'shell/services/content-details';
import { EditedDataService } from 'shell/services/edited-data.service';
import { NewItemPath } from 'shell/shell-constants';
import { ShellTranslationKey } from 'shell/shell.tk';
import { TablePageConfig } from 'shell/table/table-page-config';
import { TableDetailPageComponent } from 'shell/table-detail/table-detail-page.component';

interface ContentWrapper<T> {
    component: T;
    type: ContentType | StructureNodeType | DiscoverContentType;
    node?: StructureNode;
}

export enum TableDisplayMode {
    Detail = 'detail'
}

export const fade = trigger('fade', [
	transition(':leave', [
		animate('400ms ease', style({ opacity: 0 })),
	]),
]);

/**
 * Manage dynamic content inside Structure navigation
 * Load the Content associated with a StructureNode
 * Following node/contents are allowed:
 *
 *  HomePage  (Empty, View, Page, Dashboard, Custom)
 *  Node (View, Page, Collection)
 *  Use ComponentSelector to lookup for the right Component
 */
@Component({
	selector: 'us-content-node',
	template: `<div *ngIf="!component" @fade [ngClass]="skeletonClassName" class="skeleton-container"></div>`,
	styleUrls: ['./content-node.less'],
	animations: [fade],
})
export class ContentNodeComponent<T> implements OnInit {

	protected skeletonClassName: string;
	protected component: T | ErrorMessageComponent;

	private type?: ContentType | StructureNodeType | DiscoverContentType;
	private shell = inject(ShellService);
	private container = inject(ViewContainerRef);
	private nav = inject(NavigationService);
	private translate = inject(TranslateService);
	private route = inject(ActivatedRoute);
	private contentDetails = inject(ContentDetails);
	private editedDataService = inject(EditedDataService);
	private modalService = inject(ModalService);
	private router = inject(Router);
	private parent = inject<ContentWrapper<any>>(forwardRef(() => ContentNodeComponent), { optional: true, skipSelf: true });
	private dashboardPageComponent = inject(DashboardPageComponent, { optional: true });
	private tablePageConfig = inject(TablePageConfig, { optional: true });
	private contentFactory = inject(ContentComponentFactory);

	async ngOnInit() {

		if (this.node && !this.canAccessNode(this.parent?.node ?? this.node)) {
			this.createErrorComponent(this.unauthorizedError);

			return;
		}

		try {

			const { params } = this.route.snapshot;

			// if route includes /new and has more than 1 option, select and route
			if (this.route.routeConfig?.path === NewItemPath &&
                !params.$definition &&
                this.tablePageConfig?.addOptions &&
                this.tablePageConfig.addOptions.length > 1) {
				return this.routeToSelectedOption(this.tablePageConfig.addOptions);
			}

			this.type = this.route.snapshot.data?.contentType; // type can be set directly on route
			const bucket = this.getValidBucket(params);
			const definitionIdentifier = await this.getValidDefinitionIdentifier(params);
			const id = await this.getValidId(params);
			const hasRollingVersion = this.tablePageConfig?.hasRollingVersion ?? false;
			const mode = params.mode;

			if (!this.type) {
				this.type = await this.getContentType(this.node?.type, definitionIdentifier, mode);
			}

			this.skeletonClassName = this.getSkeletonClassName(this.type, definitionIdentifier, this.node?.tags);

			this.component = await this.contentFactory.create(this.container, this.type, {
				id,
				bucket,
				hasRollingVersion,
				tags: this.node?.tags,
				identifier: definitionIdentifier,
				parentData: this.parent?.component instanceof TableDetailPageComponent ? this.parent.component : this.dashboardPageComponent ?? this.tablePageConfig ?? undefined,
			});

			if (!this.node?.nodeId) {
				this.shell.setTitle((this.component as AppContent).title);
			}

		} catch (e) {
			if (this.node?.nodeId === '0') {
				this.createHomePage();
			} else {
				console.warn(e);
				this.createErrorComponent(e as AppError);
			}
		}
	}

    get edited(): boolean {
		return this.editedDataService.getEditedStatus(this.component as Component);
    }

	private getParentParam(param: string): string | undefined {
		return this.route.snapshot.parent?.paramMap.get(param) ?? undefined;
	}

	private canAccessNode(node: StructureNode): boolean {
		if (node.nodeId === '0') {
			// Home page is always accessible
			return true;
		}

		return this.nav.getNodeAccessInfo(node)?.matchACLs === true;
	}

	private getValidBucket(params: Params): string | undefined {

		if (params.bucket) {
			return params.bucket;
		}

		return this.tablePageConfig?.bucket;
	}

	private getValidDefinitionIdentifier(params: Params): Promise<string | undefined> | string | undefined {

		// form-data new item
		if (this.type === ContentType.Form && params.id === NewItemPath) {
			return params.$definition as string;
		}

		//  collection items require parents identifier
		if (this.parent?.type === ContentType.Collection) {
			return this.getParentParam('identifier');
		}

		if (this.parent?.type === ContentType.Table) {

			if (params.$definition) {
				return params.$definition;
			}

			// get first option identifier
			if (this.route.routeConfig?.path === NewItemPath && this.tablePageConfig?.addOptions) {
				return this.tablePageConfig?.addOptions[0]?.identifier;
			}

			return this.getParentParam('identifier');
		}

		if (this.node?.definitionIdentifier) {
			return this.node.definitionIdentifier;
		}

		return params.identifier;
	}

	private async routeToSelectedOption(options: Option[]) {

		const label = this.translate.instant(ShellTranslationKey.FormBucketDialogAddFormTitle);

		const selected = await this.modalService.openMedium(OptionsModalComponent, { label, options });

		if (selected) {
			void this.router.navigate([{ $definition: selected.identifier }], { relativeTo: this.route });

			return;
		}

		void this.router.navigate(['..'], { relativeTo: this.route });
	}

	private async getValidId(params: Params): Promise<string | number | undefined> {

		// id is formIdentifier not formData
		if (this.parent?.type === ContentType.Table && await this.contentDetails.getContentType(params.id) === ContentType.Form) {
			return;
		}

		// form-data new item
		if (this.type === ContentType.Form && params.id === NewItemPath) {
			return;
		}

		if (this.node?.id) {
			return this.node.id;
		}

        if (isString(params.id) || isNumber(params.id)) {
            return params.id;
        }

        console.warn('Unrecognized id - ', params.id);

        return undefined;
	}

	private async getContentType(
		structureNodeType?: StructureNodeType,
		identifier?: string,
		mode?: TableDisplayMode,
	): Promise<ContentType | DiscoverContentType | StructureNodeType> {

		if (structureNodeType != null) {
			switch (structureNodeType) {
				case StructureNodeType.IFrame: return StructureNodeType.IFrame;
				case StructureNodeType.PdfViewer: return StructureNodeType.PdfViewer;
				case StructureNodeType.View: return ContentType.View;
				case StructureNodeType.Page: return ContentType.Page;
				case StructureNodeType.Collection: return ContentType.Collection;
				case StructureNodeType.CollectionItem: return ContentType.CollectionItem;
				case StructureNodeType.FormBucket: return ContentType.Table;
				case StructureNodeType.Form: return ContentType.Form;
				default: return StructureNodeType.Custom;
			}
		}

		if (this.parent?.type === ContentType.Collection) {
			return ContentType.CollectionItem;
		}

		if (this.parent?.type === ContentType.Table) {

			if (mode === TableDisplayMode.Detail) {
				return ContentType.Detail;
			}

			switch (this.tablePageConfig?.sourceType) {
				case TableSourceType.Company: return DiscoverContentType.Company;
				case TableSourceType.Users: return DiscoverContentType.User;
				default: return ContentType.Form;
			}
		}

		if (identifier) {

			const nodeType = await this.contentDetails.getContentType(identifier);

			if (nodeType) {
				return nodeType;
			}

		}
		throw this.notFoundError;
	}

	private createErrorComponent(error: AppError) {
		this.component = this.createComponent();
		this.component.error = error;
		this.component.message = error.message;
	}

	private createHomePage() {
		this.component = this.createComponent();
		this.component.title = this.translate.instant(ShellTranslationKey.ContentNodeHomePageTitle);
		this.component.message = this.translate.instant(ShellTranslationKey.ContentNodeHomePageMessage);
		this.component.level = MessageLevel.Info;
	}

	private createComponent(): ErrorMessageComponent {
		const ref = this.container.createComponent(ErrorMessageComponent, { index: 0, injector: this.container.injector });

		return ref.instance;
	}

	private getSkeletonClassName(type: ContentType | StructureNodeType | DiscoverContentType, identifier?: string, tags?: string[]): string {
		/**
         * Since detail is loaded in a resolver, when it's loading it's parent route we show the detail skeleton
         * if it's not detail we don't show anything because it's going to load the children route and then it's going to show the proper skeleton
         */
		const activeChildRoute = this.route.snapshot.children[0];

		if (activeChildRoute != null) {
			const mode = activeChildRoute?.params?.mode;

			if (mode === TableDisplayMode.Detail) {
				return 'uf-container detail';
			}

			return '';
			// TODO confirm that no skeleton should show when there are no children
			// if (type === ContentType.Table) {
			//     return '';
			// }
		}

		if ((identifier === 'directory-template' || (tags || []).includes('directory')) || type === ContentType.Collection) {
			return 'uf-container-md directory-template';
		}

		switch (type) {
			case ContentType.Table:
				return 'container table';
			case ContentType.Page:
			case ContentType.CollectionItem:
				return 'page-wrap page';
			case ContentType.View:
				return 'grid--fixed body-copy page';
			case ContentType.Form:
			case DiscoverContentType.User:
			case DiscoverContentType.UserProfile:
			case DiscoverContentType.Company:
				return 'uf-container-lg form';
			case ContentType.Detail:
				return 'uf-container detail';
			default:
				return 'skeleton';
		}
	}

	get node(): StructureNode | undefined {
		if (this.parent == null) {
			return this.nav.current ?? undefined;
		}

		return;
	}

	private get unauthorizedError(): UfError {
		return new UfError(this.translate.instant(ShellTranslationKey.ErrorRequestUnauthorized), ErrorType.Unauthorized);
	}

	private get notFoundError(): UfError {
		return new UfError(this.translate.instant(ShellTranslationKey.ErrorContentNotFound), ErrorType.NotFound);
	}

}
