import { Component } from '@angular/core';
import { RuntimeDefinition } from '@unifii/library/common';
import { Compound } from '@unifii/sdk';

import { ViewContent } from './content-types';

@Component({
    selector: 'us-view',
    templateUrl: './view.html',
})
export class ViewComponent implements ViewContent {

    definition: RuntimeDefinition;
    compound: Compound;
    title: string;

}
