<div class="uf-card">
    <div class="uf-app-bar">
        <button [title]="sharedTermsTK.ActionClose | translate" (click)="close()" ufAutofocus class="uf-action tertiary"
            type="button">
            <uf-icon name="close" />
        </button>
        <h3>{{commonTK.ScanBarcodeLabel | translate }}</h3>
    </div>
    <div class="col">
        <div *ngIf="loading" class="pad center-all">
            <uf-spinner type="dots" />
        </div>
        <video #video [class.hidden]="loading"></video>
        <canvas #canvas></canvas>
    </div>
</div>