<div class="uf-app-bar flat transparent">
    <div class="title">{{ context.project?.name }}</div>
    <button (click)="close()" type="button" class="uf-action tertiary">
        <uf-icon name="close" class="close" />
    </button>
</div>

<uf-panel class="grow">
    <div class="settings-items col">
        <ul>
            <li *ngIf="contentInfo">
                <a [class.disabled]="!updateAvailable" (click)="update()">
                    <uf-icon name="data" />
                    <span *ngIf="!updateAvailable">
                        {{discoverTK.SettingsDataVersionLabel | translate:{version: contentInfo.name} }}
                    </span>
                    <span *ngIf="updateAvailable">
                        {{discoverTK.SettingsUpdateToLabel | translate:{version: updateAvailable.name} }}
                    </span>
                    <uf-icon *ngIf="updateAvailable" name="warning--solid" />
                </a>
            </li>
            <li *ngIf="!config.flags?.hidePreviewMode && previewAvailable">
                <a [class.preview]="preview" (click)="toggle()">
                    <uf-icon name="preview" />
                    <span>{{discoverTK.SettingsActionChangeMode | translate}}</span>
                    <uf-icon [name]="preview ? 'd-checkbox--true' : 'd-checkbox'" />
                </a>
            </li>
            <li *ngIf="!config.flags?.hideRefreshApp">
                <a (click)="reload()">
                    <uf-icon name="refresh" />
                    <span>{{sharedTermsTK.ActionRefresh | translate}}</span>
                </a>
            </li>
            <li *ngIf="showChangeProject">
                <a [routerLink]="['/login', 'projects']" [queryParams]="{autofill: false}">
                    <uf-icon name="change" />
                    <span>{{discoverTK.SettingsActionChangeProject | translate}}</span>
                </a>
            </li>
            <li *ngIf="!config.flags?.hideSyncForms">
                <a [routerLink]="['/offline-forms']" (click)="close()" class="has-notification">
                    <uf-icon name="sync" />
                    <span>{{discoverTK.SettingsOfflineFormsLabel | translate}}</span>
                    <uf-icon *ngIf="offlineSyncRequired" name="warning--solid" />
                </a>
            </li>
            <ng-template [usFeatureFlag]="'translations'">
                <li *ngIf="availableLanguages.length > 1">
                    <a (click)="showChangeLanguage()" class="language">
                        <img src="assets/svg/language-{{translations.currentLanguage}}.svg">
                        <span>{{shellTK.ChangeLanguageTitle | translate}}</span>
                    </a>
                </li>
            </ng-template>
        </ul>

        <footer>
            <ul>
                <li *ngIf="showMyProfileLink && auth.userInfo; let user">
                    <a [routerLink]="[userProfilePath]">
                        <uf-icon name="edit" />
                        <span *ngIf="user.firstName || user.lastName">
                            {{user.firstName}} {{user.lastName}}
                        </span>
                        <span *ngIf="!user.firstName && !user.lastName">
                            {{user.username}}
                        </span>
                    </a>
                </li>
                <li *ngIf="context.project?.privacyPolicyUrl">
                    <a href="{{context.project?.privacyPolicyUrl}}" ufTarget="_blank">
                        <uf-icon name="privacyPolicy" />
                        <span>{{discoverTK.PrivacyPolicyLabel | translate}}</span>
                    </a>
                </li>
                <li *ngIf="!config.flags?.hideLogout">
                    <a (click)="auth.logout({askConfirmation: true})">
                        <uf-icon name="login" />
                        <span>{{sharedTermsTK.ActionLogout | translate}}</span>
                    </a>
                </li>
            </ul>
            <div class="logo-wrap row">
                <uf-data-display-list [items]="versionInfo" class="small small-label white gap-left" />
                <img src="{{config.unifii.productLogoSecondary}}" alt="Unifii" />
            </div>
        </footer>
    </div>
</uf-panel>