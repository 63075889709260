import { Inject, Injectable } from '@angular/core';
import { AppContext, ContextProvider } from '@unifii/library/common';
import { DATE_TIME_DATA_FORMAT, mapUserToUserContext } from '@unifii/sdk';
import format from 'date-fns/format';
import set from 'date-fns/set';

import { Authentication } from 'shell/services/authentication';

/** ContextProvider is responsible for providing context for expression parsing */
@Injectable()
export class ShellContextProvider implements ContextProvider {

    constructor(@Inject(Authentication) private auth: Authentication) { }

    get(): AppContext {
        const now = format(set(new Date(), { seconds: 0 }), DATE_TIME_DATA_FORMAT);

        return {
            now,
            user: mapUserToUserContext(this.auth.userInfo),
        };
    }

}
