import { Injectable } from '@angular/core';
import { Progress, generateUUID } from '@unifii/sdk';
import { Observable } from 'rxjs';

import { ContentIndexer } from 'shell/offline/content-indexer';
import { IndexedDbWrapper } from 'shell/offline/indexeddb-wrapper';
import { PostUpdateHook } from 'shell/offline/post-update-hook';

@Injectable()
export class FakePostUpdateHook implements PostUpdateHook {

    run(nextContentDB: IndexedDbWrapper): Observable<Progress> {

        return new Observable<Progress>((observer) => {

            console.log('Fake Index Start');

            const indexer = new ContentIndexer(nextContentDB);
            const entries = this.generateEntries();

            let done = 0;
            let promise = Promise.resolve();

            for (const entry of entries) {
                promise = promise.then(() => {
                    // console.log('Fake Index added', entry.key);
                    observer.next({ done: ++done, total: entries.length });

                    return indexer.index(entry.key, entry.text);
                });
            }

            promise.then(() => {
                console.log('Fake Index Done');
                observer.complete();
            });
        });
    }

    private generateEntries() {

        const quantity = { keys: 100, tokens: 2000, entries: 50, textMaxSize: 50 };
        const tokens = new Array(quantity.tokens).fill(Math.random().toString(36).replace(/[^a-z]+/g, '').substring(0, 25)) as string[];
        const keys = new Array(quantity.keys).fill(generateUUID()) as string[];

        return new Array(quantity.entries).fill(({
            key: keys[Math.floor(Math.random() * (keys.length - 1))],
            text: new Array(Math.floor(Math.random() * quantity.textMaxSize)).fill(tokens[Math.floor(Math.random() * (tokens.length - 1))]).join(' '),
        })) as { key: string; text: string }[];
    }

}
