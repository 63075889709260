<uf-panel ufMasterDetail class="container bg-gray">
    <div class="uf-container bg-solid">

        <div class="uf-app-bar transparent pad-sm-left">
            <uf-breadcrumbs [breadcrumbs]="breadcrumbs" [minimize]="true" class="small left" />
        </div>

        <uf-message *ngIf="error" [content]="error.message" icon="error" class="error" />

        <div *ngIf="form" class="uf-grid pad-sm-sides">
            <div [formGroup]="form" class="uf-box col-12 ng-untouched ng-pristine ng-valid ng-star-inserted">
                <div class="uf-app-bar accent medium">
                    <div class="title">
                        {{shellTK.DetailsTitle | translate}}
                    </div>
                </div>
                <div class="gap-top pad-sides pad-bottom">
                    <uf-company-description *ngIf="companyDisabledIdentifiers.length && company" [company]="company"
                        [showOnly]="companyDisabledIdentifiers" class="pad-bottom" />

                    <uf-text *ngIf="nameControl && nameControl.enabled" [label]="commonTK.CompanyNameLabel | translate"
                        [control]="nameControl" class="pad-bottom">
                        <span *ngIf="isNameControlRequired" class="suffix">*</span>
                    </uf-text>
                    <uf-select *ngIf="statusControl && statusControl.enabled" [control]="statusControl"
                        [label]="commonTK.CompanyStatusLabel | translate" [options]="companyStatusOptions"
                        valueProperty="identifier" nameProperty="name" class="pad-bottom">
                        <span *ngIf="isStatusControlRequired" class="suffix">*</span>
                    </uf-select>
                </div>
            </div>
        </div>

        <div class="row gap-lg-top">
            <button (click)="back()" type="button" class="uf-button tertiary left">
                {{sharedTK.ActionCancel | translate}}
            </button>
            <button *ngIf="form?.enabled" (click)="save()" type="button" class="uf-button primary">
                {{(company ? sharedTK.ActionSave : sharedTK.ActionCreate) | translate}}
            </button>
        </div>

    </div>
</uf-panel>

<router-outlet />