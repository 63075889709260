import { getPlatform } from '@angular/core';
import { isBoolean } from '@unifii/sdk';

import { EditedDataService } from '../services/edited-data.service';

/**
 * Descriptor used to update and access edited data service boolean value
 */
export const editedData: PropertyDecorator = (target: any, propertyKey: string | symbol) => {

    const originalNgOnDestroy = target.ngOnDestroy;

    target.ngOnDestroy = function() {

        const editedDataService = getPlatform()?.injector.get(EditedDataService, undefined, { optional: true });

        editedDataService?.setEditedStatus(this, false);

        if (originalNgOnDestroy) {
            originalNgOnDestroy.apply(this);
        }
    };

    Object.defineProperty(target, propertyKey, {
        get(): boolean {
            const editedDataService = getPlatform()?.injector.get(EditedDataService, undefined, { optional: true });

            return editedDataService?.getEditedStatus(this) ?? false;
        },

        set(value: boolean) {

            if (!isBoolean(value)) {
                throw Error('EditedData Property must be Boolean');
            }

            const editedDataService = getPlatform()?.injector.get(EditedDataService, undefined, { optional: true });

            editedDataService?.setEditedStatus(this, value);
        },
        enumerable: true,
        configurable: true,
    });
};
