import { Component, OnInit, inject } from '@angular/core';
import { Compound, ImageProfile, PublishedContent } from '@unifii/sdk';

import { NavigationService } from 'shell/nav/navigation.service';
import { Authentication } from 'shell/services/authentication';

type CardHref = {
    label: string;
    imgUrl?: string;
    href: string;
}

type CardLink = {
    label: string;
    imgUrl?: string;
    link: string;
}

type CardLogout = {
    label: string;
    imgUrl?: string;
    logout: boolean;
}

type Card = CardHref | CardLink | CardLogout;

interface DirectoryCompound extends Compound{
    description?: string;
    buttons?: Button[];
} 

interface Button {
    link?: string;
    icon?: ImageProfile[];
    label: string;
    logout?: boolean;
}

const LogoutString = '/logout';

@Component({
    selector: 'ud-directory-template',
    templateUrl: './directory-template.html',
    styleUrls: ['./directory-template.less'],
})
export class DirectoryTemplateComponent implements OnInit {

    compound: DirectoryCompound; // Set by data Resolver

    protected logo: string | undefined;
    protected description: string | undefined;
    protected cards: Card[] = [];

    private nav = inject(NavigationService);
    private content = inject(PublishedContent);
    private auth = inject(Authentication);

    ngOnInit() {
        this.logo = this.getImage(this.compound.icon, { width: 600 });
        this.description = this.compound.description;
        if (this.compound.buttons) {
            this.cards = this.compound.buttons.map((button) => this.toCard(button)).filter((card): card is Card => !!card);
        }
    }

    protected logout() {
        this.auth.logout({ askConfirmation: true });
    }

    protected isCardHref(card: Card): card is CardHref {
        return (card as CardHref).href != null;
    }

    protected isCardLink(card: Card): card is CardLink {
        return (card as CardLink).link != null;
    }

    protected isCardLogout(card: Card): card is CardLogout {
        return (card as CardLogout).logout != null;
    } 

    private toCard(button: Button): Card | undefined {

        if (!button) {
            return;
        }

        const safeLinkValue = button.link ? (button.link as string).trim() : undefined;

        const link = this.getLink(safeLinkValue);
        const href = this.getHref(safeLinkValue);
        const imgUrl = this.getImage(button.icon, { width: 300 });
        const label = button.label;
                
        if (link === LogoutString) {
            return { label, imgUrl, logout: true } as CardLogout;
        } 

        const node = this.nav.getNode(link?.split('/')[2]);

        if (node && !this.nav.canAccessNode(node)) {
            return;
        }

        if (href) {
            return { label, imgUrl, href } as CardHref;
        }

        if (link) {
            return { label, imgUrl, link } as CardLink;
        }

        return;
    }

    private getLink(link: string | undefined): string | undefined {

        if (link != null && !link.startsWith('https://') && !link.startsWith('http://')) {
            return link;
        }

        return;
    }

    private getHref(href: string | undefined): string | undefined {

        if (href != null && (href.startsWith('https://') || href.startsWith('http://'))) {
            return href;
        }

        return;
    }

    private getImage(profile: ImageProfile[] | undefined, options: { width: number }): string | undefined {

        if (profile?.[0]?.url == null) {
            return;
        }

        return this.content.buildImageUrl(profile[0], options);
    }

}
