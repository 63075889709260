import { TableInputManager, TableInputs } from '@unifii/components';
import { FilterEntry, FilterValue, TableDataSource, TableDataSourceQueryOptions } from '@unifii/library/common';
import { ClientGetOptions, Query, RequestAnalyticsOrigin, UserInfo, UsersClient } from '@unifii/sdk';

export class UsersTableDataSource extends TableDataSource<UserInfo> {

    private _countPromise: Promise<number | undefined>;

    constructor(
        private usersClient: UsersClient,
        private tableIdentifier: string,
        private tableInputManager: TableInputManager<FilterValue, FilterEntry>,
        private tableInputs?: TableInputs<FilterValue>,
        private showCount?: boolean,
    ) {
        super();

        this.loadCount();
    }

    get filtered(): boolean {
        return !!this.tableInputs?.q?.trim();
    }

    get sorted(): boolean {
        return this.tableInputs?.sort != null;
    }

    override get count() {
        return this._countPromise;
    }

    private get clientGetOptions(): ClientGetOptions {
        return {
            signal: this.loadController?.signal,
            analytics: { origin: RequestAnalyticsOrigin.Table, id: this.tableIdentifier },
        };
    }

    load(options?: TableDataSourceQueryOptions) {
        const query = this.buildQuery(options);

        return this.usersClient.query(query, this.clientGetOptions).then(
            (data) => { this.stream.next({ data }); },
            (error) => { this.stream.next({ error }); },
        );
    }

    override getDownloadUrl(options?: TableDataSourceQueryOptions): string | undefined {
        const query = this.buildQuery(options);

        return this.usersClient.getDownloadUrl(query);
    }

    private buildQuery(options?: TableDataSourceQueryOptions): Query {
        return this.tableInputManager.createQuery(this.tableInputs).limit(options?.limit, options?.offset);
    }

    private loadCount() {
        if (!this.showCount) {
            return;
        }

        this._countPromise = this.usersClient.count(this.buildQuery(), this.clientGetOptions);
    }

}
