import { Injectable } from '@angular/core';
import { DataDisplayListItem, FieldDescriptionService, RuntimeField, Scope } from '@unifii/library/common';

// TODO delete after DataDisplay feature has been well tested
/**
 * Extends the library field description service and modify or add values that are useful to the app context
 * eg: Add router link to app content
 */
@Injectable()
export class DiscoverFieldDescriptionService extends FieldDescriptionService {

    override transform(value: unknown, field: RuntimeField, scope?: Scope, format?: string): DataDisplayListItem[] | null {
        return super.transform(value, field, scope, format);
    }

}
