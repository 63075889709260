<uf-panel class="content-pane content scrollable bg-gray">
    <div *ngIf="!uploadResult && form" class="uf-container-lg bg-solid">
        <div [formGroup]="form" class="uf-grid pad-top row-gap-lg">
            <div class="uf-box col-12">
                <ng-container *ngIf="uploadType === userCsvUploadType.BASIC">
                    <div class="uf-app-bar accent medium">
                        <div class="title">{{ discoverTK.UsersBasicCSVTemplateTitle | translate }}</div>
                    </div>
                    <div class="pad-sides">
                        <p class="small">{{ discoverTK.UsersBasicCSVTemplateMessage | translate }}</p>
                        <p class="small">{{ discoverTK.UsersBasicCSVTemplateUsageMessage | translate }}</p>
                    </div>
                </ng-container>
                <ng-container *ngIf="uploadType === userCsvUploadType.ADVANCED">
                    <div class="uf-app-bar accent medium">
                        <div class="title">{{ discoverTK.UsersAdvancedCSVTemplateTitle | translate }}</div>
                    </div>
                    <div class="pad-sides">
                        <p class="small">{{ discoverTK.UsersAdvancedCSVTemplateMessage | translate }}</p>
                        <p class="small">{{ discoverTK.UsersAddMultipleAdvancedCSVMessage | translate }}
                            <span *ngIf="companyControl?.enabled">
                                {{ discoverTK.UsersAddMultipleAdvancedCSVMessageCompany | translate }}
                            </span>
                        </p>
                    </div>
                </ng-container>
                <div class="pad-right pad-bottom">
                    <a [href]="csvTemplatePath" download class="uf-button right small">
                        {{ discoverTK.UsersDownloadCSVTemplate | translate }}
                    </a>
                </div>
            </div>
            <div class="uf-box col-12">
                <div class="uf-app-bar accent medium">
                    <div *ngIf="uploadType === userCsvUploadType.BASIC" class="title">
                        {{ discoverTK.UsersAddMultipleBasicCSVTitle | translate }}</div>
                    <div *ngIf="uploadType === userCsvUploadType.ADVANCED" class="title">
                        {{ discoverTK.UsersAddMultipleAdvancedCSVTitle | translate }}</div>
                </div>
                <div *ngIf="fileControl" class="uf-grid condensed">
                    <div *ngIf="!fileControl.value.length" class="col-12 pad-sides">
                        <p class="small">{{ discoverTK.UsersAddMultipleBasicCSVMessage | translate }}</p>
                    </div>
                    <div *ngIf="fileControl.value.length" class="col-12 pad-bottom">
                        <ul class="uf-list">
                            <li *ngFor="let file of fileControl.value; let i = index" class="uf-list-item">
                                <span class="grow">{{ file.name }}</span>
                                <button [title]="sharedTermsTK.ActionRemove | translate" (click)="removeFile()"
                                    type="button" class="uf-action tertiary small">
                                    <uf-icon name="delete" />
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div class="col-12 pad-sides pad-bottom">
                        <uf-file [formControlName]="userInputControlKey.File" type=".csv" />
                        <uf-error [control]="fileControl" />
                    </div>
                </div>
            </div>

            <div *ngIf="canChangeUsernameControl && canChangeUsernameControl?.enabled" class="col-12 uf-box">
                <div class="uf-app-bar accent medium">
                    <div class="title">{{ commonTK.UsernameLabel | translate }}</div>
                </div>
                <div class="uf-grid pad">
                    <uf-checkbox [control]="canChangeUsernameControl"
                        [label]="labelDictionary[userInfoControlKeys.CanChangeUsername]" class="col-12" />
                </div>
            </div>

            <div *ngIf="changePasswordOnNextLoginControl && changePasswordOnNextLoginControl?.enabled"
                class="col-12 uf-box">
                <div class="uf-app-bar accent medium">
                    <div class="title">{{ commonTK.PasswordLabel | translate }}</div>
                </div>
                <div class="uf-grid pad">
                    <uf-checkbox [control]="changePasswordOnNextLoginControl"
                        [label]="discoverTK.UserCreateNewPasswordLabel | translate" class="col-12" />
                </div>
            </div>

            <div *ngIf="companyControl && companyControl?.enabled" class="col-12 uf-box">
                <div class="uf-app-bar accent medium">
                    <div class="title">{{ commonTK.CompanyLabel | translate }}</div>
                </div>
                <div class="pad">
                    <uf-company-input [control]="companyControl" [editableCompanies]="allowedCompanies"
                        class="col-12 col-md-6" />
                </div>
            </div>

            <div *ngIf="unitsControl && unitsControl.enabled" class="uf-box col-12 gap-top-sm">
                <div class="uf-app-bar flat medium accent">
                    <div class="title">{{ commonTK.HierarchyLabel | translate }}</div>
                </div>
                <div class="uf-grid pad-bottom">
                    <uf-hierarchy-input [control]="unitsControl" class="col-12" />
                </div>
            </div>

            <div *ngIf="rolesControl && rolesControl.enabled" class="col-12 uf-box">
                <div class="uf-app-bar medium accent">
                    <div class="title">{{ commonTK.RolesLabel | translate }}</div>
                </div>
                <div class="pad">
                    <uf-role-input [control]="rolesControl" [editableRoles]="allowedRoles" />
                </div>
            </div>

            <div *ngIf="claimsControl && claimsControl.enabled" class="col-12 uf-box">
                <div class="uf-app-bar accent medium">
                    <div class="title">{{ commonTK.ClaimsLabel | translate }}</div>
                </div>
                <uf-claim-editor [control]="claimsControl" [editableClaimsValues]="allowedClaimsValues"
                    [width]="fieldWidth.Half" class="col-12 pad-sm-bottom" />
            </div>

            <uf-blockquote *ngIf="error?.message" [content]="error.message" icon="errorSolid" class="col-12 error" />

            <div class="col-12 row">
                <button (click)="back()" type="button" class="uf-button tertiary left">
                    {{sharedTermsTK.ActionCancel | translate}}
                </button>
                <button [disabled]="uploading" (click)="upload()" type="button" class="uf-button primary">
                    {{ saveButtonLabel }}
                </button>
            </div>
        </div>
    </div>
    <ud-user-upload-result *ngIf="uploadResult" [csvUpload]="true" [uploadResult]="uploadResult"
        (resolved)="back(true)" />
</uf-panel>