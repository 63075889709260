import { Injectable, OnDestroy, inject } from '@angular/core';
import { UserProvisioningCache } from '@unifii/user-provisioning';
import { Subscription } from 'rxjs';

import { DiscoverContext } from 'discover/discover-context';
import { TitleConfig } from 'shell/core/title-config';
import { InitStep } from 'shell/init-step';
import { Authentication } from 'shell/services/authentication';
import { EditedDataService } from 'shell/services/edited-data.service';
import { AppTitle } from 'shell/shell-constants';

/**
 * Centralized place for running the logout process
 */
@Injectable()
export class LogoutInitStep implements InitStep, OnDestroy {

    private auth = inject(Authentication);
    private context = inject(DiscoverContext);
    private userProvisioningCache = inject(UserProvisioningCache);
    private editedDataService = inject(EditedDataService);
    private titleConfig = inject(TitleConfig);
    private logoutsSubscription?: Subscription;

    ngOnDestroy() {
        this.logoutsSubscription?.unsubscribe();
    }

    run() {
        this.logoutsSubscription?.unsubscribe();
        this.logoutsSubscription = this.auth.logouts.subscribe(() => {
            this.editedDataService.clearEdited();
            this.context.clear();
            this.auth.clear();
            this.userProvisioningCache.clear();
            this.titleConfig.appTitle = AppTitle; // TODO: Add to environment.json
        });
    }

}
