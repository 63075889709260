import { Component, Inject, OnInit } from '@angular/core';
import { ContextProvider, RuntimeDefinition, RuntimeField } from '@unifii/library/common';
import { Compound, FieldType, ImageProfile, PermissionAction, PublishedContent } from '@unifii/sdk';

import { Config } from 'config';
import { CollectionContent } from 'shell/content/content-types';
import { Authentication } from 'shell/services/authentication';
import { BreadcrumbsService } from 'shell/services/breadcrumbs.service';
import { PermissionsFunctions } from 'shell/services/permissions-functions';

interface Card {
    id: string;
    label: string;
    accessible: boolean;
    url?: string;
}

@Component({
    selector: 'us-collection',
    templateUrl: './collection.html',
    providers: [BreadcrumbsService],
})
export class CollectionComponent implements OnInit, CollectionContent {

    definition: RuntimeDefinition;
    compounds: Compound[];
    label: string;
    cards: Card[] = [];
    title: string;

    constructor(
        private breadcrumbsService: BreadcrumbsService,
        @Inject(PublishedContent) private content: PublishedContent,
        @Inject(Authentication) private auth: Authentication,
        @Inject(Config) private config: Config,
        @Inject(ContextProvider) private contextProvider: ContextProvider,
    ) { }

    ngOnInit() {
        this.label = this.definition.label;
        this.breadcrumbsService.title = this.label;
        this.cards = this.createCardList(this.definition, this.compounds);
    }

    getImageUrl(imageProfiles: ImageProfile[]): string | undefined {

        if (!imageProfiles?.length) {
            return;
        }

        const firstImage = imageProfiles[0] as ImageProfile;
        const width = 336; // Size base off the the largest col1of3 found in a fixed grid
        // const height = 212;

        // TODO @Adam - How to apply the imgHeight? // '&h=' + this.imgHeight + crop
        return this.content.buildImageUrl(firstImage, { width /* , height */ });
    }

    private createCardList(definition: RuntimeDefinition, compounds: Compound[] = []): Card[] {

        const imageIdentifier = this.findIdentifier(definition.fields, FieldType.ImageList) ?? '';
        const textIdentifier = this.findIdentifier(definition.fields, FieldType.Text) ?? '';

        return compounds.map((compound) => {

            const id = compound.id ?? '';
            const url = this.getImageUrl(compound[imageIdentifier]);
            const label = compound[textIdentifier] ?? id;
            const accessible = this.auth.getGrantedInfo(
                PermissionsFunctions.getCollectionItemPath(this.config.unifii.projectId, definition.identifier, compound.id as string),
                PermissionAction.Read,
                compound,
                this.contextProvider.get(),
            ).granted;

            return { id, url, label, accessible };
        });
    }

    private findIdentifier(fields: RuntimeField[], type: FieldType): string | undefined {
        return fields.find((f) => f.type === type)?.identifier;
    }

}
