import { Component, Input } from '@angular/core';
import { DataDisplayComponentType } from '@unifii/library/common';

import { DataDisplayPdfViewerValue, isDataDisplayPdfViewerValue } from './data-display-model';

@Component({
	selector: 'us-data-display-pdf-viewer',
	templateUrl: './data-display-pdf-viewer.html',
	styleUrls: ['./data-display.less'],
})
export class DataDisplayPdfViewerComponent implements DataDisplayComponentType {

	@Input({ required: true }) data: DataDisplayPdfViewerValue;

	static isDataAccepted = isDataDisplayPdfViewerValue;

}
