import { Component, HostBinding, Inject, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Modal, ModalData, ModalRuntime, SharedTermsTranslationKey } from '@unifii/library/common';
import { Progress, UfRequestError } from '@unifii/sdk';
import { Observable, Subscription } from 'rxjs';

import { DiscoverTranslationKey } from 'discover/discover.tk';
import { ErrorService } from 'shell/errors/error.service';

export interface UpdateProgressData {
    progress: Observable<Progress>;
}

@Component({
    selector: 'ud-update-progress',
    templateUrl: './update-progress.html',
    styleUrls: ['./update-progress.less'],
})
export class UpdateProgressComponent implements Modal<UpdateProgressData, boolean>, OnDestroy {

    readonly sharedTermsTK = SharedTermsTranslationKey;
    readonly discoverTK = DiscoverTranslationKey;

    @HostBinding('class.ud-form-card') get isError() { return this.error != null; }

    version: string | null;
    progress = 0;
    error: UfRequestError | undefined;
    completed: boolean;

    private subscriptions = new Subscription();

    constructor(
        public runtime: ModalRuntime<UpdateProgressData, boolean>,
        @Inject(ModalData) public data: UpdateProgressData,
        private errorService: ErrorService,
        private translate: TranslateService,
    ) {
        this.update();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    update() {

        this.subscriptions.unsubscribe();
        this.subscriptions = new Subscription();

        this.subscriptions.add(this.data.progress.subscribe(
            (progress) => {
                this.progress = progress.done / progress.total;
                this.version = progress.id ?? null;
            },
            (error) => {
                this.error = this.errorService.createError(
                    this.translate.instant(DiscoverTranslationKey.UpdateProgressErrorUpdateFail),
                    error,
                );
            },
            () => { this.close(); }));
    }

    close() {
        this.runtime.close(this.error == null);
    }

}
