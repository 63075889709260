<uf-progress class="large" />
<div class="uf-app-bar">
    <button *ngIf="data.guard !== true" (click)="close()" type="button" class="uf-action tertiary">
        <uf-icon name="close" />
    </button>
    <h3>{{shellTK.ChangeLanguageTitle | translate}}</h3>
</div>

<div class="uf-grid pad">
    <ul class="uf-list col-12">
        <ng-template [ngForOf]="languages" let-language ngFor>
            <li (click)="data.current = language.identifier" class="uf-list-button language">
                <img src="assets/svg/language-{{language.identifier}}.svg">
                <div class="multi-text">
                    {{ language.name }}
                </div>
                <uf-icon *ngIf="language.identifier === data.current" name="tick" class="small" />
            </li>
        </ng-template>
    </ul>

    <div class="col-12 error">
        <span *ngIf="error">{{error | translate}}</span>
    </div>
</div>

<div class="uf-form-actions">
    <button *ngIf="data.guard !== true && !loading" (click)="close()" type="button"
        class="uf-button tertiary small left">
        {{sharedTermsTK.ActionCancel | translate}}
    </button>
    <button [disabled]="loading" (click)="confirm()" type="button" class="uf-button primary small">
        {{sharedTermsTK.ActionChange | translate}}
    </button>
</div>