import { UfControl, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';

/**
 * User INVITE, ADD and DETAILS pages contains a Claims section
 * this section is visible when 1+ claims are editable or valorized
 * */
// TODO Move to Provisioning
export const getClaimsSectionVisibility = (claimsControl: UfControlGroup | undefined) => {
    if (!claimsControl) {
        return false;
    }

    for (const key of Object.keys(claimsControl.controls)) {
        const claimControl = claimsControl.get(key) as UfControl;

        if (claimControl.enabled || !ValidatorFunctions.isEmpty(claimControl.getRawValue())) {
            return true;
        }
    }

    return false;
};
