import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';

export const pinShow = trigger('show', [
    transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms', keyframes([
            style({ opacity: 0 }),
            style({ opacity: 0 }),
            style({ opacity: 1 }),
            style({ opacity: 1 }),
            style({ opacity: 1 }),
        ])),
    ]),
]);

export const pinHide = trigger('hide', [
    transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms', keyframes([
            style({ opacity: 1 }),
            style({ opacity: 1 }),
            style({ opacity: 0 }),
            style({ opacity: 0 }),
            style({ opacity: 0 }),
        ])),
    ]),
]);

export const pinShake = trigger('shake', [

    state('yes', style({
        transform: 'scale3d(1, 1, 1)',
    })),
    state('no', style({
        transform: 'scale3d(1, 1, 1)',
    })),
    transition('no => yes', [
        animate('500ms', keyframes([
            style({ transform: 'scale3d(1, 1, 1)' }), // 0
            style({ transform: 'scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg)' }), // 10
            style({ transform: 'scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg)' }), // 20
            style({ transform: 'scale3d(1.2, 1.2, 1.2) rotate3d(0, 0, 1, 3deg)' }), // 30
            style({ transform: 'scale3d(1.2, 1.2, 1.2) rotate3d(0, 0, 1, -3deg)' }), // 40
            style({ transform: 'scale3d(1.2, 1.2, 1.2) rotate3d(0, 0, 1, 3deg)' }), // 50
            style({ transform: 'scale3d(1.2, 1.2, 1.2) rotate3d(0, 0, 1, -3deg)' }), // 60
            style({ transform: 'scale3d(1.2, 1.2, 1.2) rotate3d(0, 0, 1, 3deg)' }), // 70
            style({ transform: 'scale3d(1.2, 1.2, 1.2) rotate3d(0, 0, 1, -3deg)' }), // 80
            style({ transform: 'scale3d(1.2, 1.2, 1.2) rotate3d(0, 0, 1, 3deg)' }), // 90
            style({ transform: 'scale3d(1, 1, 1)' }), // 100
        ])),
    ]),
]);
