import { Injectable, Type } from '@angular/core';
import { FormComponentRegistryOptions, FormField } from '@unifii/library/smart-forms';
import { UfFormComponentRegistry } from '@unifii/library/smart-forms/input';
import { FieldType } from '@unifii/sdk';

@Injectable()
export class UdFormComponentRegistry extends UfFormComponentRegistry {

    override get(type: FieldType, options?: FormComponentRegistryOptions): Type<FormField> {
        return super.get(type, options);
    }

}
