import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { Dictionary } from '@unifii/sdk';

import { Config } from 'config';
import { TenantSelectionPath, UserAccessRootPath } from 'discover/discover-constants';
import { TenantSettingsService } from 'shell/services/tenant-settings.service';

/**
 * Ensure tenant settings are present for the following routes
 *  - Main "/"
 *  - Login "/login"
 *  - Projects "/login/projects"
 *  - Request password change "/login/..."
 */
export const tenantGuard: CanActivateFn = async(route: ActivatedRouteSnapshot) => {

    const router = inject(Router);
    const config = inject(Config);
    const tenantSettings = inject(TenantSettingsService);

    // catch iss param
    const { iss, tenant } = route.queryParams;

    // successfully synced tenant settings
    try {
        if (iss && tenant) {
            await tenantSettings.setTenant(tenant);
        } else {
            await tenantSettings.sync();
        }

        return true;
    } catch (err) {
        // Not enough tenant info provided
        redirect(route.params, router, config);

        return false;
    }
};

const redirect = (params: Dictionary<string>, router: Router, config: Config) => {
    config.unifii.tenant = undefined;
    config.unifii.tenantSettings = undefined;

    void router.navigate(['/', UserAccessRootPath, TenantSelectionPath, params]);
};
