import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { ModalService } from '@unifii/library/common';

import { Config } from 'config';
import { UpdateProgressComponent } from 'discover/offline/update-progress.component';
import { OfflineManager } from 'shell/offline/offline-manager';
import { Authentication } from 'shell/services/authentication';

/**
 * responsible for ensuring that the latest version of offline content is downloaded prior to entering the app
 * this runs on project select, refresh, and app reload.
 */
export const offlineContentGuard: CanActivateFn = async() => {

    const config = inject(Config);
    const modalService = inject(ModalService);
    const offlineManager = inject(OfflineManager);
    const authentication = inject(Authentication);

    if (!config.unifii.offline) {
        return true;
    }

    try {
        await offlineManager.openContentDB();
    } catch (error) {
        console.info('OfflineGuard: no content available, update needed', error);

        return updateContent(modalService, offlineManager, authentication);
    }

    if (await offlineManager.updateAvailable()) {
        return updateContent(modalService, offlineManager, authentication);
    }

    return true;
};

const updateContent = async(modalService: ModalService, offlineManager: OfflineManager, authentication: Authentication): Promise<boolean> => {

    const updated = await modalService.openFit(
        UpdateProgressComponent,
        { progress: offlineManager.updateContent() },
        { guard: true },
    );

    if (!updated) {
        // If no existing database we have no choice but to logout
        return authentication.logout();
    }

    return true;

};
