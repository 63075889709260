import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TableContainerManager } from '@unifii/components';
import { CommonTranslationKey, FilterEntry, FilterValue, ModalService, SharedTermsTranslationKey, ToastService, UfControl, UfControlArray, UfControlGroup } from '@unifii/library/common';
import { FieldWidth, PermissionAction, UserCreateResult, UserInfo, UserInvite, UsersClient } from '@unifii/sdk';
import { ClaimWithValues, UserControlService, UserFieldLabelService, UserFormContext, UserKeys, UserProvisioningTranslationKey, UsersAddUserEntry, UsersCreateInputFormControl, UsersCreateUserEntryFormControl, UsersInputKeys, UsersInviteInputFormControl, UsersInviteUserEntry, UsersInviteUserEntryFormControl } from '@unifii/user-provisioning';
import { Subscription } from 'rxjs';

import { DiscoverTranslationKey } from 'discover/discover.tk';
import { editedData } from 'shell/decorator/edited-data.decorator';
import { DialogsService } from 'shell/services/dialogs.service';

import { UserAddComponent, UserAddModalData } from './user-add.component';
import { getClaimsSectionVisibility } from './user-management-functions';

@Component({
    selector: 'ud-user-input',
    templateUrl: './user-input.html',
    styleUrls: ['./user-create.less', './user-management.less'],
})

export class UserInputComponent implements OnInit, OnDestroy {

    @editedData protected edited: boolean;

    protected readonly commonTK = CommonTranslationKey;
    protected readonly discoverTK = DiscoverTranslationKey;
    protected readonly sharedTermsTK = SharedTermsTranslationKey;
    protected readonly userProvisioningTK = UserProvisioningTranslationKey;
    protected readonly fieldWidth = FieldWidth;
    protected readonly controlKeys = UsersInputKeys;
    protected readonly userInfoKeys = UserKeys;

    protected busy: boolean;
    protected uploadResult: any;
    protected abortController: AbortController = new AbortController();
    protected signal = this.abortController.signal;
    protected form: UfControlGroup | undefined;
    protected labelDictionary = inject(UserFieldLabelService).labelDictionary;
    protected allowedRoles: string[] | undefined;
    protected showClaimsSection: boolean;
    protected saveButtonLabel: string;
    protected usersControl?: UfControlArray;
    protected unitsControl?: UfControl;
    protected claimsControl?: UfControlGroup;
    protected rolesControl?: UfControl;
    protected companyControl?: UfControl;
    protected changePasswordOnNextLoginControl?: UfControl;
    protected allowedCompanies: string[] | undefined;
    protected allowedClaimsValues: ClaimWithValues[];

    private router = inject(Router);
    private route = inject(ActivatedRoute);
    private usersClient = inject(UsersClient);
    private modalService = inject(ModalService);
    private toastService = inject(ToastService);
    private translate = inject(TranslateService);
    private context = inject(UserFormContext);
    private userControlService = inject(UserControlService);
    private dialogsService = inject(DialogsService);
    private tableManager = inject<TableContainerManager<UserInfo, FilterValue, FilterEntry>>(TableContainerManager, { optional: true });

    private readonly formController = this.context.action === PermissionAction.Add ?
        inject(UsersCreateInputFormControl) :
        inject(UsersInviteInputFormControl);

    private readonly userController = this.context.action === PermissionAction.Add ?
        inject(UsersCreateUserEntryFormControl) :
        inject(UsersInviteUserEntryFormControl);

    private subscriptions = new Subscription();

    async ngOnInit() {
        const userInfoDefaults = await this.userControlService.getUserDefaultValueByConditions();

        this.form = this.context.action === PermissionAction.Add ?
            (this.formController as UsersCreateInputFormControl).buildRoot(Object.assign(userInfoDefaults, { isActive: true })) :
            (this.formController as UsersInviteInputFormControl).buildRoot(userInfoDefaults);

        this.usersControl = this.form.get(UsersInputKeys.Users) as UfControlArray | undefined;
        this.changePasswordOnNextLoginControl = this.form.get(UserKeys.ChangePasswordOnNextLogin) as UfControl | undefined;
        this.companyControl = this.form.get(UserKeys.Company) as UfControl | undefined;
        this.rolesControl = this.form.get(UserKeys.Roles) as UfControl | undefined;
        this.unitsControl = this.form.get(UserKeys.Units) as UfControl | undefined;
        this.claimsControl = this.form.get(UserKeys.Claims) as UfControlGroup | undefined;

        this.allowedRoles = this.userControlService.getEditableRoles(undefined, undefined);
        this.allowedCompanies = this.userControlService.getEditableCompanies(undefined);
        this.allowedClaimsValues = this.userControlService.getEditableClaimsValues(undefined);
        this.showClaimsSection = getClaimsSectionVisibility(this.claimsControl);

        this.subscriptions.add(this.form.valueChanges.subscribe(() => { this.edited = true; }));

        this.saveButtonLabel = this.context.action === PermissionAction.Add ?
            this.saveButtonLabel = this.translate.instant(SharedTermsTranslationKey.ActionCreate) :
            this.translate.instant(SharedTermsTranslationKey.ActionInvite);
    }

    ngOnDestroy() {
        this.abortController.abort();
        this.subscriptions.unsubscribe();
    }

    protected async save() {
        if (this.busy || !this.form) {
            return;
        }

        this.form.setSubmitted();
        if (this.form.invalid) {
            return;
        }

        const users = this.formController.toDataModel(this.form);

        let responseData: UserCreateResult = {
            successCount: 0,
            errors: [],
        };

        try {
            this.busy = true;
            if (this.context.action === PermissionAction.Add) {
                (users as UserInfo[]).forEach((user) => { user.changePasswordOnNextLogin = this.changePasswordOnNextLoginControl?.value ?? false; });
                responseData = await this.usersClient.bulkAdd(users as UserInfo[], { signal: this.signal });
            } else if (this.context.action === PermissionAction.Invite) {
                responseData = await this.usersClient.bulkInvite(users as UserInvite[], { signal: this.signal });
            }

            if (!responseData.errors.length) {
                this.edited = false;
                if (this.context.action === PermissionAction.Add) {
                    this.toastService.success(this.translate.instant(DiscoverTranslationKey.UsersCreateSuccessToast, { count: responseData.successCount }));
                } else {
                    this.toastService.success(this.translate.instant(DiscoverTranslationKey.UsersInviteSuccessToast, { count: responseData.successCount }));
                }

                this.tableManager?.reload?.next();
                this.back();
            } else {
                this.uploadResult = responseData;

                if (this.context.action === PermissionAction.Add) {
                    this.toastService.warning(this.translate.instant(DiscoverTranslationKey.UsersCreateFailedToast, { count: responseData.successCount, total: users.length }));
                } else {
                    this.toastService.warning(this.translate.instant(DiscoverTranslationKey.UsersInviteFailedToast, { count: responseData.successCount, total: users.length }));
                }
            }
        } catch (e) {
            console.error(e);
            this.toastService.error(this.translate.instant(SharedTermsTranslationKey.ErrorGenericMessage));
        } finally {
            this.busy = false;
        }
    }

    protected back(canLeave?: boolean) {
        this.edited = canLeave ? false : this.edited;
        void this.router.navigate(['../../'], { relativeTo: this.route });
    }

    protected async addUser() {
        if (!this.usersControl) {
            return;
        }

        const addModalInfo: UserAddModalData = {
            control: this.context.action === PermissionAction.Add ?
                (this.userController as UsersCreateUserEntryFormControl).buildRoot(undefined) :
                (this.userController as UsersInviteUserEntryFormControl).buildRoot(undefined),
        };

        const userControl = await this.modalService.openMedium(UserAddComponent, addModalInfo, { guard: true });

        if (userControl) {
            this.usersControl.push(userControl);
        }
        this.usersControl.updateValueAndValidity();
    }

    protected async editUser(index: number) {
        if (!this.usersControl) {
            return;
        }

        const control = this.usersControl.controls[index] as UfControlGroup;
        const savedData = this.userController.toDataModel(control);
        const modalControl = this.context.action === PermissionAction.Add ?
            (this.userController as UsersCreateUserEntryFormControl).buildRoot(savedData as UsersAddUserEntry) :
            (this.userController as UsersInviteUserEntryFormControl).buildRoot(savedData as UsersInviteUserEntry);

        // to shows errors and avoid email to generate username
        modalControl.setSubmitted();

        const addModalInfo: UserAddModalData = {
            control: modalControl,
            isEdit: true,
        };

        const userControl = await this.modalService.openMedium(UserAddComponent, addModalInfo, { guard: true });

        if (!userControl) {
            return;
        }

        this.usersControl.setControl(index, userControl);
        this.usersControl.updateValueAndValidity();
    }

    protected async removeUser(i: number) {
        if (!this.usersControl || !await this.dialogsService.confirmDelete()) {
            return;
        }
        this.usersControl.removeAt(i);
        this.usersControl.updateValueAndValidity();
    }

}
