import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

import { IFrameComponent } from 'shell/content/iframe.component';

import { ContentNodeComponent } from './content/content-node.component';
import { PdfViewerNodeComponent } from './content/pdf-viewer-node.component';
import { NodeComponent } from './nav/node.component';
import { NewItemPath, UserProfilePath } from './shell-constants';
import { TableDetailComponent } from './table-detail/table-detail.component';

@Injectable()
export class ShellRouteReuseStrategy extends RouteReuseStrategy {

	shouldDetach(): boolean {
		return false;
	}

	store() {
		/** */
	}

	shouldAttach(): boolean {
		return false;
	}

	retrieve(): DetachedRouteHandle | null {
		return null;
	}

	// eslint-disable-next-line complexity
	shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
		switch (current.component) {
			case PdfViewerNodeComponent:
			case IFrameComponent:
				return false;

			case TableDetailComponent:
				if (future.params.id && current.params.id) {
					return future.params.id === current.params.id;
				}

				return false;

			case ContentNodeComponent:
				/**
				 * Can reuse if identifiers match, unless they are direct links
				 * such as page and form links
				*/
				if (
					future.params.identifier &&
					current.params.identifier &&
					future.parent?.params.nodeId === current.parent?.params.nodeId &&
					future.params.identifier === current.params.identifier
				) {
					return true;
				}

				// reuse table detail page
				if (future.params.mode &&
					current.params.mode &&
					future.params.id &&
					current.params.id &&
					future.params.id === current.params.id
				) {
					return true;
				}

				// reuse userProfile for the same user (params.identifier)
				if (future.routeConfig?.path &&
					current.routeConfig?.path &&
					future.routeConfig.path === UserProfilePath &&
					current.routeConfig.path === UserProfilePath &&
					future.parent?.params.identifier === current.parent?.params.identifier
				) {
					return true;
				}

				// reuse new form for the same bucket (params.identifier) and same form definition (no $definition or matching $definition)
				if (future.routeConfig?.path &&
					current.routeConfig?.path &&
					future.routeConfig.path === NewItemPath &&
					current.routeConfig.path === NewItemPath &&
					future.parent?.params.identifier === current.parent?.params.identifier &&
					future.params.$definition === current.params.$definition
				) {
					return true;
				}

				return false;
			case NodeComponent:
				if (future.params.nodeId) {
					return future.params.nodeId === current.params.nodeId;
				}

				return future.routeConfig === current.routeConfig;
			default:
				return future.routeConfig === current.routeConfig;

		}
	}

}
