import { Inject, Injectable, Optional, SkipSelf, forwardRef } from '@angular/core';
import { Breadcrumb } from '@unifii/library/common';

@Injectable()
export class BreadcrumbsService {

    private _title: string;

    constructor(@SkipSelf() @Optional() @Inject(forwardRef(() => BreadcrumbsService)) public parent?: BreadcrumbsService) { }

    get parentBreadcrumbs(): Breadcrumb[] {
        return [...this.parent?.parentBreadcrumbs ?? [], { name: this._title, urlSegments: ['..'] }];
    }

    getBreadcrumbs(): Breadcrumb[] {
        return [...this.parent?.parentBreadcrumbs ?? [], { name: this._title }];
    }

    set title(title: string) {
        this._title = title;
    }

}
