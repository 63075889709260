import { InjectionToken } from '@angular/core';
import { Progress } from '@unifii/sdk';
import { Observable } from 'rxjs';

import { IndexedDbWrapper } from 'shell/offline/indexeddb-wrapper';
import { ContentPackage } from 'shell/offline/offline-model';

export interface PostUpdateHook {
    run: (nextDb: IndexedDbWrapper, data?: ContentPackage) => Observable<Progress>;
}

export const PostUpdateHook = new InjectionToken<PostUpdateHook>('PostUpdateHook');
