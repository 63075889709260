import { Location } from '@angular/common';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { WindowWrapper } from '@unifii/library/common';
import { hasLengthExact } from '@unifii/sdk';

import { DeviceService } from 'capacitor/device.service';
import { LegacyRouteAdapter } from 'shell/services/legacy-route-adapter';
import { UserAccessManager } from 'shell/services/user-access-manager';

import { Authentication } from '../shell/services/authentication';

import { UserAccessRootPath } from './discover-constants';
import { DiscoverTranslationKey } from './discover.tk';
import { ExternalService } from './external.service';

/**
 * Responsible for decoding route from route params
 * and redirecting to intended route or redirecting to
 * user access route if needed
 */
export const externalRouteGuard: CanActivateFn = async(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {

    const router = inject(Router);
    const accessManager = inject(UserAccessManager);
    const externalService = inject(ExternalService);
    const translate = inject(TranslateService);
    const device = inject(DeviceService);
    const legacyRouteAdapter = inject(LegacyRouteAdapter);
    const auth = inject(Authentication);
    const window = inject(WindowWrapper) as Window;
    const { tenant, projectId } = route.params;
    const location = inject(Location);
    const deniedRedirectURL = ['/', UserAccessRootPath, { tenant, projectId }];

    try {

        if (!auth.isAuthenticated) {

            void accessManager.deny({
                deniedRedirectURL,
                grantedRedirectURL: state.url,
            });

            return false;
        }

        if (!(await externalService.validateTenant(tenant))) {
            void accessManager.deny({
                deniedRedirectURL,
                grantedRedirectURL: state.url,
                message: translate.instant(DiscoverTranslationKey.LoginUseCompanyMessage, { company: tenant }),
            });

            return false;
        }

        if (!(await externalService.validateProject(projectId))) {
            externalService.selectProject(projectId);

            if (device.isCapacitorIOS() || device.isCapacitorAndroid()) {
                window.location.hash = state.url;
                window.location.reload();
                // stops the guard continuing the route while reloading the page.
                throw new Error('Project Changed');
            }
        }

        const legacyUrl = '/' + swapQueryForMatrix(state.url);
        const transformed = legacyRouteAdapter.transform(legacyUrl);

        // Since we're coming from a external route, we're replacing the state to be the home of the application
        location.replaceState('/');

        void router.navigateByUrl(transformed.url);

    } catch (err) {
        void router.navigate(['/']);

        return false;
    }

    return false;
};

const swapQueryForMatrix = (url: string) => {
    let redirectPath = url.split('/').filter(Boolean).splice(3).join('/');
    const parts = redirectPath.split('?');

    if (hasLengthExact(parts, 2)) {
        const path = parts[0];
        const params = parts[1];

        redirectPath = `${path};${params.split('&').join(';')}`;
    }

    return redirectPath;
};
