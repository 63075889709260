import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonTranslationKey, ModalService, Repository } from '@unifii/library/common';
import { AuthProvider, UserInfo } from '@unifii/sdk';
import { compareAsc, isAfter, set, subDays } from 'date-fns';

import { DeviceService } from 'capacitor/device.service';
import { Config } from 'config';
import { DiscoverTranslationKey } from 'discover/discover.tk';

import { SSOService } from './sso.service';

const SavedUsersKey = 'UfSavedUsers';

export interface SavedUser {
    username: string;
    email?: string;
    lastLogin: string;
    providerId?: string;
    authProvider?: AuthProvider; // deprecated use provider
    tenant: string;
    projectId?: string;
}

@Injectable({ providedIn: 'root' })
export class SavedUsersService {

    private config = inject(Config);
    private repo = inject(Repository);
    private ssoService = inject(SSOService);
    private modalService = inject(ModalService);
    private translate = inject(TranslateService);
    private device = inject(DeviceService);
    private _cachedUsers: SavedUser[]; // provide cache layer when accessing user list

    /** checks if remember me is enabled */
    get enabled(): boolean {
        return this.config.unifii.tenantSettings?.rememberMe === true;
    }

    get users(): SavedUser[] {

        this.updateLocalStorage();

        return this._users
            .filter((user) => user.tenant === this.config.unifii.tenant)
            .sort((a, b) => compareAsc(new Date(b.lastLogin), new Date(a.lastLogin)),
            );
    }

    remove(username: string) {

        const userIndex = this._users.findIndex((user) => user.username === username && user.tenant === this.config.unifii.tenant);

        if (userIndex !== -1) {
            this._users.splice(userIndex, 1);
        }
        this.repo.store(SavedUsersKey, this._users);
    }

    userProject(userInfo: UserInfo | null): string | null {

        if (!this.enabled || !userInfo) {
            return null;
        }

        const user = this._users.find((u) => u.username === userInfo.username && u.tenant === this.config.unifii.tenant);

        if (!user) {
            return null;
        }

        return user.projectId ?? null;
    }

    updateProject(userInfo: UserInfo | null, projectId: string) {

        if (!this.enabled || !userInfo) {
            console.log('no enabled, or info');

            return;
        }

        const user = this._users.find((u) => u.username === userInfo.username && u.tenant === this.config.unifii.tenant);

        if (!user) {
            return;
        }

        user.projectId = projectId;
        this.repo.store(SavedUsersKey, this._users);
    }

    async rememberUser(userInfo: UserInfo, rememberMe?: boolean, providerId?: string): Promise<void> {

        if (!this.enabled || !userInfo) {
            return;
        }

        // unifii login
        if (rememberMe && !providerId) {
            this.add(userInfo.username, userInfo.email);

            return;
        }

        // auth provider login
        if (providerId) {

            const provider = this.ssoService.getProvider(providerId);

            // if device is mobile and provider doesn't support mobile
            if (this.device.isNative() && !provider?.features?.rememberUserMobile) {
                return;
            }

            // if device is desktop and provider doesn't support desktop
            if (!this.device.isNative() && !provider?.features?.rememberUserDesktop) {
                return;
            }

            // if user is remembered, update and don't ask
            if (this.isUserSaved(userInfo.username, providerId)) {
                this.add(userInfo.username, userInfo.email, providerId);

                return;
            }

            const proceed = await this.modalService.openConfirm({
                title: this.translate.instant(DiscoverTranslationKey.RemeberUserTitle),
                message: this.translate.instant(DiscoverTranslationKey.RemeberUserMessage),
                confirmLabel: this.translate.instant(CommonTranslationKey.YesLabel),
                cancelLabel: this.translate.instant(CommonTranslationKey.NoLabel),
            });

            if (proceed) {
                this.add(userInfo.username, userInfo.email, providerId);
            }

            return;
        }

        // if unifii login doesn't select remember me, remove them
        this.remove(userInfo.username);
    }

    private add(username: string, email?: string, providerId?: string) {

        if (!this.config.unifii.tenant) {
            return;
        }

        const user: SavedUser = {
            username,
            email,
            lastLogin: new Date().toISOString(),
            providerId,
            tenant: this.config.unifii.tenant,
        };

        // if already exists update, otherwise add
        const position = this._users.findIndex((u) => u.username === user.username && u.tenant === this.config.unifii.tenant);
        const existingUser = this._users[position];
        
        if (existingUser) {
            Object.assign(existingUser, user);
        } else {
            this._users.push(user);
        }
        this.repo.store(SavedUsersKey, this._users);
    }

    private isUserSaved(username: string, providerId?: string): boolean {

        return this._users.filter((user) =>
            user.username === username &&
            user.tenant === this.config.unifii.tenant &&
            (user.providerId != null && user.providerId === providerId),
        ).length > 0;
    }

    private get historyDays(): number {
        return this.config.unifii.tenantSettings?.rememberMeExpiryDays ?? 30;
    }

    private updateLocalStorage() {

        // remove users older than number of days
        // set time to 00:00:01 so that expiry is the day after
        const today = set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
        const expiryDate = subDays(today, this.historyDays);

        this._users = this._users
            .map((user) => this.updateProviderDetails(user))
            .filter((user) => user != null && this.isValidExpiry(user, expiryDate)) as SavedUser[];
    }

    private updateProviderDetails(user: SavedUser): SavedUser | undefined {

        // remove deprecated authProvider fields
        if (user.authProvider || user.providerId) {
            const match = this.ssoService.providers.find((p) => '' + p.id === user.providerId || p.type === user.authProvider);

            if (!match) {
                return;
            } else if (user.authProvider) {
                // can be removed after 30 days from 1.29 release
                delete user.authProvider;
                user.providerId = '' + match.id;
            }
        }

        return user;
    }

    private isValidExpiry(user: SavedUser, expiryDate: Date): boolean {

        const userDate = set(new Date(user.lastLogin), { hours: 0, minutes: 0, seconds: 1, milliseconds: 0 });

        return isAfter(userDate, expiryDate);
    }

    private set _users(v: SavedUser[]) {
        this._cachedUsers = v;
        this.repo.store(SavedUsersKey, v);
    }

    private get _users(): SavedUser[] {

        if (!this._cachedUsers) {
            this._cachedUsers = this.repo.load(SavedUsersKey) ?? [];
        }

        return this._cachedUsers;
    }

}
