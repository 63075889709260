<us-shell>
  <us-header #header [menuDrawer]="menu" [rightDrawer]="right" [title]="shell.title" />
  <us-menu-drawer #menu>
    <us-nav [img]="imgUrl" />
  </us-menu-drawer>
  <us-drawer #right (closed)="shell.closeRightDrawer()" icon="settings">
    <ud-settings />
  </us-drawer>
  <router-outlet />
</us-shell>
<ud-icons />
<uf-progress class="large" />