import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonTranslationKey, SharedTermsTranslationKey, UfControl, ValidatorFunctions } from '@unifii/library/common';
import { UsersClient } from '@unifii/sdk';

import { DiscoverTranslationKey } from 'discover/discover.tk';
import { ErrorService } from 'shell/errors/error.service';
import { UserAccessManager } from 'shell/services/user-access-manager';

/** Component used to submit the request to change the user password, lead to an email with link sent to the user's email */
@Component({
    selector: 'ud-request-password-change',
    templateUrl: 'request-password-change.html',
})
export class RequestPasswordChangeComponent implements OnInit, OnDestroy {

    protected readonly commonTK = CommonTranslationKey;
    protected readonly discoverTK = DiscoverTranslationKey;

    protected successMessage: string;
    protected control: UfControl;

    private usersClient = inject(UsersClient);
    private errorService = inject(ErrorService);
    private translate = inject(TranslateService);
    private accessManager = inject(UserAccessManager);

    ngOnInit() {
        this.control = new UfControl(ValidatorFunctions.required(this.translate.instant(SharedTermsTranslationKey.ValidatorValueRequired)));
    }

    ngOnDestroy() {
        // clean errors
        this.accessManager.showError(null);
    }

    protected async resetPassword() {

        this.accessManager.showError(null);

        if (this.control.invalid) {
            this.control.setSubmitted();

            return;
        }

        const username = this.control.value.trim();

        try {
            await this.usersClient.resetPassword(username);
            this.successMessage = this.translate.instant(DiscoverTranslationKey.RequestPasswordChangeFeedbackEmailSent);
        } catch (e) {
            this.accessManager.showError(this.errorService.createError(this.discoverTK.RequestPasswordChangeErrorRequestFail, e));
        }
    }

}
