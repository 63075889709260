import { Component, ElementRef, HostBinding, HostListener, Input, OnInit, ViewChild, inject } from '@angular/core';
import { RuntimeField, WindowWrapper } from '@unifii/library/common';
import { ContentField } from '@unifii/library/smart-forms';
import { PDFDocumentProxy, getDocument } from 'pdfjs-dist';
import { EventBus, NullL10n, PDFLinkService, PDFViewer } from 'pdfjs-dist/web/pdf_viewer';

import { ShellTranslationKey } from 'shell/shell.tk';

export interface PDFAsset {
    url: string;
}

@Component({
    selector: 'us-pdf-viewer',
    templateUrl: './pdf-viewer.html',
    styleUrls: ['./pdf-viewer.less'],
})
export class PdfViewerComponent implements ContentField, OnInit {

    @Input({ required: true }) content: PDFAsset | null | undefined;
    @Input() field: RuntimeField;

    @HostBinding('class.uf-box') boxClass = true;
    @HostBinding('class.expanded') isExpanded = false;
    @ViewChild('pdfContainer', { static: true }) private pdfContainer: ElementRef<HTMLDivElement>;

    protected readonly shellTK = ShellTranslationKey;
    protected error = false;
    protected loading = false;
    protected pagesCount: number | null;

    private pdfDocument: PDFDocumentProxy | null;
    private pdfViewer: PDFViewer | null;
    private defaultScaleDelta = 1.1;
    private minScale = 0.15;
    private maxScale = 10.0;
    private defaultScaleValue = 'auto';
    private scrollableElement?: HTMLElement;
    private window = inject<Window>(WindowWrapper);

    // prevent right-click
    @HostListener('contextmenu', ['$event'])
    onRightClick(event: Event) {
        event.preventDefault();
    }

    async ngOnInit() {

        this.scrollableElement = this.getScrollableElement(this.pdfContainer.nativeElement);

        if (!this.content?.url) {
            this.error = true;

            return;
        }

        const pdfUrl = this.content.url;
        const container = this.pdfContainer.nativeElement;
        const pdfEvents = new EventBus();
        const linkService = new PDFLinkService({ eventBus: pdfEvents });

        try {
            this.loading = true;
            this.pdfDocument = await getDocument(pdfUrl).promise;
            this.pagesCount = this.pdfDocument.numPages;
            this.pdfViewer = new PDFViewer({
                container,
                eventBus: pdfEvents,
                linkService,
                l10n: NullL10n,
                useOnlyCssZoom: true,
                textLayerMode: 0,
            });

            linkService.setViewer(this.pdfViewer);
            this.pdfViewer.setDocument(this.pdfDocument);

            const safePDFViewer = this.pdfViewer;

            pdfEvents.on('pagesinit', () => {
                safePDFViewer.currentScaleValue = this.defaultScaleValue.toString();
                safePDFViewer.container.scrollTop = 0;
                this.loading = false;
                this.minScale = Math.min(safePDFViewer.currentScale, this.minScale);
            });
        } catch (error) {
            this.error = true;
            this.loading = false;

            return;
        }
    }

    protected get page() {
        return this.pdfViewer?.currentPageNumber;
    }

    protected toggleExpandedMode() {
        this.isExpanded = !this.isExpanded;

        if (!this.scrollableElement) {
            return;
        }

        // disable scroll when expanded
        if (this.isExpanded) {
            this.scrollableElement.style.overflow = 'hidden';
            this.scrollableElement.scroll({ top: 0 });
        } else {
            this.scrollableElement.style.overflow = 'auto';
        }
    }

    protected nextPage() {
        this.pdfViewer?.nextPage();
    }

    protected previousPage() {
        if (!this.pdfViewer) {
            return;
        }

        this.pdfViewer.previousPage();

        if (this.page === 1) {
            this.pdfViewer.container.scrollTop = 0;
        }
    }

    protected zoomIn() {
        if (!this.pdfViewer) {
            return;
        }

        let newScale = this.pdfViewer.currentScale;

        newScale = Number((newScale * this.defaultScaleDelta).toFixed(2));
        newScale = Math.ceil(newScale * 10) / 10;
        newScale = Math.min(this.maxScale, newScale);
        this.pdfViewer.currentScaleValue = newScale.toString();
    }

    protected zoomOut() {
        if (!this.pdfViewer) {
            return;
        }

        let newScale = this.pdfViewer.currentScale;

        newScale = Number((newScale / this.defaultScaleDelta).toFixed(2));
        newScale = Math.floor(newScale * 10) / 10;
        newScale = Math.max(this.minScale, newScale);
        this.pdfViewer.currentScaleValue = newScale.toString();
    }

    private getScrollableElement(element?: HTMLElement): HTMLElement | undefined {
        if (!element) {
            return;
        }

        const computedStyle = this.window.getComputedStyle(element);

        if (computedStyle.overflow === 'auto' || computedStyle.overflowY === 'auto') {
            return element;
        }

        return this.getScrollableElement(element.parentElement ?? undefined);
    }

}
