export enum ShellTranslationKey {
    FormBucketDialogAddFormTitle = 'form_bucket_dialog_add_form_title',
    DetailsTitle = 'details_title',
    ReportNotFound = 'report_not_found',
    ReportIncrementDaysLabel = 'report_days_label',
    ReportIncrementWeeksLabel = 'report_weeks_label',
    ReportIncrementMonthsLabel = 'report_months_label',
    ReportFilterStartDateLabel = 'report_filter_start_date_label',
    ReportFilterEndDateLabel = 'report_filter_end_date_label',
    ReportFilterDateIntervalsLabel = 'report_filter_date_intervals_label',
    ReportResultNoDataLabel = 'report_result_no_data_label',
    ContentNodeHomePageTitle = 'content_node_home_page_title',
    ContentNodeHomePageMessage = 'content_node_home_page_message',
    DashboardBucketActionSeeMore = 'dashboard_bucket_action_see_more',
    FormFeedbackQueued = 'form_feedback_queued',
    FormFeedbackSaved = 'form_feedback_saved',
    FormOriginatedBy = 'form_originated_by',
    MenuLinkHomeLabel = 'menu_link_home_label',
    LogOutModalTitle = 'log_out_modal_title',
    LogOutModalMessage = 'log_out_modal_message',
    LogOutModalActionConfirm = 'log_out_modal_action_confirm',
    ChangeLanguageTitle = 'change_language_title',
    NoLanguageAvailableTitle = 'no_language_available_title',
    NoLanguageAvailableMessage = 'no_language_available_message',
    ValidatorLanguageRequired = 'validator_language_required',
    AuthenticationPasswordChangeRequiredLabel = 'authentication_password_change_required_label',
    DownloadCompatibleBrowserMessage = 'download_compatible_browser_message',
    UnsavedChangesModalTitle = 'unsaved_changes_modal_title',
    UnsavedChangesModalMessage = 'unsaved_changes_modal_message',
    DontLeaveLabel = 'dont_leave_label',
    LeaveLabel = 'leave_label',
    DeleteDialogTitle = 'delete_dialog_title',
    DeleteDialogMessage = 'delete_dialog_message',
    DeleteLabel = 'delete_label',
    DoNotDeleteLabel = 'do_not_delete_label',
    DeleteUserModalTitle = 'delete_modal_title', // user management specific
    DeleteUserModalMessage = 'delete_modal_message', // user management specific
    DeleteUserModalSuccess = 'delete_modal_success', // user management specific
    DeleteUserModalFail = 'delete_modal_fail', // user management specific
    ConflictModalTitle = 'conflict_modal_title',
    ConflictModalMessage = 'conflict_modal_message',
    ConflictModalActionDiscard = 'conflict_modal_action_discard',
    ConflictModalActionSave = 'conflict_modal_action_save',
    ErrorContentNotFound = 'error_content_not_found',
    ErrorNotFound = 'error_not_found',
    ErrorLoad = 'error_load',
    ErrorNoInternet = 'error_no_internet',
    ErrorCheckConnectivity = 'error_check_connectivity',
    ErrorSave = 'error_save',
    ErrorAccessDenied = 'error_access_denied',
    ErrorRequestUnauthorized = 'error_request_unauthorized',
    ErrorRequestForbidden = 'error_request_forbidden',
    ErrorRequestNotFound = 'error_request_not_found',
    ErrorNoAllowedProjects = 'error_no_allowed_projects',
    ErrorInvalidClient = 'error_invalid_client',
    ErrorUnhandled = 'error_unhandled',
    NextPageLabel = 'next_page_label', // Next Page
    PreviousPageLabel = 'previous_page_label', // Previous Page
    ZoomInLabel = 'zoom_in_label', // Zoom In
    ZoomOutLabel = 'zoom_out_label', // Zoom Out
    FullscreenLabel = 'fullscreen_label', // Fullscreen
}
