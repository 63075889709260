import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Modal, ModalData, ModalRuntime, ModalService, SharedTermsTranslationKey, UfControl, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';
import { UserInfo } from '@unifii/sdk';

import { VibrateService } from 'capacitor/vibrate.service';
import { Config } from 'config';
import { DiscoverTranslationKey } from 'discover/discover.tk';
import { pinHide, pinShake, pinShow } from 'discover/pin/pin-animations';
import { PinModalData, PinService, PinState } from 'discover/pin/pin-types';
import { ErrorService } from 'shell/errors/error.service';
import { Authentication } from 'shell/services/authentication';
import { ShellTranslationKey } from 'shell/shell.tk';

@Component({
    selector: 'ud-password-check',
    templateUrl: './password-check.html',
    styleUrls: ['./pin-screen.less'],
    animations: [pinShow, pinHide, pinShake],
})

export class PasswordCheckComponent implements Modal<PinModalData, PinState> {

    readonly sharedTermsTK = SharedTermsTranslationKey;
    readonly discoverTK = DiscoverTranslationKey;

    error: Error | undefined;
    closeAllowed: boolean;
    closeParentOnLogout: boolean;
    password: string;
    passwordForm: UfControlGroup;

    constructor(
        @Inject(Config) public config: Config,
        @Inject(ModalData) public data: PinModalData,
        public runtime: ModalRuntime<PinModalData, PinState>,
        @Inject(Authentication) private auth: Authentication,
        @Inject(PinService) private pinService: PinService,
        private vibrateService: VibrateService,
        private modalService: ModalService,
        private errorService: ErrorService,
        private translate: TranslateService,
    ) {
        this.closeAllowed = this.data.closeAllowed || false;

        this.passwordForm = new UfControlGroup({
            password: new UfControl(
                ValidatorFunctions.required(
                    this.translate.instant(SharedTermsTranslationKey.ValidatorValueRequired),
                ),
            ),
        });
    }

    close() {
        if (this.closeAllowed) {
            this.runtime.close(PinState.NotVerified);
        }
    }

    async checkPassword() {

        this.error = undefined;
        this.passwordForm.setSubmitted();

        if (this.passwordForm.invalid) {
            return;
        }

        try {

            await this.auth.login({ username: (this.auth.userInfo as UserInfo).username, password: this.passwordForm.value.password });
            this.runtime.close(PinState.Verified);

        } catch (err) {

            const error = this.errorService.createError(this.errorService.unhandledErrorMessage, err);

            if ((err as any).status === 0) { // TODO what's status attribute?
                await this.modalService.openAlert({
                    title: this.translate.instant(ShellTranslationKey.ErrorNoInternet),
                    message: this.translate.instant(ShellTranslationKey.ErrorCheckConnectivity),
                });

                this.runtime.close(PinState.NotVerified);
            } else {
                this.vibrateService.vibrate(500);
                this.error = error;
            }

        }
    }

    async logout() {

        // get values before logout, because they will be gone
        const tenant = this.config.unifii.tenant as string;
        const userId = (this.auth.userInfo as UserInfo).id as string;

        const done = await this.auth.logout({ askConfirmation: true });

        if (!done) {
            return;
        }

        this.pinService.clearPin(tenant, userId);

        this.runtime.close(PinState.Logout);
    }

}
