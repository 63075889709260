<ud-user-access-box>
    <ng-container content>

        <uf-blockquote *ngIf="linkError" [content]="linkError.message" icon="errorSolid" class="error gap-top small" />

        <ng-container *ngIf="form">
            <p>{{discoverTK.CompleteRegistrationDescription | translate}}</p>

            <div class="grid">
                <form [formGroup]="form" (ngSubmit)="submit()" class="col-1of1 grid">

                    <uf-text *ngIf="emailControl" [label]="labels[controlKeys.Email]" [control]="emailControl"
                        class="col-1of1" />

                    <uf-text *ngIf="usernameControl" [label]="labels[controlKeys.Username]" [control]="usernameControl"
                        class="col-1of1">
                        <span class="suffix">*</span>
                    </uf-text>

                    <uf-text *ngIf="firstNameControl" [label]="labels[controlKeys.FirstName]"
                        [control]="firstNameControl" class="col-1of1">
                        <span class="suffix">*</span>
                    </uf-text>

                    <uf-text *ngIf="lastNameControl" [label]="labels[controlKeys.LastName]" [control]="lastNameControl"
                        class="col-1of1">
                        <span class="suffix">*</span>
                    </uf-text>

                    <uf-text *ngIf="phoneControl" [label]="labels[controlKeys.Phone]" [control]="phoneControl"
                        class="col-1of1" />

                    <uf-password-advanced *ngIf="passwordControl" [label]="labels[controlKeys.Password]"
                        [control]="passwordControl" class="col-1of1">
                        <span class="suffix">*</span>
                    </uf-password-advanced>

                    <div *ngIf="error" class="col-1of1">
                        <uf-blockquote
                            [content]="error.message || (discoverTK.CompleteRegistrationActionRegisterFail | translate)"
                            icon="errorSolid" class="error" />
                    </div>

                    <uf-password-indicator *ngIf="passwordControl" [control]="passwordControl"
                        [style.visibility]="!form.controls[controlKeys.Password]?.value ? 'hidden' : 'visible'"
                        class="col-1of1" />

                    <div class="col-1of1 col center-all">
                        <button [disabled]="busy || linkError" type="submit" class="uf-button primary">
                            {{discoverTK.CompleteRegistrationActionRegister | translate}}
                        </button>
                    </div>

                </form>
            </div>

        </ng-container>

    </ng-container>
</ud-user-access-box>