import { Component, OnDestroy, inject } from '@angular/core';
import { Router } from '@angular/router';
import { WindowWrapper } from '@unifii/library/common';
import { encrypt } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { DeviceService } from 'capacitor/device.service';
import { InAppBrowserService } from 'capacitor/in-app-browser.service';
import { Environment } from 'config';
import { AuthorizePath } from 'discover/discover-constants';
import { DiscoverTranslationKey } from 'discover/discover.tk';
import { createIdentityRedirectUri, createIdentityUrl } from 'shell/services/identity-functions';
import { UserAccessManager } from 'shell/services/user-access-manager';

@Component({
    selector: 'ud-login-identity-hold',
    templateUrl: './login-identity-hold.html',
    styleUrls: ['../../../shell/styles/external-branding.less', './login-identity-hold.less'],
})
export class LoginIdentityHoldComponent implements OnDestroy {

    protected readonly discoverTK = DiscoverTranslationKey;
    protected inProgress: boolean;
    protected authProvider = inject(Environment).authProvider;

    private router = inject(Router);
    private window = inject(WindowWrapper) as Window;
    private deviceService = inject(DeviceService);
    private userAccessManager = inject(UserAccessManager);
    private env = inject(Environment);
    private inAppBrowser = inject(InAppBrowserService);

    private redirectUri = createIdentityRedirectUri(this.window, this.deviceService.isCapacitorIOS());
    private subscriptions = new Subscription();

    // TODO drop the constructor for ngOnInit?
    constructor() {
        if (this.deviceService.isNative()) {

            this.subscriptions.add(this.inAppBrowser.loadStart.subscribe((url) => { this.inAppBrowserInterceptor(url); }));

            if (this.router.lastSuccessfulNavigation?.extras.state?.capacitorFirstLoad as boolean | undefined) {
                void this.identitySignIn();
            }
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    protected async identitySignIn() {

        this.userAccessManager.showError(null);

        this.inProgress = true;

        try {

            if (!this.authProvider) {
                throw new Error('Auth Provider is not set');
            }

            const encryptedState = await encrypt(this.env.unifii.appId as string, new URLSearchParams({ redirectUri: this.redirectUri }).toString());
            const identityUrl = createIdentityUrl(this.authProvider.authorizationEndpoint, this.env.unifii.appId as string, this.redirectUri, encryptedState.byteString);

            if (this.inAppBrowser.isAvailable) {
                this.inAppBrowser.open(identityUrl);
            } else {
                this.window.location.href = identityUrl;
            }

        } catch (error) {
            console.error('LoginIdentityHoldComponent: ', error);

        } finally {
            this.inProgress = false;
        }
    }

    private inAppBrowserInterceptor(url: string) {

        if (!url.startsWith(this.redirectUri)) {
            return;
        }

        const params = new URL(url).search;
        const urlSearchParams = new URLSearchParams(params);

        this.router.navigateByUrl(`/${AuthorizePath}?${urlSearchParams.toString()}`);
        this.inAppBrowser.close();
    }

}
