import { Component, Input } from '@angular/core';

@Component({
    selector: 'us-empty-module-message',
    templateUrl: './empty-module-message.html',
    styleUrls: ['./empty-module-message.less'],
})
export class EmptyModuleMessageComponent {

    @Input({ required: true }) message: string | undefined;

}
