import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'ud-loading-bar',
    template: `<div [ngStyle]="{ 'transform': 'scaleX(' + progress + ')' }" class="bar"></div>`,
    styleUrls: ['./loading-bar.less'],
})
export class LoadingBarComponent {

    @Input() progress = 0;

    @HostBinding('style.opacity') get opacity() {
        return this.progress >= 1 ? '0' : '1';
    }

    @HostBinding('class.active') get active() {
        return this.progress > 0;
    }

}
