<uf-panel class="container">
    <div class="header-pane">
        <button (click)="refreshBuckets()" type="button" class="uf-action">
            <uf-icon name="refreshApp" />
        </button>
    </div>

    <us-empty-module-message *ngIf="showNoEmptyMessage && pageMode === templateEnumVales.PageViewHideEmptyTables"
        [message]="emptyMessage" />

    <ng-container *ngFor="let tableInfo of tableInfos">
        <us-dashboard-table [tableInfo]="tableInfo" [pageMode]="pageMode"
            (hideChange)="updateHiddenTablesSet(tableInfo, $event)" class="content-pane" />
    </ng-container>
</uf-panel>