<form (ngSubmit)="resetPassword()" class="uf-grid">

    <ng-template [ngIf]="successMessage">
        <uf-blockquote [content]="successMessage" class="success col-12 small" icon="successSolid">
            <h3>{{ discoverTK.RequestPasswordChangeFeedbackEmailSentHeading | translate }}</h3>
        </uf-blockquote>
    </ng-template>

    <uf-text *ngIf="!successMessage" [label]="commonTK.UsernameLabel | translate" [control]="control" class="col-12"
        name="username" autocomplete="username" />


    <div class="col-12 col center-all gap-lg-top">
        <button *ngIf="!successMessage" class="uf-button primary" type="submit">
            {{discoverTK.ActionRequestPasswordChange | translate}}
        </button>
    </div>

</form>