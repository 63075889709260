import { Routes } from '@angular/router';
import { ContentType } from '@unifii/sdk';

import { externalRouteGuard } from 'discover/external-route-guard';
import { loggedInGuard } from 'discover/user-access/logged-in-guard';
import { contentRoutes, customRoutes, mainConfig, mainRoutes, rootRoutes } from 'settings';
import { ContentNodeComponent } from 'shell/content/content-node.component';
import { IFrameComponent } from 'shell/content/iframe.component';
import { PdfViewerNodeComponent } from 'shell/content/pdf-viewer-node.component';
import { DashboardPageComponent } from 'shell/dashboard/dashboard-page.component';
import { ErrorMessageComponent } from 'shell/nav/error-message.component';
import { nodeGuard } from 'shell/nav/node-guard';
import { NodeComponent } from 'shell/nav/node.component';

export const AppRoutes: Routes = [
    ...rootRoutes,
    // ExternalGuard always redirect or block navigation, never succeed to its component
    {
        path: 'e/:tenant/:projectId', canActivate: [externalRouteGuard], component: ErrorMessageComponent, children: [
            { path: '**', component: ErrorMessageComponent },
        ],
    },
    {
        path: '', component: mainConfig.component, canActivate: mainConfig.canActivate, data: mainConfig.data, canDeactivate: [loggedInGuard],
        children: [
            ...mainRoutes,
            { path: '', component: ContentNodeComponent }, // home page
            { path: 'error', component: ErrorMessageComponent },
            {
                path: 'n/:nodeId', component: NodeComponent, canActivate: [nodeGuard],
                children: [
                    { path: 'dashboard', component: DashboardPageComponent, children: [
                        { path: ':identifier', component: ContentNodeComponent, data: { contentType: ContentType.Table } },
                    ] },
                    {
                        path: 'custom', children: [
                            // for a node route with content identifier (Collection, View, Page) matching 'custom'
                            ...customRoutes,
                            { path: ':identifier', pathMatch: 'full', component: ContentNodeComponent },
                        ],
                    },
                    { path: 'iframe', component: IFrameComponent },
                    { path: 'pdf-viewer/:id', component: PdfViewerNodeComponent },
                    ...contentRoutes,
                ],
            }, // end of structure navigation
            ...contentRoutes,
        ],
    },
];
