import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageLevel, SharedTermsTranslationKey } from '@unifii/library/common';
import { ErrorType, UfRequestError, ensureUfRequestError, isValueOfNumberEnumType } from '@unifii/sdk';

import { ComponentTitleRouteData } from 'shell/shell-model';
import { ShellTranslationKey } from 'shell/shell.tk';

export interface ErrorMessageRouteData extends ComponentTitleRouteData {
    messageTranslationKey?: string;
    messageParams?: Record<string, any>;
    level?: MessageLevel;
}

@Component({
    selector: 'us-error-message',
    templateUrl: './error-message.html',
    styles: [`:host { display: block; }`],
})
export class ErrorMessageComponent {

    private _title: string | undefined;
    private _message: string | undefined;
    private _level: MessageLevel | undefined;
    private _error: UfRequestError | undefined;

    constructor(private route: ActivatedRoute, private translate: TranslateService) {
        const type = parseInt(this.route.snapshot.params.type);
        
        this.error = new UfRequestError('', isValueOfNumberEnumType(ErrorType)(type) ? type : undefined);
    }

    @Input() set title(v: string) {
        this._title = v;
    }

    get title(): string {

        if (this._title?.trim().length) {
            return this._title;
        }

        if (this.error) {
            switch (this.error.type) {
                case ErrorType.Server:
                    return this.translate.instant(SharedTermsTranslationKey.ErrorServer);
                case ErrorType.NotFound:
                default:
                    return this.translate.instant(SharedTermsTranslationKey.Error);
            }
        }

        // Route snapshot
        if (this.routeData.titleTranslationKey) {
            return this.translate.instant(this.routeData.titleTranslationKey, this.routeData.titleParams);
        }

        // Default title
        return this.translate.instant(SharedTermsTranslationKey.Error);
    }

    @Input() set message(v: string) {
        this._message = v;
    }

    get message(): string {

        if (this._message?.trim().length) {
            return this._message;
        }

        // An Error is binded
        if (this.error) {
            switch (this.error.type) {
                case ErrorType.Server:
                    return this.translate.instant(SharedTermsTranslationKey.ErrorServer);
                case ErrorType.NotFound:
                    return this.translate.instant(ShellTranslationKey.ErrorContentNotFound);
                case ErrorType.Forbidden:
                    return this.translate.instant(ShellTranslationKey.ErrorRequestForbidden);
                case ErrorType.Unauthorized:
                    return this.translate.instant(ShellTranslationKey.ErrorRequestUnauthorized);
            }
        }

        // Route snapshot
        if (this.routeData.messageTranslationKey) {
            return this.translate.instant(this.routeData.messageTranslationKey, this.routeData.messageParams);
        }

        // Default message
        return this.translate.instant(SharedTermsTranslationKey.ErrorUnknown);
    }

    @Input() set level(v: MessageLevel) {
        this._level = v;
    }

    get level(): MessageLevel {

        // A level is binded as input
        if (this._level) {
            return this._level;
        }

        // An Error is binded
        if (this.error) {
            return MessageLevel.Error;
        }

        // Route snapshot
        if (this.routeData.level) {
            return this.routeData.level;
        }

        // Default level
        return MessageLevel.Error;
    }

    get icon(): string {
        switch (this.level) {
            case MessageLevel.Info:
                return 'infoSolid';
            case MessageLevel.Success:
                return 'successSolid';
            case MessageLevel.Warning:
                return 'warningSolid';
            case MessageLevel.Error:
                return 'errorSolid';
        }
    }

    @Input() set error(v: unknown) {
        this._error = ensureUfRequestError(v);
    }

    get error(): UfRequestError | undefined {
        return this._error;
    }

    get routeData(): ErrorMessageRouteData {
        return this.route.snapshot.data || {};
    }

}
