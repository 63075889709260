import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { appear, fade, slide } from './drawer-animations';

@Component({
    selector: 'us-drawer',
    templateUrl: './drawer.html',
    styleUrls: ['./drawer.less'],
    animations: [appear, slide, fade],
})
export class DrawerComponent {

    @Input() open = false;
    @Input() icon = 'hamburger';
    @Output() openChange = new EventEmitter(); /** Default usage */
    @Output() closed = new EventEmitter(); /** Implementation for main component draw is controlled by shell service */

    @HostBinding('@appear') get state() {
        return this.open ? 'open' : 'closed';
    }

}
