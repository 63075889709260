<uf-panel *ngIf="ready" ufMasterDetail class="container">
    <!-- It shows tabs on the right (at the top for Mobile) if it's MenuView and it renders only TableDetailComponent if PageView (default) -->
    <uf-tabs *ngIf="pageMode === 'MenuView'" [responsive]="true" class="vertical">
        <uf-tab label="Details" path="" />
        <ng-template [ngForOf]="tableModules" let-tableModule ngFor>
            <uf-tab *ngIf="tableModule.detailModule.title" [label]="tableModule.detailModule.title"
                [path]="tableModule.detailModule.identifier" />
        </ng-template>
    </uf-tabs>
</uf-panel>
<router-outlet *ngIf="ready && pageMode !== 'MenuView'" (activate)="onActivate($event)" />