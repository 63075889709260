<form (ngSubmit)="verify()">

    <p><b>{{ (mfaUrl ? commonTK.MfaSetupMessage : commonTK.MfaVerifyMessage) | translate }}</b></p>

    <ng-template [ngIf]="mfaUrl">

        <p>{{commonTK.MfaOpenAuthenticationAppMessage | translate}} <button
                [data]="{markdown: commonTK.MfaAdditionalInfoMessage | translate}" contentViewerLink type="button"
                class="uf-action x-small quaternary inline">
                <uf-icon name="info" />
            </button>
        </p>

        <qrcode [qrdata]="mfaUrl" [width]="120" [margin]="0" errorCorrectionLevel="L" elementType="img"
            class="pad-bottom" />

        <p>{{commonTK.MfaManualSetupMessage | translate}} <a [href]="mfaUrl">{{ sharedTermsTK.LinkLabel |
                translate}}</a></p>


        <p>{{secret}} <button (click)="copySecret()" type="button" class="uf-action x-small quaternary inline">
                <uf-icon name="copy" />
            </button>
        </p>

    </ng-template>

    <div class="pad-sides pad-bottom">
        <p><b>{{ commonTK.MfaVerificationMessage | translate }}</b></p>

        <uf-text [control]="toptCodeControl" autocomplete="one-time-code" />

        <div class="col center-all pad-top">
            <button class="uf-button primary" type="submit">{{ commonTK.MfaVerifyButtonLabel | translate
                }}</button>
        </div>
    </div>

    <div class="col center-all">
        <button (click)="logout()" type="button" class="uf-button tertiary">
            {{sharedTermsTK.ActionLogout | translate}}
        </button>
    </div>

</form>