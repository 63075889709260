import { Component, ElementRef, HostListener, Inject, Input, OnDestroy, ViewChild } from '@angular/core';
import { WindowWrapper } from '@unifii/library/common';
import { Chart, ChartConfiguration, ChartData, ChartOptions, ChartTypeRegistry, LegendOptions, Plugin, ScaleOptions } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Options as ChartDataLabelsOptions } from 'chartjs-plugin-datalabels/types/options';
import { Subject, Subscription, debounceTime } from 'rxjs';

import { ReportColour, ReportColourCommon } from 'discover/reports/report-constants';
import { DataLabelContent, ReportAxisConfig, ReportConfig, ReportData, ReportDatalabelsConfig, ReportLegendConfig, ReportService } from 'discover/reports/report-service';
import { ChartComponent } from 'shell/common/chart/chart.component';
import { ShellTranslationKey } from 'shell/shell.tk';

import { afterDraw } from './pie-label-plugin';

@Component({
    selector: 'us-report',
    templateUrl: './report.html',
})
export class ReportComponent implements OnDestroy {

    readonly shellTK = ShellTranslationKey;

    loading = true;
    error: any;

    chartConfig: ChartConfiguration;
    reportData?: ReportData;

    onWindowResize = new Subject<void>();

    protected hideChart: boolean;

    private _chart: ChartComponent;
    private _reportConfig: ReportConfig;
    private subscription = new Subscription();

    constructor(
        private service: ReportService,
        private element: ElementRef,
        @Inject(WindowWrapper) private window: Window,
    ) {
        Chart.register(ChartDataLabels);

        this.subscription.add(this.onWindowResize.pipe(debounceTime(300)).subscribe(() => {
            this.changeChartRatio();
        }));
    }

    @HostListener('window:resize')
    onResize() {
        this.onWindowResize.next();
    }

    @ViewChild(ChartComponent) set chart(chart: ChartComponent) {
        const init = !this._chart;

        this._chart = chart;
        if (init && this._chart) {
            this.updateChartData();
        }
        this.changeChartRatio();
    }

    get chart(): ChartComponent {
        return this._chart;
    }

    @Input() set reportConfig(v: ReportConfig) {
        this._reportConfig = v;
        this.updateReportConfig();
    }

    get reportConfig(): ReportConfig {
        return this._reportConfig;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    async loadData(filters: any) {
        try {
            this.reportData = await this.service.getData(this.reportConfig.identifier, filters);
            this.updateChartData();
        } catch (e) {
            console.error(e);
        }
    }

    downloadTableAsCsv() {
        const rows: string[] = [this.reportData?.labels.join(',') ?? ''];

        for (const data of this.reportData?.datasets ?? []) {
            rows.push(data.data.map((d) => (d.value as string | undefined ?? '')).join(','));
        }

        const file = new Blob([rows.join('\n')], { type: 'text/csv' });
        const link = document.createElement('a');

        link.download = this.reportConfig.title + '.csv';

        link.href = window.URL.createObjectURL(file);

        link.click();
    }

    downloadChartAsImage() {
        this.chart.downloadChartAsImage(this.reportConfig.title + '.png');
    }

    private updateHideChart() {
        this.hideChart = this.chartConfig?.type === 'pie' &&
            this.reportData?.datasets.every((dataset) => dataset.data == null || dataset.data.length === 0) === true;
    }

    private updateReportConfig() {
        if (this.reportConfig.chartType === 'table') {
            return;
        }

        this.chartConfig = {
            type: this.reportConfig.chartType,
            options: this.getChartOptions(this.reportConfig.chartType),
            plugins: this.getChartPlugins(this.reportConfig.chartType),
            data: {
                labels: [],
                datasets: [],
            },
        };
        this.updateHideChart();
    }

    private getScales(yAxis: ReportAxisConfig | undefined, xAxis: ReportAxisConfig | undefined): { x?: ScaleOptions; y?: ScaleOptions } | undefined {
        if (yAxis == null && xAxis == null) {
            return;
        }

        const scales: { x?: ScaleOptions; y?: ScaleOptions } = {};

        if (yAxis != null) {
            scales.y = this.getScaleOptions(yAxis);
        }
        if (xAxis != null) {
            scales.x = this.getScaleOptions(xAxis);
        }

        return scales;
    }

    private getScaleOptions(axisConfig: ReportAxisConfig): ScaleOptions {
        const axisOptions: ScaleOptions = {
            title: {
                display: !!axisConfig.label,
                text: axisConfig.label ?? '',
            },
        };

        if (axisConfig.stacked != null) {
            axisOptions.stacked = axisConfig.stacked;
        }

        if (axisConfig.ticks != null) {
            axisOptions.ticks = axisConfig.ticks;
            axisOptions.min = axisConfig.ticks.min;
            axisOptions.max = axisConfig.ticks.max;
        }

        return axisOptions;
    }

    private getLegend<Type extends keyof ChartTypeRegistry>(type: keyof ChartTypeRegistry, legend?: ReportLegendConfig): Partial<LegendOptions<Type>> {
        if (legend == null) {
            return { position: 'center', align: 'start' };
        }

        // TODO check the align override, should it be controlled by BE instead?
        return Object.assign(legend, { align: 'start' });
    }

    private getDatalabels(datalabels: ReportDatalabelsConfig = { display: false }): ChartDataLabelsOptions | undefined {
        return Object.assign(datalabels, { formatter: this.createDataLabelFormatter(datalabels?.content) });
    }

    private createDataLabelFormatter(content?: DataLabelContent) {
        switch (content) {
            case DataLabelContent.DatasetLabel: return ((_: any, context: any) => context?.dataset?.label);
            case DataLabelContent.DataLabel: return ((_: any, context: any) => ((context?.dataset?.labels || [])[context.dataIndex] || null));
            case DataLabelContent.Value: return ((value: any) => value);
            case DataLabelContent.X: return ((value: any) => value?.x);
            case DataLabelContent.Y: return ((value: any) => value?.y);
            case DataLabelContent.R: return ((value: any) => value?.r);
            default: return;
        }
    }

    private updateChartData() {

        this.updateHideChart();

        if (!this.reportData || !this.chart || this.hideChart) {
            return;
        }

        const chartData: ChartData = {
            labels: this.reportData?.labels as string[],
            datasets: this.reportData.datasets.map((dataset, index) => {
                const ds: any = {
                    label: dataset.label,
                    labels: dataset.labels,
                    data: dataset.data,
                    backgroundColor: this.getBackgroundColour(index, dataset.color),
                    borderColor: this.getBorderColour(index, dataset.color),
                    borderWidth: this.getBorderWidth(),
                    hoverBorderColor: this.getBorderColour(index, dataset.color),
                    hoverBorderWidth: this.getHoverBorderWidth(),
                    tension: dataset.tension ?? 0,
                    tooltips: dataset.tooltips,
                };

                if (this.chartConfig.type === 'pie') {
                    ds.borderWidth = 1;
                    ds.polyline = {
                        formatter: (value: any) => `${value}`,
                    };
                }

                return ds;
            }),
        };

        this.chart.clearData();
        this.chart.addData(chartData);

    }

    private getBackgroundColour(index: number, colour?: string | string[]): string | string[] {
        return this.getColour(index, colour);
    }

    private getBorderColour(index: number, colour?: string | string[]): string | string[] | undefined {
        switch (this.reportConfig.chartType) {
            case 'bar':
            case 'pie':
            case 'doughnut':
                return '#ffffff';
            case 'polarArea':
            case 'scatter':
            case 'bubble':
                return undefined;
            default:
                return this.getColour(index, colour);
        }
    }

    private getBorderWidth(): number | { top: number; bottom: number; left: number; right: number } | undefined {
        switch (this.reportConfig.chartType) {
            case 'bar':
                return {
                    top: 1,
                    bottom: 0,
                    left: 0,
                    right: 0,
                };
            case 'polarArea':
            case 'scatter':
            case 'bubble':
                return undefined;
            default:
                return 1;
        }
    }

    private getChartOptions(type: keyof ChartTypeRegistry) {
        const options: ChartOptions = {
            scales: this.getScales(this.reportConfig.yAxis, this.reportConfig.xAxis),
            plugins: {
                legend: this.getLegend(type, this.reportConfig.legend),
                datalabels: this.getDatalabels(this.reportConfig.datalabels),
                tooltip: {
                    callbacks: {
                        label: (context: any) => {
                            const customTooltip = (context.dataset?.tooltips || [])[context.dataIndex];

                            return customTooltip || `${context.dataset?.label ?? ''}: ${context.formattedValue}`;
                        },
                    },
                },
            },
        };

        if (type === 'pie') {
            options.layout = {
                padding: {
                    top: 50,
                    left: 50,
                    right: 50,
                    bottom: 50,
                },
            };
        }

        return options;
    }

    private getChartPlugins(type: keyof ChartTypeRegistry): Plugin<any>[] {

        const plugins: Plugin<any>[] = [{
            id: 'custom_canvas_background_color',
            beforeDraw: (chart: Chart) => {
                const { ctx } = chart;

                ctx.save();
                ctx.globalCompositeOperation = 'destination-over';
                ctx.fillStyle = '#fff';
                ctx.fillRect(0, 0, chart.width, chart.height);
                ctx.restore();
            },
        }];

        if (type === 'pie') {
            plugins.push({
                id: 'pie-custom-labels',
                afterDraw,
            });
        }

        return plugins;
    }

    private getHoverBorderWidth(): number {
        return this.getBorderWidth() ? 1 : 0;
    }

    // look up in 2 dictionaries or return itself, if no colour supplied return random
    private getColour(index: number, colour?: string | string[]): string | any[] {
        if (!colour) {
            return ReportColourCommon[index % Object.keys(ReportColourCommon).length] as unknown as string;
        }

        if (Array.isArray(colour)) {
            return colour.map((c, i) => this.getColour(i, c));
        }

        return ReportColour[colour] ?? ReportColourCommon[colour] ?? colour;
    }

    private changeChartRatio() {
        const windowHeight = this.window.innerHeight;
        const componentWidth = this.element.nativeElement.clientWidth;
        let ratio = 1;

        if (componentWidth * 1.5 > windowHeight) {
            ratio = 2;
        }
        if (componentWidth / 1.5 > windowHeight) {
            ratio = 3;
        }
        if (this.chart) {
            this.chart.changeRatio(ratio);
        }
    }

}
