import { Component, HostListener, Inject, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonTranslationKey, Modal, ModalData, ModalRuntime, ModalService, SharedTermsTranslationKey } from '@unifii/library/common';
import { UserInfo } from '@unifii/sdk';

import { FingerPrintService } from 'capacitor/finger-print.service';
import { VibrateService } from 'capacitor/vibrate.service';
import { Config } from 'config';
import { DiscoverTranslationKey } from 'discover/discover.tk';
import { pinHide, pinShake, pinShow } from 'discover/pin/pin-animations';
import { PinModalData, PinService, PinState } from 'discover/pin/pin-types';
import { Authentication } from 'shell/services/authentication';

@Component({
    selector: 'ud-pin-setup',
    templateUrl: './pin-setup.html',
    styleUrls: ['./pin-screen.less'],
    animations: [pinShow, pinHide, pinShake],
})

export class PinSetupComponent implements Modal<PinModalData, PinState> {

    readonly sharedTermsTK = SharedTermsTranslationKey;
    readonly discoverTK = DiscoverTranslationKey;

    protected pin = '';
    protected confirmPin = '';
    protected message = '';
    protected error: string | null;
    protected closeAllowed: boolean;

    private inputValid: boolean;

    constructor(
        @Inject(ModalData) public data: PinModalData,
        public runtime: ModalRuntime<PinModalData, PinState>,
        @Inject(Authentication) private auth: Authentication,
        @Inject(PinService) private pinService: PinService,
        @Inject(Config) private config: Config,
        private modalService: ModalService,
        private vibrateService: VibrateService,
        private fingerPrintService: FingerPrintService,
        private translate: TranslateService,
        private zone: NgZone,
    ) {
        this.message = data.message ?? '';
        this.closeAllowed = data.closeAllowed ?? false;
    }

    // used to allow active status on touch events
    @HostListener('touchstart', ['$event'])
    touchEvent() { }

    // desktop keyboard typing pin
    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {

        this.zone.run(() => {
            if (Number(event.key) >= 0 && Number(event.key) <= 9) {
                this.add(Number(event.key));

                return;
            }

            if (event.key === 'Backspace') {
                this.remove();
            }
        });
    }

    add(num: number) {

        // reset error
        this.error = null;

        if (this.confirmPin.length >= 4) {
            return;
        }

        if (this.pin.length >= 4) {
            this.confirmPin = this.confirmPin + String(num);

            return;
        }

        this.pin = this.pin + String(num);
    }

    // fired by UI after input
    numberAdded() {

        if (this.inputValid) {
            return;
        }

        if (this.pin.length !== 4 || this.confirmPin.length !== 4) {
            return;
        }

        this.inputValid = true;
        this.setPin();
    }

    remove() {

        if (!this.pin && !this.confirmPin) {
            return;
        }

        if (this.confirmPin.length > 0) {
            this.confirmPin = this.confirmPin.substring(0, this.confirmPin.length - 1);

            return;
        }

        this.pin = this.pin.substring(0, this.pin.length - 1);
    }

    close() {

        if (this.closeAllowed) {
            this.runtime.close(PinState.Closed);
        }
    }

    async logout() {

        // get values before logout, because they will be gone
        const tenant = this.config.unifii.tenant as string;
        const userId = (this.auth.userInfo as UserInfo).id as string;

        const done = await this.auth.logout({ askConfirmation: true });

        if (!done) {
            return;
        }

        this.pinService.clearPin(tenant, userId);
        this.runtime.close(PinState.Logout);
    }

    private setPin() {

        if (this.pin !== this.confirmPin) {
            this.error = this.translate.instant(DiscoverTranslationKey.PINSetupErrorCheckNotMatch);
            this.vibrateService.vibrate(500);
            this.pin = '';
            this.confirmPin = '';
            this.inputValid = false;

            return;
        }

        this.setFingerPrint();
    }

    private async setFingerPrint() {
        try {

            const type = await this.fingerPrintService.isAvailable();

            // if no finger print (old phone), skip and close
            if (!type) {
                this.runtime.close(PinState.Verified);

                return;
            }

            let title = this.translate.instant(DiscoverTranslationKey.FingerprintLabel);
            let message = this.translate.instant(DiscoverTranslationKey.PINSetupChooseFingerprintMessage);

            if (type === 'face') {
                title = this.translate.instant(DiscoverTranslationKey.FaceIDLabel);
                message = this.translate.instant(DiscoverTranslationKey.PINSetupChooseFaceIDMessage);
            }

            const res = await this.modalService.openConfirm({
                title,
                message,
                confirmLabel: this.translate.instant(CommonTranslationKey.YesLabel),
                cancelLabel: this.translate.instant(CommonTranslationKey.NoLabel),
            });

            if (res) {
                await this.checkFingerPrint(type);

                return;
            }

            await this.pinService.savePin(this.pin, false);
            this.runtime.close(PinState.Verified);

        } catch (error) {
            console.log(error);
            await this.pinService.savePin(this.pin, false);
            this.runtime.close(PinState.Verified);
        }
    }

    private async checkFingerPrint(type: string) {

        try {

            await this.fingerPrintService.show(this.pinService.fingerprintConfig);
            await this.pinService.savePin(this.pin, true);
            this.runtime.close(PinState.Verified);

        } catch (reason) {
            console.log(`Check fingerprint error`, reason);
            this.fingerPrintFailed(type);
        }
    }

    private async fingerPrintFailed(type: string) {

        let message = this.translate.instant(DiscoverTranslationKey.PINSetupFeedbackFingerprintFailMessage);

        if (type === 'face') {
            message = this.translate.instant(DiscoverTranslationKey.PINSetupFeedbackFaceIDFailMessage);
        }

        const res = await this.modalService.openConfirm({
            title: this.translate.instant(DiscoverTranslationKey.PINSetupFeedbackFailTitle),
            message,
            confirmLabel: this.translate.instant(SharedTermsTranslationKey.ActionRetry),
            cancelLabel: this.translate.instant(DiscoverTranslationKey.PINSetupActionUsePIN),
        });

        if (res) {
            this.checkFingerPrint(type);

            return;
        }

        await this.pinService.savePin(this.pin, false);
        this.runtime.close(PinState.Verified);
    }

}
