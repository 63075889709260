<div class="uf-app-bar">
    <a (click)="close()" class="uf-action tertiary">
        <uf-icon name="close" />
    </a>
    <h3>{{ (data.isEdit ? discoverTK.UserEditAction : discoverTK.UserAddAction) | translate }}</h3>
</div>
<form [formGroup]="form" (ngSubmit)="addUser()" class="col grow">
    <uf-panel class="grow">
        <div class="uf-grid pad">

            <uf-text *ngIf="firstNameControl && firstNameControl.enabled" [control]="firstNameControl"
                [label]="labelDictionary[userInfoControlKeys.FirstName]" class="col-12">
                <span *ngIf="firstNameRequired" class="suffix">*</span>
            </uf-text>

            <uf-text *ngIf="lastNameControl && lastNameControl.enabled" [control]="lastNameControl"
                [label]="labelDictionary[userInfoControlKeys.LastName]" class="col-12">
                <span *ngIf="lastNameRequired" class="suffix">*</span>
            </uf-text>

            <uf-text *ngIf="emailControl && emailControl.enabled" [control]="emailControl"
                [label]="labelDictionary[userInfoControlKeys.Email]" class="col-12">
                <span *ngIf="emailRequired" class="suffix">*</span>
            </uf-text>

            <uf-phone *ngIf="phoneControl && phoneControl.enabled" [control]="phoneControl"
                [label]="labelDictionary[userInfoControlKeys.Phone]" class="col-12">
                <span *ngIf="phoneRequired" class="suffix">*</span>
            </uf-phone>

            <uf-text *ngIf="usernameControl && usernameControl.enabled" [control]="usernameControl"
                [label]="labelDictionary[userInfoControlKeys.Username]" class="col-12">
                <span *ngIf="usernameRequired">*</span>
                <uf-help *ngIf="action == actionInvite" [content]="discoverTK.UserCanChangeUsernameHelp | translate"
                    class="suffix" />
            </uf-text>

            <uf-checkbox *ngIf="canChangeUsernameControl && canChangeUsernameControl?.enabled"
                [control]="canChangeUsernameControl" [label]="labelDictionary[userInfoControlKeys.CanChangeUsername]"
                class="col-12" />

            <ng-template [ngIf]="passwordControl && passwordControl.enabled">
                <uf-password-advanced [control]="passwordControl"
                    [label]="labelDictionary[userInfoControlKeys.Password]" class="col-12">
                    <span *ngIf="passwordRequired" class="suffix">*</span>
                </uf-password-advanced>
                <uf-password-indicator [control]="passwordControl"
                    [style.visibility]="!passwordControl.value ? 'hidden' : 'visible'" class="col-12" />
            </ng-template>

            <uf-hierarchy-input *ngIf="unitsControl && unitsControl.enabled" [control]="unitsControl"
                [label]="labelDictionary[userInfoControlKeys.Units]" class="col-12">
                <span *ngIf="unitsRequired" class="suffix">*</span>
            </uf-hierarchy-input>

            <uf-role-input *ngIf="rolesControl && rolesControl.enabled" [control]="rolesControl"
                [label]="labelDictionary[userInfoControlKeys.Roles]" [editableRoles]="editableRoles" class="col-12">
                <span *ngIf="rolesRequired" class="suffix">*</span>
            </uf-role-input>

            <uf-company-input *ngIf="companyControl && companyControl.enabled"
                [formControlName]="userInfoControlKeys.Company" [editableCompanies]="editableCompanies"
                [label]="labelDictionary[userInfoControlKeys.Company]" class="col-12" />
        </div>

        <div class="uf-grid condensed">
            <uf-claim-editor *ngIf="claimsControl && claimsControl.enabled" [control]="claimsControl"
                [editableClaimsValues]="editableClaimsValues" [width]="fieldWidth.Half" class="col-12 pad-sm-bottom" />
        </div>

        <!-- feedback-username-as-email to target hide it in custom apps -->
        <div *ngIf="!usernameControl?.enabled" class="uf-grid pad feedback-username-as-email">
            <uf-message [content]="discoverTK.UserCreateFeedbackUsernameAsEmail | translate" class="col-12" />
        </div>

    </uf-panel>

    <div class="uf-form-actions">
        <button (click)="close()" type="button" class="uf-button tertiary left">
            {{ sharedTermsTK.ActionCancel | translate }}
        </button>
        <button [disabled]="form.status === 'PENDING'" ufAutofocus type="submit" class="uf-button primary">
            {{ (data.isEdit ? sharedTermsTK.ActionEdit : sharedTermsTK.ActionAdd) | translate }}
        </button>
    </div>

</form>