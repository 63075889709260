import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataDescriptorBucketType, DataDescriptorCompanyType, DataDescriptorType, DataDescriptorUsersType, DataLoaderFactory, DataPropertyDescriptor, FilterEntry, FilterEntryDataDescriptorAdapter } from '@unifii/library/common';
import { AstNode, RequestAnalyticsOrigin, TableSourceType, VisibleFilterDescriptor } from '@unifii/sdk';

@Injectable()
export class TableFilterEntryFactory {

    constructor(
        private dataLoaderFactory: DataLoaderFactory,
        private translate: TranslateService,
        @Inject(FilterEntryDataDescriptorAdapter) private filterEntryAdapter: FilterEntryDataDescriptorAdapter,
    ) { }

    create(filter: VisibleFilterDescriptor, propertyDescriptors: Map<string, DataPropertyDescriptor>, source: TableSourceType, tableIdentifier: string, staticFilter?: AstNode): FilterEntry | undefined {

        const descriptorProperty = propertyDescriptors.get(filter.identifier);

        if (!descriptorProperty) {
            return;
        }

        return this.filterEntryAdapter.transform({
            descriptorType: this.getSourceType(source),
            descriptorProperty,
            visibleFilterDescriptor: filter,
            staticFilter,
            translateService: this.translate,
            dataLoaderFactory: this.dataLoaderFactory,
            requestAnalytics: { origin: RequestAnalyticsOrigin.Table, id: `${tableIdentifier}.${filter.identifier}` },
        });

    }

    private getSourceType(tableSourceType: TableSourceType): DataDescriptorType {
        switch (tableSourceType) {
            case TableSourceType.Bucket: return DataDescriptorBucketType;
            case TableSourceType.Company: return DataDescriptorCompanyType;
            case TableSourceType.Users: return DataDescriptorUsersType;
        }
    }

}
