<uf-panel class="container locked">
    <div class="enter-pin-screen">
        <div class="pin-input-wrapper grid--fixed">
            <div class="pin-input push-bottom col-1of2">
                <span class="message">{{message}}</span>
                <div [@shake]="error? 'yes': 'no'" class="pin-hint">
                    <span class="title">{{discoverTK.PINSetupCreatePINLabel | translate}}</span>
                </div>
                <div [ngClass]="{'error': error,'active': pin.length <= 3}" class="pin-input-field">
                    <span *ngIf="pin.length === 0" class="blink">|</span>
                    <span *ngFor="let char of pin.split('')" class="number">
                        <span [@hide] class="character">{{char}}</span>
                        <uf-icon [@show] class="small" name="radioDot" />
                    </span>
                    <div (click)="remove()" class="backspace hover-dot"
                        *ngIf="pin.length !== 0 && confirmPin.length === 0">
                        <uf-icon name="backspace" />
                    </div>
                </div>
                <div class="pin-hint pin-hint--confirm">
                    <span class="title">{{discoverTK.PINSetupConfirmPINLabel | translate}}</span>
                </div>
                <div [ngClass]="{'error': error,'active': pin.length >= 4}" class="pin-input-field">
                    <span *ngIf="pin.length === 4 && confirmPin.length === 0" class="blink">|</span>
                    <span *ngFor="let char of confirmPin.split('')" class="number">
                        <span [@hide] class="character">{{char}}</span>
                        <uf-icon [@show] (@show.done)="numberAdded()" class="small" name="radioDot" />
                    </span>
                    <div *ngIf="confirmPin.length !== 0" (click)="remove()" class="backspace hover-dot">
                        <uf-icon name="backspace" />
                    </div>
                </div>
                <div class="error-text">{{error}}</div>
                <div class="keypad">
                    <div (click)="add(1)" class="item-wrapper hover-dot">
                        <div class="item">
                            <span class="title">1</span>
                        </div>
                    </div>
                    <div (click)="add(2)" class="item-wrapper hover-dot">
                        <div class="item">
                            <span class="title">2</span>
                            <span class="subtitle">ABC</span>
                        </div>
                    </div>
                    <div (click)="add(3)" class="item-wrapper hover-dot">
                        <div class="item">
                            <span class="title">3</span>
                            <span class="subtitle">DEF</span>
                        </div>
                    </div>
                    <div (click)="add(4)" class="item-wrapper hover-dot">
                        <div class="item">
                            <span class="title">4</span>
                            <span class="subtitle">GHI</span>
                        </div>
                    </div>
                    <div (click)="add(5)" class="item-wrapper hover-dot">
                        <div class="item">
                            <span class="title">5</span>
                            <span class="subtitle">JKL</span>
                        </div>
                    </div>
                    <div (click)="add(6)" class="item-wrapper hover-dot">
                        <div class="item">
                            <span class="title">6</span>
                            <span class="subtitle">MNO</span>
                        </div>
                    </div>
                    <div (click)="add(7)" class="item-wrapper hover-dot">
                        <div class="item">
                            <span class="title">7</span>
                            <span class="subtitle">PQRS</span>
                        </div>
                    </div>
                    <div (click)="add(8)" class="item-wrapper hover-dot">
                        <div class="item">
                            <span class="title">8</span>
                            <span class="subtitle">TUV</span>
                        </div>
                    </div>
                    <div (click)="add(9)" class="item-wrapper hover-dot">
                        <div class="item">
                            <span class="title">9</span>
                            <span class="subtitle">WXYZ</span>
                        </div>
                    </div>
                    <div class="item-wrapper"></div>
                    <div (click)="add(0)" class="item-wrapper hover-dot">
                        <div class="item">
                            <span class="title">0</span>
                        </div>
                    </div>
                    <div class="item-wrapper hover-dot"></div>
                </div>
                <div class="close-buttons">
                    <span *ngIf="closeAllowed" (click)="close()">{{sharedTermsTK.ActionBack | translate}}</span>
                    <span (click)="logout()">{{sharedTermsTK.ActionLogout | translate}}</span>
                </div>
            </div>
        </div>
    </div>
</uf-panel>