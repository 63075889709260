<a *ngIf="menuDrawer" (click)="shell.toggleMenuDrawer()" class="uf-action tertiary">
    <uf-icon [name]="menuDrawer.icon" />
</a>
<a *ngIf="leftDrawer" (click)="shell.openLeftDrawer()" class="uf-action tertiary">
    <uf-icon [name]="leftDrawer.icon" />
</a>

<ng-template [usFeatureFlag]="'backButton'">
    <a (click)="back()" class="uf-action tertiary">
        <uf-icon name="arrowBack" />
    </a>
</ng-template>

<div class="title primary">{{title}}</div>

<ng-content />

<button *ngIf="previewMode === shell.mode" [disabled]="disablePreviewToggle" (click)="shell.toggleMode.next()"
    type="button" class="uf-action tertiary preview-icon">
    <uf-icon name="preview" />
</button>

<a *ngIf="rightDrawer" (click)="shell.openRightDrawer()" class="uf-action tertiary rightDrawer-wrap">
    <uf-icon [name]="rightDrawer.icon" />
    <uf-icon *ngIf="shell.getNotificationCount() > 0" [@grow] name="warning--solid" class="badge" />
</a>