import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { PanelComponent } from '@unifii/library/common';
import { Structure } from '@unifii/sdk';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { DeviceService } from 'capacitor/device.service';
import { ShellService } from 'shell/core/shell.service';
import { NavigationService } from 'shell/nav/navigation.service';
import { ShellTranslationKey } from 'shell/shell.tk';

@Component({
    selector: 'us-nav',
    templateUrl: 'nav.html',
    styleUrls: ['nav.less'],
})
export class NavComponent implements OnDestroy {

    @ViewChild(PanelComponent, { static: true }) panel: PanelComponent;

    @Input() img?: string;

    protected readonly shellTK = ShellTranslationKey;

    protected structure: Structure | null;
    protected isHomeActive: boolean;

    private subscriptions = new Subscription();

    constructor(
        router: Router,
        nav: NavigationService,
        private deviceService: DeviceService,
        private shell: ShellService,
    ) {

        this.structure = nav.structure;

        this.subscriptions.add(router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => {
            this.isHomeActive = nav.isRoot(router.routerState.snapshot);
        }));

        this.subscriptions.add(this.shell.menuOpened.subscribe(() => {

            if (this.panel && this.forceReflow) {
                /**
                 * force reflow on panel for
                 * mobile iOS 12.2 scroll bug where scroll position freezes
                 */
                this.panel.reflow();
            }
        }));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    protected get forceReflow(): boolean {
        return this.deviceService.isCapacitorIOS() || this.deviceService.isIosMobile();
    }

    protected close() {
        this.shell.closeMenuDrawer();
    }

}
