import { Component, Inject, Input, OnDestroy, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataDisplayListItem, ModalService, ProgressComponent, SharedTermsTranslationKey } from '@unifii/library/common';
import { Subscription } from 'rxjs';

import { Config } from 'config';
import { DiscoverTranslationKey } from 'discover/discover.tk';
import { ContactInfoModalComponent, ContactInfoModalData } from 'discover/user-access/contact-info-modal.component';
import { ShellService } from 'shell/core/shell.service';
import { TranslationsService } from 'shell/services/translations.service';
import { ChangeLanguageComponent, ChangeLanguageModalData } from 'shell/translations/change-language.component';

@Component({
    selector: 'ud-user-access-box',
    templateUrl: './user-access-box.html',
    styleUrls: ['./user-access-box.less'],
})
export class UserAccessBoxComponent implements OnDestroy {

    @Input() backButtonRoute: string | null;

    @ViewChild(ProgressComponent) private progressBar: ProgressComponent;

    readonly discoverTK = DiscoverTranslationKey;
    readonly sharedTermsTK = SharedTermsTranslationKey;

    inProgress: boolean;
    versionInfo: DataDisplayListItem[];

    private subscriptions = new Subscription();

    constructor(
        @Inject(Config) public config: Config,
        public translations: TranslationsService,
        private modalService: ModalService,
        private translate: TranslateService,
        private shell: ShellService,
    ) {

        this.versionInfo = this.getVersionInfo();
        this.subscriptions.add(this.shell.busyEvents.subscribe((e) => { this.progressChange(e); }));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    get productLogo() {
        return this.config.unifii.productLogo;
    }

    showContactInfo() {
        const data: ContactInfoModalData = { tenantSettings: this.config.unifii.tenantSettings };

        void this.modalService.openFit(ContactInfoModalComponent, data);
    }

    showChangeLanguage() {

        const data: ChangeLanguageModalData = {
            languages: this.translations.languages,
            current: this.translations.currentLanguage,
        };

        void this.modalService.openFit(ChangeLanguageComponent, data);
    }

    private getVersionInfo(): DataDisplayListItem[] {

        const info = [{ term: this.translate.instant(DiscoverTranslationKey.VersionLabel), data: this.config.version }];

        if (this.config.productVersion != null) {
            info.push({ term: this.translate.instant(DiscoverTranslationKey.ProductVersionLabel), data: this.config.productVersion });
        }

        return info;
    }

    private progressChange(inProgress: boolean) {

        if (this.progressBar == null) {
            return;
        }
        if (inProgress) {
            this.progressBar.start();
        } else {
            this.progressBar.complete();
        }
        this.inProgress = inProgress;
    }

}
