import { Injectable, InjectionToken, Type } from '@angular/core';
import { TableSourceType } from '@unifii/sdk';

import { CompanyFormComponent } from 'discover/company-management/company-form.component';
import { UserDetailsComponent } from 'discover/user-management';
import { CollectionItemComponent } from 'shell/content/collection-item.component';
import { CollectionComponent } from 'shell/content/collection.component';
import { CollectionContent, CollectionItemContent, CompanyContent, FormContent, PageContent, UserContent, ViewContent } from 'shell/content/content-types';
import { PageComponent } from 'shell/content/page.component';
import { ViewComponent } from 'shell/content/view.component';
import { FormComponent } from 'shell/form/form.component';
import { TablePageComponent } from 'shell/table/table-page.component';
import { TableDetailPageComponent } from 'shell/table-detail/table-detail-page.component';

export interface SelectorArgs {
    tags?: string[];
}

export interface ContentComponentSelector {
    getPageComponent(identifier?: string): Type<PageContent>;
    getViewComponent(identifier?: string): Type<ViewContent>;
    getFormComponent(identifier?: string): Type<FormContent>;
    getTableComponent(source?: TableSourceType): Type<any>; // Table pages can be anything as settings and logic existings in the table manager class
    getTableDetailComponent(identifier?: string, id?: string): Type<TableDetailPageComponent>;
    getCollectionComponent(identifier?: string): Type<CollectionContent>;
    getCollectionItemComponent(identifier?: string): Type<CollectionItemContent>;
    getCompanyComponent(id?: string): Type<CompanyContent>;
    getUserComponent(id?: string): Type<UserContent>;
    getCustom(identifier?: string, args?: SelectorArgs): Type<any> | null;
}

export const ContentComponentSelector = new InjectionToken<ContentComponentSelector>('UfContentComponentSelector');

@Injectable()
export class ShellContentComponentSelector implements ContentComponentSelector {

    getPageComponent(): Type<PageContent> {
        return PageComponent;
    }

    getViewComponent(): Type<ViewContent> {
        return ViewComponent;
    }

    getFormComponent(): Type<FormContent> {
        return FormComponent;
    }

    getCollectionComponent(): Type<CollectionContent> {
        return CollectionComponent;
    }

    getCollectionItemComponent(): Type<CollectionItemContent> {
        return CollectionItemComponent;
    }

    getTableComponent(): Type<TablePageComponent> {
        return TablePageComponent;
    }

    getTableDetailComponent(): Type<TableDetailPageComponent> {
        return TableDetailPageComponent;
    }

    getCompanyComponent(): Type<CompanyContent> {
        return CompanyFormComponent;
    }

    getUserComponent(): Type<UserContent> {
        return UserDetailsComponent;
    }

    getCustom(): Type<any> | null {
        throw new Error('Method not implemented.');
    }

}
