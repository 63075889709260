import { Injectable, inject } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Haptics } from '@capacitor/haptics';

import { DeviceService } from './device.service';

@Injectable({ providedIn: 'root' })
export class VibrateService {

    private deviceService = inject(DeviceService);

    vibrate(durationInMilliseconds: number) {

        if (!(this.deviceService.isNative() && Capacitor.isPluginAvailable('Haptics'))) {
            return;
        }

        Haptics.vibrate({ duration: durationInMilliseconds });
    }

}
