import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { ErrorType } from '@unifii/sdk';

import { NavigationService } from './navigation.service';

/**
 * Checks for valid node
 */
export const nodeGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {

    const router = inject(Router);
    const nav = inject(NavigationService);

    const nodeId = route.paramMap.get('nodeId');
    const node = nav.getNode(nodeId);

    if (node == null) {
        router.navigate(['/error', { type: ErrorType.NotFound }]);

        return false;
    }

    if (!nav.canAccessNode(node)) {
        router.navigate(['/error', { type: ErrorType.Unauthorized }]);

        return false;
    }

    // Redirect to canonical URL, if it's not
    const commands = nav.nodeToCommands(node);
    const segments = nav.getSegments(state);

    if (!commands) {
        router.navigate(['/']);

        return false;
    }

    // slice '/' off before comparison
    if (!isPrefix(commands.slice(1), segments)) {
        console.log('No match, rerouting');

        router.navigate(commands);

        return false;
    }

    nav.current = node;

    return true;
};

const isPrefix = (nodeSegments: string[], urlSegments: string[]) => {

    if (nodeSegments.length > urlSegments.length) {
        return false;
    }

    for (let i = 0; i < nodeSegments.length; i++) {
        if (nodeSegments[i] !== urlSegments[i]) {
            return false;
        }
    }

    return true;
};
