import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ErrorType, PermissionAction, ProjectInfo, TenantClient, ensureError, ensureUfError, hasLengthAtLeast } from '@unifii/sdk';

import { DiscoverContext } from 'discover/discover-context';
import { DiscoverTranslationKey } from 'discover/discover.tk';
import { ErrorService } from 'shell/errors/error.service';
import { OfflineManager } from 'shell/offline/offline-manager';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { UserAccessManager } from 'shell/services/user-access-manager';

/**
 * This function is responsible for:
 * - Adding allowed projects to localStorage
 * - Redirecting if only one available project
 * - Redirecting back to login if errors
 * Guard is useful for:
 * - Preventing any unwanted flashes in UI when retrieving data or redirecting
 */
export const projectSelectorGuard: CanActivateFn = () => new ProjectSelectorGuard(
    inject(TenantClient),
    inject(DiscoverContext),
    inject(ErrorService),
    inject(Authentication),
    inject(TranslateService),
    inject(OfflineManager),
    inject(UserAccessManager),
).canActivate();

class ProjectSelectorGuard {

    constructor(
        private tenantClient: TenantClient,
        private context: DiscoverContext,
        private errorService: ErrorService,
        private auth: Authentication,
        private translate: TranslateService,
        private offlineManager: OfflineManager,
        private accessManager: UserAccessManager,
    ) { }

    async canActivate(): Promise<boolean> {

        try {
            // A computed set/get
            if (this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getProjectsPath(), PermissionAction.List).granted) {
                this.auth.allowedProjects = await this.tenantClient.getProjects();
            }

            if (!this.auth.allowedProjects.length || this.auth.allowedProjects.length > 1) {
                return true;
            }

            await this.updateProjectAndRedirect();

        } catch (e) {
            let error = ensureUfError(e);

            // Don't show message for expired token
            if (error.type !== ErrorType.Unauthorized) {
                error = this.errorService.mergeError(error, this.translate.instant(DiscoverTranslationKey.SelectProjectErrorLoadingProjects));
                void this.accessManager.deny({ error: error.message });
            }
        }

        return false;
    }

    private async updateProjectAndRedirect() {

        try {

            this.context.project = hasLengthAtLeast(this.auth.allowedProjects, 1) ? await this.getProject(this.auth.allowedProjects[0].id) : null;
            this.accessManager.grant();

        } catch (err) {
            let error = ensureUfError(err);

            // Don't show message for expired token
            if (error.type !== ErrorType.Unauthorized) {
                error = this.errorService.mergeError(error, this.translate.instant(DiscoverTranslationKey.SelectProjectErrorLoadingProject));
                void this.accessManager.deny({ error: error.message });
            }
        }
    }

    private async getProject(id: string): Promise<ProjectInfo> {

        try {
            const project = await this.tenantClient.getProject(id);

            // Close previous project DB reference
            this.offlineManager.projectChanged();

            return project;
        } catch (error) {
            throw this.errorService.createLoadError(this.translate.instant(DiscoverTranslationKey.SelectProjectErrorLoadingProject), ensureError(error));
        }
    }

}
