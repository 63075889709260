<div class="uf-app-bar flat">
    <div class="img-wrap">
        <img *ngIf="img" [attr.src]="img" />
    </div>
    <a (click)="close()" class="uf-action tertiary">
        <uf-icon name="close" />
    </a>
</div>

<uf-panel [forceReflow]="forceReflow" class="grow">
    <!-- home menu -->
    <a [class.active]="isHomeActive" [routerLink]="['/']" class="home">
        <uf-icon name="home" />
        {{structure?.name || (shellTK.MenuLinkHomeLabel | translate)}}
    </a>
    <!-- structure children -->
    <us-nav-item *ngFor="let node of structure?.children" [node]="node" />
</uf-panel>