import { Component } from '@angular/core';
import { RuntimePage } from '@unifii/library/common';

import { PageContent } from './content-types';

@Component({
    selector: 'ud-page',
    templateUrl: './page.html',
})
export class PageComponent implements PageContent {

    page: RuntimePage;
    title: string;

}
