import { Component, Input, QueryList, ViewChildren } from '@angular/core';
import { TableDetailTemplate } from '@unifii/sdk';

import { DiscoverTranslationKey } from 'discover/discover.tk';
import { TableNodeInfo } from 'shell/shell-model';

import { DashboardTableComponent } from './dashboard-table.component';

@Component({
    selector: 'us-dashboard',
    templateUrl: './dashboard.html',
    styleUrls: ['./dashboard.less', '../content/content-node.less'],
})
export class DashboardComponent {

    @Input() pageMode = TableDetailTemplate.PageView;
    @Input({ required: true }) emptyMessage: string | undefined;
    @Input({ required: true }) tableInfos: TableNodeInfo[];
    @ViewChildren(DashboardTableComponent) protected dashboardTables: QueryList<DashboardTableComponent> | null = null;

    protected readonly discoverTK = DiscoverTranslationKey;
    protected readonly templateEnumVales = TableDetailTemplate;
    protected showNoEmptyMessage = false;

    private hiddenTablesSet = new Set<TableNodeInfo>();

    protected updateHiddenTablesSet(tableInfo: TableNodeInfo, hidden: boolean) {
        if (!hidden) {
            this.hiddenTablesSet.delete(tableInfo);
        } else {
            this.hiddenTablesSet.add(tableInfo);
        }

        this.showNoEmptyMessage = !!this.tableInfos.length && this.tableInfos.length === this.hiddenTablesSet.size;
    }

    protected refreshBuckets() {
        for (const db of this.dashboardTables ?? []) {
            db.reload();
        }
    }

}
