import { KEY_SIGN_IN } from 'shell/services/legacy-route-adapter';

// Routes
export const UserAccessRootPath = 'login';
export const UnifiiLoginPath = 'form';
export const IdentityPath = 'identity';
export const UnifiiPasswordChangePath = 'request-password-change';
export const SSOPath = 'sso';
export const TenantSelectionPath = 'tenant';
export const ProjectSelectionPath = 'projects';
export const CompleteRegistrationPath = 'complete-registration';
export const PasswordChangePath = 'password-change';
export const MFAPath = 'mfa';
export const AuthorizePath = 'authorize';
export const ExternalPath = 'e'; // used when routing from form to form
export const CustomReport = 'custom-report';
/**
 * Main routes (or paths the may be used at root level) are
 * prefixed with ~ symbol to prevent clashes with identifiers or id's
 */
export const SignInPath = KEY_SIGN_IN; // sign feature never delete
