import { Injectable } from '@angular/core';

/**
 * Responsible for collecting edited state from components across the app.
 * Injected at platform level as shared singleton
 */
@Injectable({ providedIn: 'platform' })
export class EditedDataService {

    private editedData = new Set<object>();

    /**
     * set edited status of component
     */
    setEditedStatus(component: object, edited: boolean) {
        if (edited) {
            this.editedData.add(component);

            return;
        }
        this.editedData.delete(component);
    }

    /**
     * get edited status of component
     */
    getEditedStatus(component: object): boolean {
        return this.editedData.has(component);
    }

    /**
     * clear all edited statuses
     */
    clearEdited() {
        this.editedData.clear();
    }

    /**
     * check if any statuses are set to true
     */
    get edited(): boolean {
        return !!this.editedData.size;
    }

}
