import { AuthorizePath } from 'discover/discover-constants';

export const createIdentityUrl = (authorizationEndpoint: string, clientId: string, redirectUri: string, encryptedStateString: string): string => {
    const params = new URLSearchParams([
        ['response_type', 'code'],
        ['client_id', clientId],
        ['state', encodeURIComponent(encryptedStateString)],
        ['redirect_uri', redirectUri],
        ['scope', 'openid profile email phone'],
    ]);

    return `${authorizationEndpoint}?${params.toString()}`;
};

export const createIdentityRedirectUri = (window: Window, isCapacitorIOS?: boolean ): string => {
    let redirectUri = `${window.location.origin}/${AuthorizePath}`;

    if (isCapacitorIOS) {
        // hack for iOS webview and azure, can't register non https urls as a redirect URI and iOS web doesn't support a https as a baseURL
        redirectUri = redirectUri.replace('unifii://', 'https://');
    }

    return redirectUri;
};

