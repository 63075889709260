import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService, CommonTranslationKey, SharedTermsTranslationKey, UfControl, ValidatorFunctions } from '@unifii/library/common';
import { MeClient, UfRequestError, ensureUfRequestError, isBoolean, isDictionary, isOptionalType, isValueOfStringEnumType } from '@unifii/sdk';
import { isString } from 'markdown-it/lib/common/utils';
import { Secret, TOTP } from 'otpauth';

import { PasswordChangePath, ProjectSelectionPath, UserAccessRootPath } from 'discover/discover-constants';
import { ErrorService } from 'shell/errors/error.service';
import { AppError } from 'shell/errors/errors';
import { Authentication, LogoutArgs } from 'shell/services/authentication';
import { MfaStatus } from 'shell/services/shell-authentication.service';
import { UserAccessManager } from 'shell/services/user-access-manager';

import { PasswordChangeComponentNavigationState } from './password-change.component';

export interface MfaComponentNavigationState {
    mfaStatus: MfaStatus;
    rememberMe?: boolean;
    password?: string;
    params?: MfaPrams;
}

interface MfaPrams {
    projectId?: string;
}

export const isMfaComponentNavigationState = (data: unknown): data is MfaComponentNavigationState =>
    isDictionary(data) &&
    isValueOfStringEnumType(MfaStatus)(data.mfaStatus) &&
    isOptionalType(data.rememberMe, isBoolean) &&
    isOptionalType(data.password, isString) &&
    isOptionalType(data.params, isMfaParams);

const isMfaParams = (data: unknown): data is MfaPrams =>
    isDictionary(data) &&
    isOptionalType(data.projectId, isString);

@Component({
    selector: 'ud-mfa',
    templateUrl: 'mfa.html',
    styleUrls: ['mfa.less'],
})
export class MFAComponent implements OnInit, OnDestroy {

    protected readonly sharedTermsTK = SharedTermsTranslationKey;
    protected readonly commonTK = CommonTranslationKey;
    protected mfaUrl: string | undefined;
    protected secret: string | undefined;
    protected toptCodeControl: UfControl;
    protected inProgress = false;

    private router = inject(Router);
    private userAccessManager = inject(UserAccessManager);
    private meClient = inject(MeClient);
    private auth = inject(Authentication);
    private errorService = inject(ErrorService);
    private translateService = inject(TranslateService);
    private clipboardService = inject(ClipboardService);
    private state: MfaComponentNavigationState = history.state; // type assumed by mfa-guard
    private totp: TOTP | undefined;

    ngOnInit() {
        this.toptCodeControl = new UfControl(ValidatorFunctions.required(this.translateService.instant(SharedTermsTranslationKey.ValidatorValueRequired)));

        if (this.state.mfaStatus === MfaStatus.MfaSetupRequired) {
            this.setup();
        }
    }

    ngOnDestroy() {
        this.userAccessManager.showError(null);
    }

    protected async verify() {

        if (this.inProgress) {
            return;
        }

        if (this.toptCodeControl.invalid) {
            this.toptCodeControl.setSubmitted();

            return;
        }

        this.inProgress = true;

        const token: string = this.toptCodeControl.value.trim();

        try {
            if (this.state.mfaStatus === MfaStatus.MfaSetupRequired && this.secret && this.totp) {

                if (this.totp.validate({ token, window: 1 }) == null) {
                    throw this.errorService.createError(this.translateService.instant(CommonTranslationKey.MfaInvalidMessage));
                }

                await this.meClient.setVirtualMfaCode(this.secret);
            }

            await this.auth.login({ mfa_token: token }, this.state.rememberMe);

            void this.router.navigate(['/', UserAccessRootPath, ProjectSelectionPath, this.state.params ?? {}]);

        } catch (e) {
            const error = ensureUfRequestError(e);

            if (isDictionary(error.data) && !!error.data.passwordChangeRequired) {
                void this.router.navigate(['/', PasswordChangePath], { state: { oldPassword: this.state.password, params: this.state.params } satisfies PasswordChangeComponentNavigationState });

                return;
            }

            this.userAccessManager.showError(this.getAuthError(error));
        } finally {
            this.inProgress = false;
        }
    }

    protected logout(args?: LogoutArgs) {
        if (this.inProgress) {
            return;
        }

        void this.auth.logout(args);
    }

    protected copySecret() {

        if (!this.secret) {
            return;
        }

        void this.clipboardService.setText(this.secret, { silent: false });
    }

    private setup() {
        try {

            if (!this.auth.userInfo) {
                throw new Error(this.errorService.unhandledErrorMessage);
            }

            const secret = new Secret({ size: 10 });

            this.totp = new TOTP({
                issuerInLabel: false,
                issuer: 'Unifii',
                label: this.auth.userInfo.username,
                secret,
            });

            this.mfaUrl = this.totp.toString();
            this.secret = this.totp.secret.base32;
        } catch (e) {
            this.logout({ error: ensureUfRequestError(e).message });
        }

    }

    private getAuthError(error: UfRequestError): AppError {

        if (isDictionary(error.data) && error.data.error === 'invalid_grant') {
            return this.errorService.createError(error.data.error_description, error);
        }

        if (error.message) {
            this.errorService.createError(error.message, error);
        }

        return this.errorService.createError(this.errorService.unhandledErrorMessage, error);
    }

}
