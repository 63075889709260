import { PermissionAction } from '@unifii/sdk';

import { Resource } from './shell-model';

export const AppTitle = 'Unifii';

export const UserInfoPath = `user-info`;
export const UserProfilePath = `user-profile`;
export const CreateUserPath = `create-user`;
export const InviteUserPath = `invite-user`;
export const FormDataPath = `form-data`;
export const NewItemPath = 'new'; // form, company
export const PagePath = '~page';

export const MobileScreenWidth = 768;
export const TableSearchMinLength = 3;

export const Resources: Resource = {
    segment: '',
    name: 'Resources',
    allowsCondition: false,
    actions: [],
    children: [
      {
        name: 'Roles',
        segment: 'roles',
        allowsCondition: false,
        actions: [
          {
            name: PermissionAction.List,
            description: 'List Roles',
          },
        ],
        children: [],
      },
      {
        name: 'User Claims',
        segment: 'default-claims',
        allowsCondition: false,
        actions: [
          {
            name: PermissionAction.List,
            description: 'List User Claims',
          },
        ],
        children: [
          {
            name: 'User Claim',
            segment: '?',
            allowsCondition: false,
            actions: [
              {
                name: PermissionAction.Read,
                description: 'Read User Claim',
              },
            ],
            children: [],
          },
        ],
      },
      {
        name: 'Users',
        segment: 'users',
        allowsCondition: true,
        actions: [
          {
            name: PermissionAction.List,
            description: 'List Users',
          },
          {
            name: PermissionAction.Add,
            description: 'Add User',
          },
          {
            name: PermissionAction.Invite,
            description: 'Invite Users',
          },
        ],
        children: [
          {
            name: 'User',
            segment: '?',
            allowsCondition: true,
            actions: [
              {
                name: PermissionAction.Read,
                description: 'Read User',
              },
              {
                name: PermissionAction.Update,
                description: 'Update User',
              },
              {
                name: PermissionAction.Delete,
                description: 'Delete User',
              },
            ],
            children: [
              {
                name: 'Permissions',
                segment: 'permissions',
                allowsCondition: false,
                actions: [
                  {
                    name: PermissionAction.Read,
                    description: 'Read User Permissions',
                  },
                ],
                children: [],
              },
            ],
          },
        ],
      },
      {
        name: 'Companies',
        segment: 'companies',
        allowsCondition: true,
        actions: [
          {
            name: PermissionAction.List,
            description: 'List Companies',
          },
          {
            name: PermissionAction.Add,
            description: 'Add Company',
          },
        ],
        children: [
          {
            name: 'Company',
            segment: '?',
            allowsCondition: true,
            actions: [
              {
                name: PermissionAction.Read,
                description: 'Read Company',
              },
              {
                name: PermissionAction.Update,
                description: 'Update Company',
              },
              {
                name: PermissionAction.Delete,
                description: 'Delete Company',
              },
            ],
            children: [],
          },
        ],
      },
      {
        name: 'Projects',
        segment: 'projects',
        allowsCondition: false,
        actions: [
          {
            name: PermissionAction.List,
            description: 'List Projects',
          },
        ],
        children: [
          {
            name: 'Project',
            segment: '?',
            allowsCondition: false,
            actions: [
              {
                name: PermissionAction.Read,
                description: 'Read Project',
              },
              {
                name: PermissionAction.Retrieve,
                description: 'Retrieve Offline Content',
              },
            ],
            children: [
              {
                name: 'Structure',
                segment: 'structure',
                allowsCondition: false,
                actions: [
                  {
                    name: PermissionAction.Read,
                    description: 'Read Structure',
                  },
                ],
                children: [],
              },
              {
                name: 'Collections',
                segment: 'collections',
                allowsCondition: false,
                actions: [
                  {
                    name: PermissionAction.List,
                    description: 'List Collections',
                  },
                ],
                children: [
                  {
                    name: 'Collection',
                    segment: '?',
                    allowsCondition: false,
                    actions: [
                      {
                        name: PermissionAction.Read,
                        description: 'Read Collection Definition',
                      },
                    ],
                    children: [
                      {
                        name: 'CollectionItems',
                        segment: 'items',
                        allowsCondition: true,
                        actions: [
                          {
                            name: PermissionAction.List,
                            description: 'List Collection Items',
                          },
                        ],
                        children: [
                          {
                            name: 'CollectionItem',
                            segment: '?',
                            allowsCondition: true,
                            actions: [
                              {
                                name: PermissionAction.Read,
                                description: 'Read Collection Item',
                              },
                            ],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Views',
                segment: 'views',
                allowsCondition: true,
                actions: [
                  {
                    name: PermissionAction.List,
                    description: 'List Views',
                  },
                ],
                children: [
                  {
                    name: 'View',
                    segment: '?',
                    allowsCondition: true,
                    actions: [
                      {
                        name: PermissionAction.Read,
                        description: 'Read View',
                      },
                    ],
                    children: [],
                  },
                ],
              },
              {
                name: 'Pages',
                segment: 'pages',
                allowsCondition: true,
                actions: [
                  {
                    name: PermissionAction.List,
                    description: 'List Pages',
                  },
                ],
                children: [
                  {
                    name: 'Page',
                    segment: '?',
                    allowsCondition: true,
                    actions: [
                      {
                        name: PermissionAction.Read,
                        description: 'Read Page',
                      },
                    ],
                    children: [],
                  },
                ],
              },
              {
                name: 'Forms',
                segment: 'forms',
                allowsCondition: true,
                actions: [
                  {
                    name: PermissionAction.List,
                    description: 'List Forms',
                  },
                ],
                children: [
                  {
                    name: 'Form',
                    segment: '?',
                    allowsCondition: true,
                    actions: [
                      {
                        name: PermissionAction.Read,
                        description: 'Read Form',
                      },
                    ],
                    children: [],
                  },
                ],
              },
              {
                name: 'Form Data Repositories',
                segment: 'buckets',
                allowsCondition: false,
                actions: [
                  {
                    name: PermissionAction.List,
                    description: 'List Form Data Repositories',
                  },
                ],
                children: [
                  {
                    name: 'Form Data Repository',
                    segment: '?',
                    allowsCondition: false,
                    actions: [
                      {
                        name: PermissionAction.Read,
                        description: 'Read Form Data Repository Schema',
                      },
                    ],
                    children: [
                      {
                        name: 'Form Data Repository Documents',
                        segment: 'docs',
                        allowsCondition: true,
                        actions: [
                          {
                            name: PermissionAction.List,
                            description: 'List Form Data Repository Documents',
                          },
                          {
                            name: PermissionAction.Add,
                            description: 'Add Form Data Repository Document',
                          },
                        ],
                        children: [
                          {
                            name: 'Form Data Repository Document',
                            segment: '?',
                            allowsCondition: true,
                            actions: [
                              {
                                name: PermissionAction.Read,
                                description: 'Read Form Data Repository Document',
                              },
                              {
                                name: PermissionAction.Update,
                                description: 'Update Form Data Repository Document',
                              },
                            ],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Form Data Transaction Log',
                segment: 'bucketlog',
                allowsCondition: false,
                actions: [
                  {
                    name: PermissionAction.List,
                    description: 'Query Form Data Transaction Log',
                  },
                ],
                children: [
                  {
                    name: 'Form Data Transaction Log Document',
                    segment: '?',
                    allowsCondition: false,
                    actions: [
                      {
                        name: PermissionAction.Read,
                        description: 'Read Document Revision from Form Data Transaction Log',
                      },
                    ],
                    children: [],
                  },
                ],
              },
              {
                name: 'External Data Sources',
                segment: 'external-data-sources',
                allowsCondition: false,
                actions: [],
                children: [
                  {
                    name: 'External Data Source',
                    segment: '?',
                    allowsCondition: false,
                    actions: [
                      {
                        name: PermissionAction.Read,
                        description: 'Execute Data Source',
                      },
                    ],
                    children: [],
                  },
                ],
              },
              {
                name: 'Tables',
                segment: 'tables',
                allowsCondition: false,
                actions: [
                  {
                    name: PermissionAction.List,
                    description: 'List Tables',
                  },
                ],
                children: [
                  {
                    name: 'Table',
                    segment: '?',
                    allowsCondition: false,
                    actions: [
                      {
                        name: PermissionAction.Read,
                        description: 'Read Table',
                      },
                    ],
                    children: [],
                  },
                ],
              },
              {
                name: 'External Data Transactions',
                segment: 'external-data-transactions',
                allowsCondition: false,
                actions: [],
                children: [
                    {
                        name: 'External Data Transaction',
                        segment: '?',
                        allowsCondition: false,
                        actions: [
                            {
                                name: PermissionAction.Read,
                                description: 'Execute Data Transaction',
                            },
                        ],
                        children: [],
                    },
                ],
            },
            ],
          },
        ],
      },
      {
        name: 'Ticket Providers',
        segment: 'ticket-providers',
        allowsCondition: false,
        actions: [],
        children: [
          {
            name: 'Ticket Provider Integration',
            segment: '?',
            allowsCondition: false,
            actions: [],
            children: [
              {
                name: 'Ticket Provider',
                segment: '?',
                allowsCondition: false,
                actions: [
                  {
                    name: PermissionAction.Read,
                    description: 'Get a ticket',
                  },
                ],
                children: [],
              },
            ],
          },
        ],
      },
      {
        name: 'Me',
        segment: 'me',
        allowsCondition: false,
        actions: [
          {
            name: PermissionAction.Read,
            description: 'Read me',
          },
          {
            name: PermissionAction.Update,
            description: 'Update me',
          },
        ],
        children: [],
      },
      {
        name: 'Units',
        segment: 'units',
        allowsCondition: false,
        actions: [
          {
            name: PermissionAction.List,
            description: 'List Units',
          },
        ],
        children: [
          {
            name: 'Unit',
            segment: '?',
            allowsCondition: false,
            actions: [
              {
                name: PermissionAction.Read,
                description: 'Read Unit',
              },
            ],
            children: [],
          },
        ],
      },
    ],
};
