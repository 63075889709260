<uf-panel class="container" ufMasterDetail>
    <div *ngIf="!busy" class="uf-container-md pad-lg-top pad-sides">

        <a *ngIf="activeSignIn" [routerLink]="[activeSignIn.id]" class="uf-card centered gap-lg-bottom">
            <div class="card-info">
                <uf-icon name="sign-out" />
            </div>
            <div class="pad">
                <h4 class="gap-none">{{discoverTK.SignInSignOutButtonLabel | translate }}</h4>
                <p class="small gap-none-bottom">
                    <b>{{ activeSignIn.location }}</b><br>
                    {{ discoverTK.SignInSignedInLabel | translate }}: {{ activeSignIn.signIn | dataDisplay }}<br>
                    {{ activeSignIn.signIn | dataDisplay }}
                </p>
            </div>
        </a>
        <a (click)="signIn()" class="uf-card centered">
            <div *ngIf="!activeSignIn" class="card-info">
                <uf-icon name="sign-in" class="large" />
            </div>
            <div class="pad">
                <h4 class="gap-none">{{discoverTK.SignInSignInButtonLabel | translate }}</h4>
            </div>
        </a>
        <!-- spacer -->
        <div class="pad-top"></div>

        <uf-message *ngIf="error" [content]="error.message" class="error" icon="error" />

        <h2 *ngIf="history.length">{{ discoverTK.SignInHistoryHeading | translate }} </h2>

        <a *ngFor="let item of history" [routerLink]="[item.id]" class="uf-card row gap-bottom pad center-all">
            <div [class.success]="item.signOut" [class.warning]="!item.signOut" class="uf-avatar gap-right">
                <uf-icon [name]="item.signOut ? 'tick' : 'warning'" />
            </div>
            <div class="grow">
                <p class="gap-none small">
                    <ng-container *ngIf="item.title">
                        <b>{{ item.title }}</b><br>
                    </ng-container>
                    <ng-container *ngIf="!item.multiday; else multiday">
                        {{ item.signIn | dataDisplay }}<br>
                        {{ item.signIn | dataDisplay }} - <span *ngIf="item.signOut; else noSignOutMessage">{{
                            item.signOut | dataDisplay }}</span>
                    </ng-container>

                    <ng-template #noSignOutMessage>
                        {{ discoverTK.SignInSignOutPendingMessage | translate }}
                    </ng-template>
                    <ng-template #multiday>
                        {{ item.signIn | dataDisplay }}<br>
                        {{ item.signOut | dataDisplay }}<br>
                    </ng-template>
                </p>
            </div>
        </a>


    </div>
</uf-panel>
<router-outlet />