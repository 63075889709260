<div ufMasterDetail class="table-page bg-solid">
    <uf-table-container (addItem)="add($event)" (dataSourceChange)="updateCount($event)" ufSyncRoute
        class="accent list-md">
        <ng-container title>
            <uf-breadcrumbs *ngIf="breadcrumbs.length > 1" [breadcrumbs]="breadcrumbs" [minimize]="true"
                class="small left" />
        </ng-container>
        <div *ngIf="showRecordCount" info class="title">
            <ng-container *ngIf="recordCount != null; else errorMessage">
                {{recordCount}} Results
            </ng-container>
            <ng-template #errorMessage>
                {{discoverTK.RecordCountErrorMessage | translate}}
            </ng-template>
        </div>
    </uf-table-container>
</div>
<router-outlet />