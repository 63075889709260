import { AfterContentInit, Component, ContentChild, DestroyRef, ElementRef, HostBinding, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { WindowResizeEventHandler, WindowResizeInfo } from '@unifii/library/common';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { DrawerComponent } from './drawer.component';
import { HeaderComponent } from './header.component';
import { MenuDrawerComponent } from './menu-drawer.component';
import { ShellService } from './shell.service';

@Component({
    selector: 'us-shell',
    templateUrl: './shell.html',
    styleUrls: ['./shell.less'],
})
export class ShellComponent implements AfterContentInit, OnDestroy {

    @ContentChild('left', { read: DrawerComponent, static: false }) leftDrawer: DrawerComponent;
    @ContentChild('right', { read: DrawerComponent, static: false }) rightDrawer: DrawerComponent;
    @ContentChild('menu', { read: MenuDrawerComponent, static: false }) menuDrawer: MenuDrawerComponent;
    @ContentChild('header', { read: HeaderComponent, static: false }) header: HeaderComponent;

    title: string;
    imageUrl: string;
    desktopWidth = 1440;

    @HostBinding('class.open-menu') get openMenuClass() {
        return this.menuDrawer && this.menuDrawer.open;
    }

    private subscriptions = new Subscription();
    private prevWidth = 0;

    constructor(
        router: Router,
        shellService: ShellService,
        private windowResizeEventHandler: WindowResizeEventHandler,
        private elementRef: ElementRef,
        private destroy: DestroyRef,
    ) {

        shellService.register(this);

        this.subscriptions.add(router.events
            .pipe(filter((e) => e instanceof NavigationStart))
            .subscribe(() => {

                shellService.closeRightDrawer();
                shellService.closeLeftDrawer();

                if (this.prevWidth < this.desktopWidth) {
                    shellService.closeMenuDrawer();
                }
            }));
    }

    ngAfterContentInit() {
        if (this.menuDrawer != null) {
            this.windowResizeEventHandler.register({
                listener: this.onResize.bind(this),
                reference: this.elementRef,
                destroy: this.destroy,
                fireOnRegister: true,
            });
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    private onResize = (res: WindowResizeInfo) => {

        if (!res.elementWidth) {
            return;
        }

        const close = res.elementWidth < this.desktopWidth;

        // Getting Smaller
        if (this.prevWidth > res.elementWidth && close) {
            this.menuDrawer.open = false;
        }

        // Getting larger
        if (this.prevWidth < res.elementWidth && !close) {
            this.menuDrawer.open = true;
        }

        this.prevWidth = res.elementWidth;
    };

}
