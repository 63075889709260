<ng-template [ngIf]="error == null" [ngIfElse]="onError">
  <div class="content">
    <ud-loading-bar [progress]="progress" />
    <p>{{discoverTK.UpdateProgressMessage | translate: {version: version} }}</p>
  </div>
</ng-template>

<ng-template #onError>
  <div *ngIf="error?.code" class="header">
    <h3>{{error?.code}}</h3>
  </div>
  <div class="content">
    <div [markdownRenderer]="error?.message"></div>
  </div>
  <div class="btns">
    <button (click)="close()" type="button" class="uf-button tertiary right">
      {{sharedTermsTK.ActionClose | translate}}
    </button>
    <button (click)="update()" type="button" class="uf-button primary right">
      {{sharedTermsTK.ActionRetry | translate}}
    </button>
  </div>
</ng-template>