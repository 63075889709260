<div class="shell-body">
    <ng-content select="us-header"></ng-content>
    <div [class.push-down]="header" class="shell-content">
        <ng-content></ng-content>
    </div>
</div>
<ng-content select="us-menu-drawer"></ng-content>
<ng-content select="us-drawer"></ng-content>
<ng-content select="us-drawer[right]"></ng-content>

<us-icons />