import { Injectable, Type } from '@angular/core';
import { ComponentRegistryOptions, ContentField } from '@unifii/library/smart-forms';
import { UfPageComponentRegistry } from '@unifii/library/smart-forms/display';
import { FieldType } from '@unifii/sdk';

import { LinkListComponent } from './link-list.component';
import { LinkComponent } from './link.component';

@Injectable()
export class UsPageComponentRegistry extends UfPageComponentRegistry {

    override get(type: FieldType, options?: ComponentRegistryOptions): Type<ContentField> | null {
        switch (type) {
            case FieldType.Link:
            case FieldType.DefinitionLink:
                return LinkComponent;

            case FieldType.LinkList:
                return LinkListComponent;

            default:
                return super.get(type, options);
        }
    }

}
