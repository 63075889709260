import { Injectable, inject } from '@angular/core';
import { Repository } from '@unifii/library/common';
import { Company, ProjectInfo } from '@unifii/sdk';

import { Environment } from 'config';
import { ProjectPin } from 'discover/discover-model';
import { LanguageKey } from 'shell/services/translations.service';

const ProjectKey = 'DiscoverProject';
const PreviewKey = 'DiscoverPreview';
const PinKey = 'DiscoverPins';
const CompanyKey = 'DiscoverCompany';

/**
 * Context service holds references to some essential discover values that are mutable.
 */
@Injectable({ providedIn: 'root' })
export class DiscoverContext {

    private repository = inject(Repository);
    private config = inject(Environment);

    get preview(): boolean {
        return this.repository.load(PreviewKey) || false;
    }

    set preview(v: boolean) {
        this.repository.store(PreviewKey, v);
    }

    // TODO does this belong here or in config
    get project(): ProjectInfo | null {
        return this.repository.load(ProjectKey);
    }

    set project(v: ProjectInfo | null) {
        this.repository.store(ProjectKey, v);
    }

    get company(): Company | null {
        return this.repository.load(CompanyKey);
    }

    set company(v: Company | null) {
        this.repository.store(CompanyKey, v);
    }

    get language(): string | undefined {
        return this.repository.load(LanguageKey) ?? undefined;
    }

    set language(v: string | undefined) {
        this.repository.store(LanguageKey, v);
    }

    get pins(): ProjectPin[] {
        return this.repository.load(PinKey) ?? [];
    }

    set pins(v: ProjectPin[]) {
        this.repository.store(PinKey, v);
    }

    clear() {
        this.preview = !!this.config.unifii.preview;
        this.project = null;
        this.company = null;
        // pins not reset on clear as are persistent across authentications
    }

}
