import { InjectionToken } from '@angular/core';

/**
 * Defines an init step that runs during on App Init, set up your providers with multi:true
 */
export interface InitStep {
    run(): void;
}

export const InitStep = new InjectionToken<InitStep>('InitStep');
