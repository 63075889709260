import { Component, Input } from '@angular/core';
import { RuntimeField } from '@unifii/library/common';
import { ContentField } from '@unifii/library/smart-forms';
import { FieldTemplate } from '@unifii/sdk';

@Component({
    selector: 'ud-file-list',
    templateUrl: './file-list.html',
})
export class FileListDisplayComponent implements ContentField {

    @Input({ required: true }) content: any[];
    @Input({ required: true }) field: RuntimeField;

    readonly fieldTemplate = FieldTemplate;

}
