import { ErrorHandler, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '@unifii/components';
import { BarcodeScannerModalProvider, ContextProvider, DataDisplayComponentRegistry, DataDisplayService, DataDisplayTableComponentRegistry, FeatureFlagProvider, UfDataDisplayContentTableComponentRegistry } from '@unifii/library/common';
import { CollectionItemComponentRegistry, DisplayModule, PageComponentRegistry, ViewComponentRegistry } from '@unifii/library/smart-forms/display';
import { InputModule } from '@unifii/library/smart-forms/input';

import { Config } from 'config';
import { ChartComponent } from 'shell/common/chart/chart.component';
import { DataDisplayPdfViewerComponent } from 'shell/common/data-display/data-display-pdf-viewer.component';
import { ShellDataDisplayComponentRegistry } from 'shell/common/data-display/shell-data-display-component-registry';
import { EmptyModuleMessageComponent } from 'shell/common/empty-module-message/empty-module-message.component';
import { IconMessageComponent } from 'shell/common/icon-message.component';
import { LoadingSpinnerComponent } from 'shell/common/loading-spinner.component';
import { UsBarcodeScannerModalComponent, UsBarcodeScannerModalProvider } from 'shell/common/modals/barcode-scanner-modal.component';
import { ConfirmExtendedModalComponent } from 'shell/common/modals/confirm-extended-modal.component';
import { PdfViewerModalComponent } from 'shell/common/modals/pdf-viewer-modal.component';
import { PillComponent } from 'shell/common/pill.component';
import { SectionAccordionComponent } from 'shell/common/section-accordion.component';
import { UsCollectionItemComponentRegistry } from 'shell/content/collection-item-component-registry';
import { CollectionItemComponent } from 'shell/content/collection-item.component';
import { CollectionComponent } from 'shell/content/collection.component';
import { ContentComponentFactory } from 'shell/content/content-component-factory';
import { ContentComponentSelector, ShellContentComponentSelector } from 'shell/content/content-component-selector';
import { ContentDataResolver, ShellContentDataResolver } from 'shell/content/content-data-resolver';
import { ContentNodeComponent } from 'shell/content/content-node.component';
import { IFrameComponent } from 'shell/content/iframe.component';
import { LinkListComponent } from 'shell/content/link-list.component';
import { LinkComponent } from 'shell/content/link.component';
import { UsPageComponentRegistry } from 'shell/content/page-component-registry';
import { PageComponent } from 'shell/content/page.component';
import { PdfViewerNodeComponent } from 'shell/content/pdf-viewer-node.component';
import { PdfViewerComponent } from 'shell/content/pdf-viewer.component';
import { ShellContentComponentFactory } from 'shell/content/shell-content-component-factory';
import { UsViewComponentRegistry } from 'shell/content/view-component-registry';
import { ViewComponent } from 'shell/content/view.component';
import { DrawerComponent } from 'shell/core/drawer.component';
import { HeaderComponent } from 'shell/core/header.component';
import { IconsComponent } from 'shell/core/icons.component';
import { MenuDrawerComponent } from 'shell/core/menu-drawer.component';
import { ShellComponent } from 'shell/core/shell.component';
import { TitleConfig } from 'shell/core/title-config';
import { DashboardPageComponent } from 'shell/dashboard/dashboard-page.component';
import { DashboardTableComponent } from 'shell/dashboard/dashboard-table.component';
import { DashboardComponent } from 'shell/dashboard/dashboard.component';
import { ShellFeatureFlagDirective } from 'shell/directives/feature-flag.directive';
import { MasterDetailDirective } from 'shell/directives/master-detail.directive';
import { PdfViewerLinkDirective } from 'shell/directives/pdf-viewer-link.directive';
import { ConflictModalComponent } from 'shell/form/conflict-modal.component';
import { FormComponent } from 'shell/form/form.component';
import { ErrorMessageComponent } from 'shell/nav/error-message.component';
import { NavItemComponent } from 'shell/nav/menu/nav-item.component';
import { NavComponent } from 'shell/nav/menu/nav.component';
import { NodeComponent } from 'shell/nav/node.component';
import { ContentDb, IndexedDbWrapper, TenantDb } from 'shell/offline/indexeddb-wrapper';
import { SentryErrorHandler } from 'shell/sentry-error-handler';
import { ShellDataDisplayService } from 'shell/services/data-display.service';
import { ShellContextProvider } from 'shell/services/shell-context-provider';
import { ShellFeatureFlagProvider } from 'shell/services/shell-feature-flag-provider';
import { AppTitle } from 'shell/shell-constants';
import { TablePageComponent } from 'shell/table/table-page.component';
import { TableDetailPageComponent } from 'shell/table-detail/table-detail-page.component';
import { TableDetailComponent } from 'shell/table-detail/table-detail.component';
import { TableModuleComponent } from 'shell/table-detail/table-module.component';
import { ChangeLanguageComponent } from 'shell/translations/change-language.component';

const createTitleConfig = (config: Config): TitleConfig => {
    const prefix = config.env === 'prod' ? '' : config.env.toUpperCase();

    return {
        appTitle: AppTitle, // TODO: Add to environment.json
        prefix,
    };
};

/**
 * This module will contain all the Unifii Framework components and will become
 * the basis for all future apps built on Unifii
 *
 * If you need to work on this, make sure
 */
@NgModule({
    imports: [
        RouterModule, InputModule, DisplayModule, ComponentsModule,
    ],
    declarations: [
        ShellComponent, DrawerComponent, MenuDrawerComponent, HeaderComponent, IconsComponent,
        NavComponent, NavItemComponent, NodeComponent,
        IconMessageComponent, ErrorMessageComponent, LoadingSpinnerComponent,
        ChartComponent, ConfirmExtendedModalComponent,
        ChangeLanguageComponent, PdfViewerModalComponent,
        UsBarcodeScannerModalComponent,
        MasterDetailDirective, ShellFeatureFlagDirective, PdfViewerLinkDirective,
        LinkListComponent, LinkComponent, CollectionComponent, CollectionItemComponent, ContentNodeComponent,
        PageComponent, ViewComponent, IFrameComponent, PdfViewerComponent, PdfViewerNodeComponent,
        FormComponent, ConflictModalComponent,
        SectionAccordionComponent, PillComponent,
        DashboardComponent, DashboardPageComponent, DashboardTableComponent, TablePageComponent,
        TableDetailComponent, TableModuleComponent, TableDetailPageComponent,
        EmptyModuleMessageComponent, DataDisplayPdfViewerComponent,
    ],
    exports: [
        RouterModule, InputModule, DisplayModule, ComponentsModule,
        ShellComponent, DrawerComponent, MenuDrawerComponent, HeaderComponent, IconsComponent,
        IconMessageComponent, ErrorMessageComponent, LoadingSpinnerComponent,
        ChartComponent, ConfirmExtendedModalComponent,
        ChangeLanguageComponent, PdfViewerComponent, PdfViewerModalComponent,
        UsBarcodeScannerModalComponent,
        MasterDetailDirective, ShellFeatureFlagDirective, PdfViewerLinkDirective,
        NavComponent, NavItemComponent,
        PillComponent,
    ],
    providers: [
        { provide: ErrorHandler, useClass: SentryErrorHandler },
        { provide: ContextProvider, useClass: ShellContextProvider },
        // Data Display
        { provide: DataDisplayService, useClass: ShellDataDisplayService },
        { provide: DataDisplayComponentRegistry, useClass: ShellDataDisplayComponentRegistry },
        { provide: DataDisplayTableComponentRegistry, useClass: UfDataDisplayContentTableComponentRegistry },
        // Content
        { provide: ViewComponentRegistry, useClass: UsViewComponentRegistry },
        { provide: PageComponentRegistry, useClass: UsPageComponentRegistry },
        { provide: CollectionItemComponentRegistry, useClass: UsCollectionItemComponentRegistry },
        { provide: ContentComponentFactory, useClass: ShellContentComponentFactory },
        { provide: ContentDataResolver, useClass: ShellContentDataResolver },
        // Offline Content
        { provide: TenantDb, useClass: IndexedDbWrapper },
        { provide: ContentDb, useClass: IndexedDbWrapper },
        // Forms
        { provide: BarcodeScannerModalProvider, useValue: UsBarcodeScannerModalProvider },
        // Title Service
        { provide: TitleConfig, useFactory: createTitleConfig, deps: [Config] },
        // Bucket
        { provide: ContentComponentSelector, useClass: ShellContentComponentSelector },
        { provide: FeatureFlagProvider, useClass: ShellFeatureFlagProvider },
    ],
})
export class ShellModule { }
