import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { ShellFeatureFlagService } from 'shell/services/shell-feature-flag.service';
import { ShellFeatureFlagKey } from 'shell/shell-model';

@Directive({
    selector: '[usFeatureFlag]',
})
export class ShellFeatureFlagDirective implements OnInit {

    @Input() usFeatureFlag: ShellFeatureFlagKey;

    constructor(
        private vcr: ViewContainerRef,
        private tpl: TemplateRef<any>,
        private service: ShellFeatureFlagService,
    ) { }

    async ngOnInit() {
        if (await this.service.isEnabled(this.usFeatureFlag)) {
            this.vcr.createEmbeddedView(this.tpl);
        }
    }

}
