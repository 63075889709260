import { Inject, Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '@unifii/library/common';
import { UserInfo } from '@unifii/sdk';
import { EMPTY, Observable, Subscription, fromEvent, merge, timer } from 'rxjs';
import { filter } from 'rxjs/operators';

import { DeviceService } from 'capacitor/device.service';
import { Config } from 'config';
import { DiscoverContext } from 'discover/discover-context';
import { ProjectPin, ProjectPinDetails } from 'discover/discover-model';
import { DiscoverTranslationKey } from 'discover/discover.tk';
import { PasswordCheckComponent } from 'discover/pin/password-check.component';
import { PinCheckComponent } from 'discover/pin/pin-check.component';
import { PinSetupComponent } from 'discover/pin/pin-setup.component';
import { PinFingerPrintConfig, PinModalData, PinService, PinState, PinTimeoutOption } from 'discover/pin/pin-types';
import { Authentication } from 'shell/services/authentication';
import { ShellFeatureFlagService } from 'shell/services/shell-feature-flag.service';

@Injectable()
export class DiscoverPinService implements PinService, OnDestroy {

    readonly timeoutOptions: PinTimeoutOption[];

    passwordCheckComponent = PasswordCheckComponent;
    pinCheckComponent = PinCheckComponent;
    pinSetupComponent = PinSetupComponent;

    fingerprintConfig: PinFingerPrintConfig = {
        clientId: 'Unifii Login',
        clientSecret: 'unifiiFingerPrint',
        disableBackup: true,
    };

    private subscriptions = new Subscription();
    private pinScreenOpen: boolean;
    private _isEnabled: boolean;

    constructor(
        @Inject(Config) private config: Config,
        @Inject(Authentication) private auth: Authentication,
        private context: DiscoverContext,
        private device: DeviceService,
        private modalService: ModalService,
        private featureFlagService: ShellFeatureFlagService,
        private translate: TranslateService,
    ) {
        this.timeoutOptions = [{
            name: this.translate.instant(DiscoverTranslationKey.PINTimeoutImmediately),
            id: 1,
        }, {
            name: this.translate.instant(DiscoverTranslationKey.PINTimeout10Seconds),
            id: 10000,
            default: true,
        }, {
            name: this.translate.instant(DiscoverTranslationKey.PINTimeout1Minute),
            id: 60000,
        }, {
            name: this.translate.instant(DiscoverTranslationKey.PINTimeout5Minutes),
            id: 300000,
        }, {
            name: this.translate.instant(DiscoverTranslationKey.PINTimeout10Minutes),
            id: 600000,
        }, {
            name: this.translate.instant(DiscoverTranslationKey.PINTimeout1Hour),
            id: 3600000,
        }, {
            name: this.translate.instant(DiscoverTranslationKey.PINTimeout1Day),
            id: 86400000,
        }];
    }

    isEnabled() {

        // Guard flag - enable on native & desktop with flag
        if (!this.device.isNative() && !this._isEnabled) {
            return false;
        }

        // Guard for project pin flag
        if (!this.context.project || !this.context.project.usesPinLock) {
            return false;
        }

        return true;
    }

    /** Pin need to be set or checked on application start and checked on device app resume */
    async init(): Promise<void> {

        this._isEnabled = await this.featureFlagService.isEnabled('desktopPin');

        if (!this._isEnabled) {
            return;
        }

        // Check or set pin on application start
        const pin = this.getPin();
        let success: boolean;

        if (!pin) {
            success = await this.showPinSetup({ message: this.translate.instant(DiscoverTranslationKey.PINConfigurationTitle) });
        } else {
            success = await this.showPinCheck();
        }

        // guard to stop pin trigger if not successful
        if (!success) {
            return;
        }

        // Check pin when needed
        this.subscriptions.add(
            this.checkPinTrigger().subscribe(() => {
                this.showPinCheck();
            }),
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    getPinDetails(): ProjectPinDetails {
        return this.context.pins.filter((pinInfo) =>
            pinInfo.tenent === this.config.unifii.tenant &&
            pinInfo.userId === (this.auth.userInfo as UserInfo).id,
        ).map((value) => ({
            tenent: value.tenent,
            userId: value.userId,
            fingerprint: value.fingerprint,
            timeout: value.timeout,
        })).pop() as ProjectPinDetails;
    }

    changeTimeout(ms: number) {

        const pin = this.getPin();

        // get all other pins
        const contextPins = this.context.pins.filter((val) => !(val.tenent === this.config.unifii.tenant && val.userId === (this.auth.userInfo as UserInfo).id));

        pin.timeout = ms;
        contextPins.push(pin);

        this.context.pins = contextPins;
    }

    /** Show PinCheck when conditions are met (foreground and timed out) */
    checkPinTrigger(): Observable<any> {

        // if desktop use desktop timeout
        if (this._isEnabled) {
           return this.desktopTimeout();
        }

        return this.device.resume.pipe(filter((millis) => {
            const pin = this.getPin();

            // Guard no user configured pin
            if (!pin) {
                return false;
            }

            // Guard for a short time
            if (millis < pin.timeout) {
                return false;
            }

            // Trigger a pin check
            return true;
        }));

    }

    async savePin(pin: string, fingerPrint: boolean): Promise<void> {
        // if pins already exist (resetting pin), remove them before adding new pin
        const contextPins = this.context.pins.filter((val) => !(val.tenent === this.config.unifii.tenant && val.userId === (this.auth.userInfo as UserInfo).id));

        const pinHash = await this.hashSha256(pin);

        // get default timeout option, or default to 10 seconds
        const defaultTimeout = this.timeoutOptions.find((o) => o.default)?.id ?? 10000;

        const newPin: ProjectPin = {
            pin: pinHash,
            tenent: this.config.unifii.tenant as string,
            fingerprint: fingerPrint,
            userId: (this.auth.userInfo as UserInfo).id as string,
            timeout: defaultTimeout,
        };

        contextPins.push(newPin);
        this.context.pins = contextPins;
    }

    async showPinReset(): Promise<boolean> {

        // already open
        if (this.pinScreenOpen) {
            return false;
        }

        this.pinScreenOpen = true;

        const result = await this.modalService.openFullScreen(
            PasswordCheckComponent,
            { closeAllowed: true },
        );

        this.pinScreenOpen = false;

        if (result !== PinState.Verified) {

            return false;
        }

        return this.showPinSetup({ message: this.translate.instant(DiscoverTranslationKey.PINResetTitle), closeAllowed: true });

    }

    clearPin(tenant: string, userId: string) {

        this.context.pins = this.context.pins.filter((val) =>
            !(val.tenent === tenant && val.userId === userId),
        );

    }

    async verifyPin(pin: string): Promise<boolean> {
        const pinHash = await this.hashSha256(pin);
        const pinResult = this.getPin();

        return pinHash === pinResult.pin;
    }

    private getPin(): ProjectPin {
        return this.context.pins.filter((pinInfo) =>
            pinInfo.tenent === this.config.unifii.tenant &&
            pinInfo.userId === (this.auth.userInfo as UserInfo).id,
        ).pop() as ProjectPin;
    }

    private async showPinCheck(): Promise<boolean> {
        // already open
        if (this.pinScreenOpen) {
            return false;
        }

        this.pinScreenOpen = true;

        const result = await this.modalService.openFullScreen(
            this.pinCheckComponent, undefined, { guard: true },
        );

        this.pinScreenOpen = false;

        if (result !== PinState.Verified) {
            return false;
        }

        return true;
    }

    private async showPinSetup(options: PinModalData): Promise<boolean> {
        // already open
        if (this.pinScreenOpen) {
            return false;
        }

        this.pinScreenOpen = true;

        const result = await this.modalService.openFullScreen(
            this.pinSetupComponent, options, { guard: true },
        );

        this.pinScreenOpen = false;

        if (result !== PinState.Verified) {
            return false;
        }

        return true;
    }

    private desktopTimeout(): Observable<any> {
        // Browser, use following logic
        return new Observable((observer) => {

            let timerSub: Subscription;

            // Generate timer
            const getTimer = () => {
                const pin = this.getPinDetails();

                // Guard no user configured pin
                if (!pin) {
                    return EMPTY;
                }

                return timer(pin.timeout);
            };

            // Timeout and emit
            const emitAndReset = () => {
                observer.next();
                timerSub = getTimer().subscribe(emitAndReset);
            };

            // Window blur event
            const blurEventSub = fromEvent(window, 'blur').subscribe(() => {
                timerSub.unsubscribe();
                observer.next();
                timerSub = getTimer().subscribe(emitAndReset);
            });

            // Reset timeout on user interaction
            const activeEventsSub = merge(
                fromEvent(document, 'mousemove'),
                fromEvent(document, 'keypress'),
                fromEvent(document, 'scroll'),
                fromEvent(document, 'click'),
                fromEvent(document, 'touchstart'),
                fromEvent(document, 'mousedown'),
            ).subscribe(() => {
                timerSub.unsubscribe();
                timerSub = getTimer().subscribe(emitAndReset);
            });

            // Kick off
            timerSub = getTimer().subscribe(emitAndReset);

            // Unsubscribe
            return () => {
                timerSub.unsubscribe();
                activeEventsSub.unsubscribe();
                blurEventSub.unsubscribe();
            };
        });
    }

    private async hashSha256(value: string): Promise<string> {
        const buf = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(value));

        return Array.prototype.map.call(new Uint8Array(buf), (x: number) => (('00' + x.toString(16)).slice(-2))).join('');
    }

}
