import { Inject, Injectable, Optional } from '@angular/core';
import { TableInputManager } from '@unifii/components';
import { ContextProvider, ExpressionParser, FilterAstNodeAdapter, FilterEntries, FilterEntry, FilterSerializer, FilterValue, HierarchyUnitProvider,
    normalizeAstNode } from '@unifii/library/common';
import { AstNode, Table, mergeAstNodes } from '@unifii/sdk';

/**
 * TableInputManagerFactory
 * Responsible for the creation of TableInputManger classes from a Table interface
 * and also provides a centralized place for merging static and context filters
 */
@Injectable()
export class TableInputManagerFactory {

    constructor(
        private contextProvider: ContextProvider,
        private expressionParser: ExpressionParser,
        @Optional() @Inject(FilterEntries) private entries: FilterEntry[] | undefined,
        @Inject(HierarchyUnitProvider) private hierarchyUnitProvider: HierarchyUnitProvider,
        @Optional() @Inject(FilterAstNodeAdapter) private filterAstNodeAdapter?: FilterAstNodeAdapter<FilterValue, FilterEntry>,
        @Optional() @Inject(FilterSerializer) private serializer?: FilterSerializer<FilterValue, FilterEntry>,
    ) { }

    create(table: Table, contextFilter?: AstNode): TableInputManager<FilterValue, FilterEntry> {

        const inputManager = new TableInputManager(this.entries ?? [], this.hierarchyUnitProvider, this.serializer ?? null, this.filterAstNodeAdapter ?? null);

        inputManager.staticFilterNode = normalizeAstNode(
            mergeAstNodes('and', table.filter, contextFilter),
            this.expressionParser,
            Object.assign({ self: null, root: {} }, this.contextProvider.get()),
        );

        return inputManager;
    }

}
