<div *ngIf="!tableNodeInfos && !error" @fade class="skeleton-container dashboard"></div>

<div *ngIf="error" class="uf-container-md">
    <uf-message [content]="error.message" icon="errorSolid" class="error padded gap-top">
        <h3>{{sharedTk.Error | translate}}</h3>
    </uf-message>
</div>

<uf-panel *ngIf="tableNodeInfos" class="container">
    <uf-tabs *ngIf="isMenuView" [responsive]="true" class="vertical">
        <ng-template [ngForOf]="tableNodeInfos" let-tableInfo ngFor>
            <uf-tab *ngIf="tableInfo.table.title" [label]="tableInfo.table.title" [path]="tableInfo.table.identifier" />
        </ng-template>
    </uf-tabs>
</uf-panel>

<us-dashboard *ngIf="!isMenuView && tableNodeInfos" [tableInfos]="tableNodeInfos" [pageMode]="pageMode"
    [emptyMessage]="emptyMessage" />