import { Inject } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { Repository } from '@unifii/library/common';
import { TenantSettings, Theme } from '@unifii/sdk';

import { DeviceService } from 'capacitor/device.service';
import { Config, Environment, UnifiiConfig } from 'config';
import { DiscoverContext } from 'discover/discover-context';

const TenantKey = 'DiscoverTenant';
const TenantSettingsKey = 'DiscoverTenantSettings';

/**
 * This class supplies options to SDK with late bound tenant, baseUrl, and projectId
 * This is necessary for Discover where baseUrl and projects are chosen during login
 *
 * You should not need to inject this.
 */
export class DiscoverOptions implements Config /* , ConnectionOptions, ContentOptions */ {

    private _unifii: UnifiiConfig;

    /**
     * config made public initial environment config can be accesses in login component
     * to hide company input when it is not needed
     */
    constructor(
        @Inject(Environment) private environment: Config,
        context: DiscoverContext,
        repo: Repository,
        device: DeviceService,
    ) {
        // Set preview mode to true if set from environment
        if (environment.unifii.preview) {
            context.preview = true;
        }
        this._unifii = new DiscoverUnifiiConfig(environment, context, repo, device);
    }

    get version() {
        return this.environment.version;
    }

    get productVersion() {
        return this.environment.productVersion;
    }

    get env() {
        return this.environment.env;
    }

    get sentryDSN() {
        return this.environment.sentryDSN;
    }

    // TODO a loop ?
    get useHashUrls(): boolean {
        return this.useHashUrls;
    }

    get unifii() {
        return this._unifii;
    }

    /**
     * @deprecated Theme replaced with themeConfig
     */
    get theme(): Theme | undefined {
        return this.environment.theme;
    }

    get themeConfig() {
        return this.environment.themeConfig ?? {};
    }

    get flags() {
        return this.environment.flags ?? {};
    }

}

export class DiscoverUnifiiConfig implements UnifiiConfig {

    /**
     * Config and Context declared as public to they can be accessed
     * when class is extended
     */
    constructor(
        public config: Config,
        private context: DiscoverContext, // TODO Should this depend on context?
        public repo: Repository, // public so additional info can added to repoistory by classes which extend DiscoverUnifiiConfig
        private device: DeviceService,
    ) {
        if (this.unifii.tenant != null) {
            this.tenant = this.unifii.tenant;
        }
    }

    get unifii(): UnifiiConfig {
        return this.config.unifii;
    }

    get tenant(): string | undefined {

        if (this.unifii.tenant != null) {
            return this.unifii.tenant;
        }

        return this.repo.loadString(TenantKey) ?? undefined;
    }

    set tenant(v: string | undefined) {
        this.repo.storeString(TenantKey, v ?? null);
    }

    get tenantSettings(): TenantSettings | undefined {
        return this.repo.load(TenantSettingsKey) ?? undefined;
    }

    set tenantSettings(v: TenantSettings | undefined) {
        this.repo.store(TenantSettingsKey, v);
    }

    // dynamically retrieve baseUrl
    get baseUrl(): string {
        return this.unifii.baseUrl.replace('{{tenant}}', this.tenant ?? '');
    }

    get appId(): string | null | undefined {
        return this.unifii.appId;
    }

    get appVersion(): string | undefined {
        return this.config.version;
    }

    get platform(): 'android' | 'ios' | 'web' {
        return this.device.getPlatform();
    }

    get appSecret(): string | null | undefined {
        return this.unifii.appSecret;
    }

    get apiKey(): string | null | undefined {
        return this.unifii.apiKey;
    }

    get apiSecret(): string | null | undefined {
        return this.unifii.apiSecret;
    }

    get projectId(): string {
        const projectId = this.unifii.projectId || (this.context.project ?? {} as any).id as string;

        if (!projectId) {
            this.logError('No Project ID - UNIFII-7675');
        }

        return projectId;
    }

    get offline(): boolean {
        return this.unifii.offline || !!this.context.project?.offline;
    }

    get maxImageWidth(): number | undefined {
        return this.unifii.maxImageWidth ?? this.context.project?.maxImageWidth;
    }

    get preview(): boolean {

        if (this.context.preview != null) {
            return this.context.preview;
        }

        return false;
    }

    get productLogo(): string | null {

        if (this.tenantSettings?.logo && !this.config.themeConfig?.disableTenantLogo) {
            return this.tenantSettings.logo.url;
        }

        return 'assets/svg/product-logo.svg';
    }

    get productLogoSecondary(): string | null {
        return 'assets/svg/product-logo-secondary-dark.svg';
    }

    get productBackground(): string {
        return 'assets/img/product-bg.jpg';
    }

    get projectLogoUrl(): string | undefined {

        // force product logo for branded apps
        if (this.config.themeConfig?.disableProjectLogo) {
            return 'assets/svg/product-logo-dark.svg';
        }

        if (this.context.project?.logo?.url) {
            return this.context.project.logo.url;
        }

        return this.unifii.projectLogoUrl;
    }

    get privacyPolicyUrl(): string | undefined {
        return this.unifii.privacyPolicyUrl;
    }

    get privacyPolicyLabelTk(): string | undefined {
        return this.unifii.privacyPolicyLabelTk;
    }

    get termsOfUseMessageTk(): string | undefined {
        return this.unifii.termsOfUseMessageTk;
    }

    get translationsUrl(): string {
        return this.unifii.translationsUrl;
    }

    get reportingApiUrl(): string | undefined {
        return this.config.unifii.reportingApiUrl;
    }

    // temporary UNIFII-7675
    private logError(message: string) {
        if (!Sentry.getCurrentHub().getClient()) {
            return;
        }

        Sentry.configureScope((scope) => {

            if (this.config.unifii.appId) {
                scope.setTag('app-id', this.config.unifii.appId);
            }

            if (this.config.productVersion) {
                scope.setTag('project-version', this.config.productVersion);
            }

            if (this.config.unifii.baseUrl && this.config.unifii.tenant) {
                scope.setTag('tenantUrl', this.config.unifii.baseUrl);
            }

            if (this.config.unifii.projectId) {
                scope.setTag('projectId', this.config.unifii.projectId);
            }

        });

        Sentry.captureException(message);
    }

}
