<uf-panel ufMasterDetail class="container">
    <div class="uf-container bg-solid">
        <div class="uf-app-bar transparent pad-sm-left">
            <uf-breadcrumbs [breadcrumbs]="breadcrumbs" [minimize]="true" class="small left" />
            <a *ngIf="itemLink" [routerLink]="itemLink.urlSegments" class="uf-button small primary right">
                {{ itemLink.name }}
            </a>
        </div>
        <div class="pad-top gap-bottom">
            <uf-data-display-group *ngFor="let group of groups" [group]="group" />
        </div>

        <us-empty-module-message
            *ngIf="showNoEmptyMessage && parent.pageMode === templateEnumVales.PageViewHideEmptyTables"
            [message]="this.emptyMessage" />

        <div *ngIf="tableModules.length && parent.pageMode !== templateEnumVales.MenuView" class="uf-grid">
            <ng-container *ngFor="let module of tableModules; let i = index">
                <us-table-module [module]="module" [item]="item" [pageMode]="parent.pageMode"
                    [detailContextProvider]="detailContextProvider" (hideChange)="updateHiddenTablesSet(module, $event)"
                    class="col-12 gap-sm-top module" />
            </ng-container>
        </div>
    </div>
</uf-panel>