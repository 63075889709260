import { Component, OnInit, inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ContextProvider, ExpressionParser } from '@unifii/library/common';
import { DataType, StructureNode, StructureNodeArg, TokenStorage, TokenStorageInterface, coerceDataToTarget } from '@unifii/sdk';

import { ErrorService } from 'shell/errors/error.service';
import { AppError } from 'shell/errors/errors';
import { NavigationService } from 'shell/nav/navigation.service';

@Component({
    selector: 'us-iframe',
    templateUrl: './iframe.html',
    styleUrls: ['./iframe.less'],
})
export class IFrameComponent implements OnInit {

    protected error: AppError;
    protected url: SafeResourceUrl | null;

    private navService = inject(NavigationService);
    private sanitizer = inject(DomSanitizer);
    private expressionParser = inject(ExpressionParser);
    private contextProvider = inject(ContextProvider);
    private tokenStorage = inject(TokenStorage) as TokenStorageInterface;
    private errorService = inject(ErrorService);

    ngOnInit() {
        try {
            this.url = this.createUrl(this.navService.current);
        } catch (error) {
            this.error = error as AppError;
        }
    }

    private createUrl(node: StructureNode | null): SafeResourceUrl {

        if (!node?.url) {
            throw this.errorService.createNotFoundError('URL');
        }

        let url = node.url;
        const params = this.createQueryParams(node.args);

        if (params) {
            url += `?${new URLSearchParams(params).toString()}`;
        }

        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    private createQueryParams(args: StructureNodeArg[] | undefined): Record<string, string> | undefined {
        const params = args?.reduce<Record<string, string>>((result, arg) => {

            let { value } = arg;

            if (arg.isExpression && value) {
                value = this.parseExpression(value);
            }

            if (value !== undefined) {
                result[arg.key] = value;
            }

            return result;
        }, {}) ?? {};

        return Object.keys(params).length ? params : undefined;
    }

    private parseExpression(value: string): string | undefined {
        // Convert expressions to values with app context
        const context = this.contextProvider.get();

        // Access token added to context
        context.accessToken = this.tokenStorage.token;

        const result = this.expressionParser.resolve(value, context as any);

        return coerceDataToTarget(result, { type: DataType.String }) ?? undefined;
    }

}
