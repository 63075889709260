import { animate, animateChild, group, query, state, style, transition, trigger } from '@angular/animations';

export const appear = trigger('appear', [
    state('closed', style({
        display: 'none',
    })),
    state('open', style({
        display: 'block',
    })),
    transition('closed => open', [
        group([
            animate('0s'),
            query('@fade', [
                animateChild(),
            ]),
            query('@slide', [
                animateChild(),
            ]),
        ]),
    ]),
    transition('open => closed', [
        group([
            animate('200ms'),
            query('@fade', [
                animateChild(),
            ]),
            query('@slide', [
                animateChild(),
            ]),
        ]),
    ]),
]);

export const fade = trigger('fade', [
    transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms ease', style({ opacity: 1 })),
    ]),
    transition(':leave', [
        animate('200ms ease', style({ opacity: 0 })),
    ]),
]);

export const slide = trigger('slide', [
    transition(':enter', [
        style({ transform: 'translate(360px, 0)' }),
        animate('200ms ease', style({ transform: 'translate(0, 0)' })),
    ]),
    transition(':leave', [
        animate('200ms ease', style({ transform: 'translate(360px, 0)' })),
    ]),
]);

export const grow = trigger('grow', [
    transition(':enter', [
        style({ opacity: 0, transition: 'ease', transform: 'scale(0)' }),
        animate('200ms', style({ opacity: 1, transform: 'scale(1)' })),
    ]),
    transition(':leave', [
        style({ opacity: 1, transition: 'ease', transform: 'scale(1)' }),
        animate('200ms', style({ opacity: 0, transform: 'scale(0)' })),
    ]),
]);

