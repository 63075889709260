<uf-panel class="container">
    <div class="uf-container-md">
        <div class="uf-grid condensed">

            <br>

            <div *ngIf="logo" class="col-12 gap-lg-top gap-sides">
                <img [src]="logo" alt="{{logo}}" class="logo" />
            </div>

            <div *ngIf="description" [markdownRenderer]="description" class="col-12 gap-sides body-copy">
            </div>
        </div>

        <div class="uf-grid directory-template row-gap-lg">
            <ng-container *ngFor="let card of cards">
                <a *ngIf="isCardHref(card)" [attr.href]="card.href" ufTarget="_blank" class="uf-card centered">
                    <ng-container *ngTemplateOutlet="cardTemplate; context: {card}" />
                </a>

                <a *ngIf="isCardLink(card)" [routerLink]="card.link" class="uf-card centered">
                    <ng-container *ngTemplateOutlet="cardTemplate; context: {card}" />
                </a>

                <a *ngIf="isCardLogout(card)" (click)="logout()" class="uf-card centered">
                    <ng-container *ngTemplateOutlet="cardTemplate; context: {card}" />
                </a>
            </ng-container>
        </div>

    </div>

</uf-panel>

<ng-template #cardTemplate let-card="card">
    <div class="uf-img-16x9">
        <img *ngIf="card.imgUrl" [src]="card.imgUrl" alt="Card Image">
    </div>
    <div class="uf-card-title">
        <h4 *ngIf="card.label">{{ card.label }}</h4>
    </div>
</ng-template>