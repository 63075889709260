<uf-panel class="container locked">
    <div class="enter-pin-screen">
        <div class="pin-input-wrapper grid--fixed">
            <div class="pin-input col-1of2">
                <span class="message"></span>
                <div [@shake]="error ? 'yes': 'no'" class="pin-hint">
                    <span class="title">{{discoverTK.PINPasswordCheckVerifyPasswordLabel | translate}}</span>
                </div>
                <form (ngSubmit)="checkPassword()">
                    <uf-password [control]="passwordForm.controls.password | asUfControl"
                        *ngIf="passwordForm.controls.password" name="password" />
                    <div class="error-text">{{error?.message}}</div>

                    <div class="row justify-center">
                        <button class="uf-button primary" type="submit">
                            {{sharedTermsTK.ActionNext | translate}}
                        </button>
                    </div>
                </form>
                <div class="close-buttons">
                    <span *ngIf="closeAllowed" (click)="close()">
                        {{sharedTermsTK.ActionBack | translate}}
                    </span>
                    <span (click)="logout()">
                        {{sharedTermsTK.ActionLogout | translate}}
                    </span>
                </div>
            </div>
        </div>
    </div>
</uf-panel>