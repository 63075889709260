import { Location } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationCancel, NavigationEnd, Router, RouterStateSnapshot } from '@angular/router';
import { ModalService } from '@unifii/library/common';
import { FieldHelperFunctions } from '@unifii/library/smart-forms';
import { ErrorType, PermissionAction, RouteInfo, Structure, StructureNode, StructureNodeType, UfError } from '@unifii/sdk';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Config } from 'config';
import { ErrorService } from 'shell/errors/error.service';
import { NodeComponent } from 'shell/nav/node.component';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { FormDataPath } from 'shell/shell-constants';

export interface StructureNodeAccessInfo {
    // User can access the structure node (based on roles and claims restrictions)
    matchNodeRules: boolean;
    // User has ACLs to this structure node
    matchACLs: boolean;
    // User has ACLs to one or more children of this node
    hasAccessibleChildren: boolean;
}

@Injectable({ providedIn: 'root' })
export class NavigationService {

    navigationEnd = new Subject<StructureNode | null>();

    private router = inject(Router);
    private errorService = inject(ErrorService);
    private modalService = inject(ModalService);
    private auth = inject(Authentication);
    private config = inject(Config);
    private location = inject(Location);
    private _structure: Structure | null = null;
    private _current: StructureNode | null = null;
    private _changes = new Subject<StructureNode | null>();
    private _parentMap = new WeakMap<StructureNode, StructureNode>();
    private _accessMap = new WeakMap<StructureNode, StructureNodeAccessInfo>();
    private previousUrl?: string;
    private previousEvent: NavigationCancel | NavigationEnd | undefined;

    /** Initializes router listener */
    init() {
        // Even though the node-guard.ts sets the node
        // it will not re run when the node component is reused
        // this will also handle root and aux routes
        // clears modals from previous page on navigation end (which has not been canceled)
        this.router.events.pipe(
            filter((e): e is NavigationEnd | NavigationCancel => (e instanceof NavigationEnd || e instanceof NavigationCancel)),
            ).subscribe((event) => {

                const currentUrl = event.url;

                this.current = this.getNodeFromSnapshot(this.router.routerState.snapshot);

                if (((!this.previousEvent || this.previousEvent instanceof NavigationCancel)
                    && event instanceof NavigationEnd)
                    || this.urlWithoutParameters(this.previousUrl) !== this.urlWithoutParameters(currentUrl)
                ) {
                    this.modalService.closeAll();
                }

                this.previousEvent = event;
                this.previousUrl = currentUrl;

                this.navigationEnd.next(this.current);
            });
    }

    /** routes the user back to the previous page, or home if true and structure node */
    back(home = false) {
        if (!this.previousUrl || (this.current && home)) {
            void this.router.navigateByUrl('/');

            return;
        }
        this.location.back();
    }

    get structure(): Structure | null {
        return this._structure;
    }

    set structure(s: Structure | null) {

        this._structure = s;

        this._parentMap = new WeakMap<StructureNode, StructureNode>();
        this._accessMap = new WeakMap<StructureNode, StructureNodeAccessInfo>();

        // Update parent's map
        this.buildParentMap(this._structure);

        if (this.auth.userInfo == null) {
            // Error type required so error would not display on login page
            throw new UfError('User not logged in', ErrorType.Unauthorized);
        }

        // Replace home page node with correct variation
        this.applyHomePageVariation(this._structure);

        for (const node of this.getDescendants(this._structure)) {
            // Check access for Roles, Claims and ACLs
            this._accessMap.set(node, this.checkNodeAccess(node));
        }

        // Verify descendants accessibility
        for (const node of this.getDescendants(this._structure)) {
            const accessInfo = this.getNodeAccessInfo(node) as StructureNodeAccessInfo;

            for (const descendant of this.getDescendants(node)) {

                // skip self
                if (descendant.nodeId === node.nodeId) {
                    continue;
                }

                const descendantAccessInfo = this.getNodeAccessInfo(descendant);

                if (descendantAccessInfo && descendantAccessInfo.matchACLs) {
                    accessInfo.hasAccessibleChildren = true;
                    break;
                }
            }
        }

        // Debug
        /*

        for (const node of this.getDescendants(this._structure)) {
            const info = this.getNodeAccessInfo(node) as StructureNodeAccessInfo;
            console.log(node.name, info.canAccess, info.hasAccessibleChildren);
        }

        const nodes: StructureNode[] = [];
        nodes.sort((a, b) => parseInt(a.nodeId as string, undefined) > parseInt(b.nodeId as string, undefined) ? 1 : -1).forEach(node => {
            const accessInfo = this.getNodeAccessInfo(node) as StructureNodeAccessInfo;
            console.log(`${node.nodeId}: {A: ${accessInfo.canAccess} C: ${accessInfo.hasAccessibleChildren || false} | ${node.type} | ${node.name}`);
        });
        */
    }

    /** Points to current node */
    get current(): StructureNode | null {
        return this._current;
    }

    set current(n: StructureNode | null) {

        if (n === this._current) {
            return;
        }

        this._current = n;
        this._changes.next(n);
    }

    /**
     * Fires when current node changes
     */
    get changes(): Observable<StructureNode | null> {
        return this._changes;
    }

    getNodeAccessInfo(node: StructureNode): StructureNodeAccessInfo | undefined {
        return this._accessMap.get(node);
    }

    /**
     * Check if current user can access given node
     */
    canAccessNode(node: StructureNode): boolean {

        if (node == null) {
            throw this.errorService.createNotFoundError('node');
        }

        const info = this.getNodeAccessInfo(node);

        if (!info) {
            return false;
        }

        return info.matchACLs && info.matchNodeRules;
    }

    /** Finds node in structure based on nodeId */
    getNode(nodeId: string | null | undefined): StructureNode | null {

        for (const n of this.getDescendants(this.structure)) {
            if (n.nodeId === nodeId) {
                return n;
            }
        }

        return null;
    }

    /** Finds node in the structure based on a predicate */
    findNode(predicate: (n: StructureNode) => boolean): StructureNode | null {
        if (predicate == null) {
            throw this.errorService.createNotFoundError('predicate');
        }

        for (const n of this.getDescendants(this.structure)) {
            if (predicate(n)) {
                return n;
            }
        }

        return null;
    }

    findCustomNode(definitionIdentifier: string): StructureNode | null {
        return this.findNode((node) => node.type === StructureNodeType.Custom &&
            node.definitionIdentifier === definitionIdentifier);
    }

    /** Gets node parent or undefined */
    getParent(n: StructureNode): StructureNode | null {
        return this._parentMap.get(n) ?? null;
    }

    /** Gets ancestors, immediate first */
    *getAncestors(n: StructureNode): Iterable<StructureNode> {

        if (n == null) {
            return;
        }

        let parent = this.getParent(n);

        while (parent != null) {
            yield parent;
            parent = this.getParent(parent);

        }
    }

    *getDescendants(node: StructureNode | null): Iterable<StructureNode> {

        if (node == null) {
            return;
        }

        yield node;

        if (node.children == null) {
            return;
        }

        for (const n of node.children) {
            yield *this.getDescendants(n);
        }
    }

    /**
     * Detects if snapshot points to the root (aka home)
     */
    isRoot(snapshot: RouterStateSnapshot): boolean {
        if (snapshot == null) {
            throw this.errorService.createNotFoundError('snapshot');
        }

        if (Array.from(this.iterateSegments(snapshot.root)).length) {
            return false;
        }

        return true;
    }

    getSegments(snapshot: RouterStateSnapshot): string[] {
        if (snapshot == null) {
            throw this.errorService.createNotFoundError('snapshot');
        }

        return Array.from(this.iterateSegments(snapshot.root));
    }

    /** Returns router commands for given structure node */
    nodeToCommands(node: StructureNode): any[] | null {
        if (node == null || node.type === StructureNodeType.Empty || node.type === StructureNodeType.Link) {
            return null;
        }

        const prefix = ['/', 'n', node.nodeId];

        switch (node.type) {
            case StructureNodeType.Page:
            case StructureNodeType.View:
            case StructureNodeType.Collection:
            case StructureNodeType.Form:
            case StructureNodeType.FormBucket:
                return [...prefix, node.definitionIdentifier];
            case StructureNodeType.CollectionItem:
                return [...prefix, node.definitionIdentifier, (node.id as number).toString()];
            case StructureNodeType.Dashboard:
                return [...prefix, 'dashboard'];
            case StructureNodeType.IFrame:
                return [...prefix, 'iframe'];
            case StructureNodeType.PdfViewer:
                return [...prefix, 'pdf-viewer', (node.id as number).toString()];
            case StructureNodeType.Custom:
                return [...prefix, 'custom', node.definitionIdentifier];
            default:
                return null;
        }
    }

    /** Returns ready to use href link for given structure node */
    nodeToUrl(node: StructureNode): string | null {
        const commands = this.nodeToCommands(node);

        return commands == null ? null : commands.join('/');
    }

    /** Returns the url commands matching the routeInfo provided */
    routeInfoToCommands(info: RouteInfo = {}): any[] | null {

        // Missing mandatory information to route
        if (!info.tenant || !info.projectId) {
            return null;
        }

        // Structure node
        if (info.nodeId) {
            return ['/', 'n', info.nodeId];
        }

        // Form data
        if (info.bucket && info.id) {
            return ['/', FormDataPath, info.bucket, info.id];
        }

        // Form
        if (info.bucket && !info.id) {
            return ['/', info.bucket];
        }

        return null;
    }

    getCommandsFromSnapshot(snapshot: RouterStateSnapshot): any[] {
        const url = snapshot.url;
        const tree = this.router.parseUrl(url);
        const commands = ['/'];

        if (tree.root.children.primary) {
            commands.push(...tree.root.children.primary.segments.map((seg) => seg.path));
        }

        return commands;
    }

    linkify(url: string | null | undefined): string | null {
        if (!url) {
            return null;
        }

        if (url.startsWith('https://') || url.startsWith('http://')) {
            return url;
        }

        return 'http://' + url;
    }

    getNodeFromSnapshot(snapshot: RouterStateSnapshot): StructureNode | null {

        let curr: ActivatedRouteSnapshot | null = snapshot.root;

        while (curr != null) {
            if (curr.component === NodeComponent) {
                return this.getNode(curr.paramMap.get('nodeId'));
            }

            curr = curr.firstChild;
        }

        return this.isRoot(snapshot) ? this.structure : null;
    }

    private buildParentMap(n: StructureNode | null): void {
        if (n?.children == null) {
            return;
        }

        for (const ch of n.children) {
            this._parentMap.set(ch, n);

            this.buildParentMap(ch);
        }
    }

    private *iterateSegments(route: ActivatedRouteSnapshot | null): Iterable<string> {

        if (route == null) {
            return;
        }

        yield *route.url.map((s) => s.path);
        yield *this.iterateSegments(route.firstChild);
    }

    private applyHomePageVariation(structure: Structure | null) {

        const user = this.auth.userInfo;

        if (!structure || !user) {
            return;
        }

        const matchingVariation = structure.variations?.find((variation) => FieldHelperFunctions.areRolesMatching(user.roles, variation.roles));

        if (!matchingVariation) {
            return;
        }

        // Replace default with variation and return
        structure.name = matchingVariation.name;
        structure.type = matchingVariation.type;
        structure.definitionIdentifier = matchingVariation.definitionIdentifier;
        structure.definitionLabel = matchingVariation.definitionLabel;
        structure.id = matchingVariation.id;
        structure.roles = matchingVariation.roles;
        structure.bucketOptions = matchingVariation.bucketOptions;
        structure.tags = matchingVariation.tags;
    }

    private checkNodeAccess(node: StructureNode): StructureNodeAccessInfo {
        const result: StructureNodeAccessInfo = { matchNodeRules: true, matchACLs: false, hasAccessibleChildren: false };

        // console.log(`-------------------------- ${node.nodeId}:${node.type} - ${node.name} --------------------------`);

        // Check Roles restrictions
        const user = this.auth.userInfo;

        if (!user || !FieldHelperFunctions.areRolesMatching(user.roles, node.roles)) {
            // console.log(`Roles: ${JSON.stringify(node.roles)} not matched`);
            result.matchNodeRules = false;
        }

        const projectId = this.config.unifii.projectId;

        switch (node.type) {
            case StructureNodeType.Collection:
                result.matchACLs = this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getCollectionPath(projectId, node.definitionIdentifier as string), PermissionAction.Read).granted &&
                    this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getCollectionItemsPath(projectId, node.definitionIdentifier as string), PermissionAction.List).granted;
                break;

            case StructureNodeType.CollectionItem:
                result.matchACLs = this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getCollectionItemPath(projectId, node.definitionIdentifier as string, node.id as number + ''), PermissionAction.Read).granted;
                break;

                // Logic removed by UNIFII-4170
                /* case StructureNodeType.Dashboard:
                // Need one bucket accessible to allow access to the dashboard node
                for (const bo of node.bucketOptions as StructureNodeBucketOptions[]) {
                    const bucketNode = this.getNode(bo.nodeId);
                    if (bucketNode && this.auth.getGrantedInfo(PermissionsFunctions.getBucketPath(projectId, bucketNode.definitionIdentifier as string), PermissionAction.Read).granted &&
                        this.auth.getGrantedInfo(PermissionsFunctions.getBucketDocumentsPath(projectId, bucketNode.definitionIdentifier as string), PermissionAction.List).granted) {
                        result.matchACLs = true;
                    }
                }
                break;
            */

            case StructureNodeType.Form:
                result.matchACLs = this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getFormPath(projectId, node.definitionIdentifier as string), PermissionAction.Read).granted;
                break;

            case StructureNodeType.FormBucket:
                if (node.id == null) {
                    // Bucket Node (old structure model)
                    result.matchACLs = this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getBucketPath(projectId, node.definitionIdentifier as string), PermissionAction.Read).granted &&
                        this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getBucketDocumentsPath(projectId, node.definitionIdentifier as string), PermissionAction.List).granted;
                } else {
                    // Table Node (new structure model)
                    result.matchACLs = this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getTablePath(projectId, node.definitionIdentifier as string), PermissionAction.Read).granted;
                }
                break;

            case StructureNodeType.Page:
                result.matchACLs = this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getPagePath(projectId, node.id as number + ''), PermissionAction.Read).granted;
                break;

            case StructureNodeType.View:
                result.matchACLs = this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getViewPath(projectId, node.definitionIdentifier as string), PermissionAction.Read).granted;
                break;

            default:
                // console.log('ACL: not required');
                result.matchACLs = true;
                break;
        }

        return result;
    }

    private urlWithoutParameters(url?: string): string | undefined {
        return url?.match(/.+?(?=;)/)?.[0] ?? url;
    }

}
