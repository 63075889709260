import { Injectable, OnDestroy, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalService, SharedTermsTranslationKey } from '@unifii/library/common';
import { Subscription } from 'rxjs';

import { DeviceService } from 'capacitor/device.service';
import { PushNotificationData, PushNotificationService } from 'capacitor/push-notification.service';
import { VibrateService } from 'capacitor/vibrate.service';
import { Config } from 'config';
import { InitStep } from 'shell/init-step';
import { NavigationService } from 'shell/nav/navigation.service';
import { FormDataPath } from 'shell/shell-constants';

import { ExternalPath } from './discover-constants';

/**
 * Centralized place for running the Push Notifications Subscription
 */
@Injectable()
export class PushNotificationsInitStep implements InitStep, OnDestroy {

    private config = inject(Config);
    private router = inject(Router);
    private deviceService = inject(DeviceService);
    private nav = inject(NavigationService);
    private translate = inject(TranslateService);
    private vibrateService = inject(VibrateService);
    private modalService = inject(ModalService);
    private notificationService = inject(PushNotificationService);
    private pushSubscription?: Subscription;

    ngOnDestroy() {
        this.pushSubscription?.unsubscribe();
    }

    run() {

        if (!this.deviceService.isNative()) {
            return;
        }

        this.pushSubscription?.unsubscribe();
        this.pushSubscription = this.notificationService.notifications.subscribe((n) => {
            void this.pushNotificationModal(n);
        });

    }

    private async pushNotificationModal(n: PushNotificationData) {
        // Route info
        const routeCommands = this.nav.routeInfoToCommands(n.route);
        const matchTenant = routeCommands && this.config.unifii.tenant === n.route?.tenant;
        const matchProject = routeCommands && this.config.unifii.projectId === n.route?.projectId;
        const sameRoute = routeCommands && this.nav.getCommandsFromSnapshot(this.router.routerState.snapshot).join('/') === routeCommands.join('/');

        // Can not redirect, routeInfo is missing or invalid or match current position
        if (!routeCommands || (matchTenant && matchProject && sameRoute)) {
            // show notification as toast if not action
            if (!n.action) {
                this.vibrateService.vibrate(500);
                void this.modalService.openAlert({
                    title: n.title,
                    message: n.message,
                });
            }

            // App lunched by this notification, nothing to do
            return;
        }

        let userConsent: boolean | undefined = true;

        if (!n.action) {
            this.vibrateService.vibrate(500);
            userConsent = await this.modalService.openConfirm({
                title: n.title,
                message: n.message,
                confirmLabel: this.translate.instant(SharedTermsTranslationKey.ActionView),
                cancelLabel: this.translate.instant(SharedTermsTranslationKey.ActionCancel),
            });
        }

        if (userConsent && n.route) {
            const { tenant, projectId, nodeId, bucket, id } = n.route;
            const redirectCommands: any[] = [ExternalPath, tenant, projectId];

            if (nodeId) {
                redirectCommands.push('n', nodeId);
            }

            if (bucket && id) {
                redirectCommands.push(FormDataPath, bucket, id);
            }

            if (bucket && !id) {
                redirectCommands.push(bucket);
            }

            void this.router.navigate(redirectCommands);
        }
    }

}
